import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Header.module.css';
import { Button, Layout, Tooltip, Select } from 'antd';
import Search from 'components/controls/Search/Search';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import LogoWrap from '../../navigation/logowrap/LogoWrap';
import { Link } from 'react-router-dom';
import NotificationIcon from '../../icons/NotificationIcon';
import UserIcon from '../../icons/UserIcon';
import DropdownIcon from '../../icons/DropdownIcon';
import { useKeycloak } from '@react-keycloak/web';
import { AppState } from 'store/reducers';
import Announcement from 'components/messages/announcement/Announcement';
import axios from 'axios';
import { INotification, INotificationsDto } from 'interfaces/INotification';
import ServerError from 'integration/ServerError';
import { useTranslation } from 'react-i18next';
import ServerAPI from 'integration/ServerAPI';
import notification from 'components/messages/notification';
import { ActionType } from 'store/actionTypes';
import CacheStorage from 'utils/CacheStorage';

interface HeaderProps {
  user?: any;
}
const { Option } = Select;

const AntHeader = Layout.Header;

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

function convertUTCDateToLocalDate(date: Date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
}

function formatDate(date: string) {
  const dateObj = new Date(date);
  const localDate = convertUTCDateToLocalDate(dateObj);
  return (
    [
      padTo2Digits(localDate.getDate()),
      padTo2Digits(localDate.getMonth() + 1),
      localDate.getFullYear(),
    ].join('.') +
    ' ' +
    [
      padTo2Digits(localDate.getHours()),
      padTo2Digits(localDate.getMinutes()),
    ].join(':')
  );
}

const Header: React.FC<RouteComponentProps & HeaderProps> = (props) => {
  const { history, user } = props;
  const { keycloak } = useKeycloak();
  const [userName, setUserName] = useState('');
  const [notifications, setNotifications] = useState<INotificationsDto>();
  const [newNotifications, setNewNotifications] = useState<number>(0);
  const [markReadNotifsIds, setMarkReadNotifsIds] = useState<string[]>([]);
  const notificationsDropdown = useRef<HTMLDivElement | null>(null);
  const [offset, setOffset] = useState(0);
  const [notificationsMutex, setNotificationsMutex] = useState(false);
  const [notificationsEnd, setNotificationsEnd] = useState(false);
  const LIMIT = 20;
  const dispatch = useDispatch();

  const {i18n, t} = useTranslation();

  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang).then(() => {
      ServerAPI.changeKeycloakLocale(lang).then((res) => {
        CacheStorage.clearAll().then(() => {
          window.location.reload();
        })
      })
    });
  }

  const [isExpiredPassword, setIsExpiredPassword] = useState(false)

  const isExternal: boolean = useSelector((state: any) => {
    return state?.typeUser?.userData?.user?.params?.External;
  });

  const getNotifications = useCallback((forNew?: boolean) => {
    if (process.env.REACT_APP_TYPE === 'csdp' && !notificationsMutex && (forNew || !notificationsEnd)) {
      setNotificationsMutex(true);
      axios.get<INotificationsDto>('/customNotification/fetchTopNewNotifications', {
        params: {
          limit: LIMIT,
          offset: forNew ? 0 : offset
        }
      })
        .then((res) => {
          if (forNew) {
            setNotifications((prev) => {
              if (res.data) {
                if (res.data.countNew !== prev?.countNew) {
                  setOffset(0);
                  setNotificationsEnd(false);
                  return res.data
                } else {
                  return prev
                }
              }
            })
          } else {
            setNotifications((prev) => {
              if (!!prev && !!prev.notifications && res.data.notifications) {
                return {
                  ...prev,
                  notifications: [...prev.notifications, ...res.data.notifications]
                }
              } else {
                return res.data
              }
            });
            if (res.data.notifications && res.data.notifications?.length < LIMIT) {
              setNotificationsEnd(true);
            }
          }
          setNewNotifications(res.data.countNew);
          setTimeout(() => {
            setNotificationsMutex(false);
          }, 100);
        })
        .catch((e: ServerError) => {
          e.notify();
        });
    }
  }, [notificationsMutex, offset, notificationsEnd])

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo: any) => {
      setUserName(userInfo && userInfo['name-token']);
      setIsExpiredPassword(userInfo['PasswordExpired']);
      getNotifications();
      if (!!userInfo.locale) {
        i18n.changeLanguage(userInfo.locale);
      } else {
        i18n.changeLanguage('ru');
      }
      dispatch({
        type: ActionType.SET_IS_LOCALE_INITED,
        payload: true
      })
      setInterval(() => {
        getNotifications(true);
      }, 180000);
    });
  }, []);

  const setObserver = (node: HTMLDivElement | null, notification: INotification) => {
    if (node) {
      setTimeout(() => {
        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting && !notification.isRead) {
            setNotifications((prev) => {
              if (prev?.notifications) {
                const changeNotif = prev.notifications.find(n => n.id === notification.id)
                if (!!changeNotif) {
                  changeNotif.isRead = true;
                }
              }
              return prev
            });
            setMarkReadNotifsIds((prev) => [...prev, notification.id]);
          }
          if (entry.isIntersecting && !notificationsEnd && !notificationsMutex && notification.id === notifications!.notifications![notifications!.notifications!.length-1].id) {
            setOffset((prev) => prev+LIMIT);
          }
        }, {root: notificationsDropdown.current});
        observer.observe(node);
      }, 100);
    }
  }

  useEffect(() => {
    if (offset > 0) {
      getNotifications();
    }
  }, [offset])

  const onNotificationsClose = (open: boolean) => {
    if (!open && markReadNotifsIds.length > 0) {
      setNewNotifications((prev) => prev-markReadNotifsIds.length);
      setMarkReadNotifsIds([]);
      axios.post('/customNotification/markNotifications', markReadNotifsIds)
        .catch((err: ServerError) => err.notify());
    }
  }

  return (
    <>
      <AntHeader className={styles.header}>
        <Link to='/' className={styles.logo}>
          <LogoWrap />
        </Link>
        {process.env.REACT_APP_TYPE === 'irk' && <Search placeholder={t('searchRequest')} />}
        <div className={styles.container}>
          <div className={styles.lang}>
            <Select
              suffixIcon={<DropdownIcon className={styles.iconLang} />}
              className={styles.input}
              defaultValue={i18n.language}
              dropdownClassName={styles.dropdown}
              bordered={false}
              onChange={onChangeLanguage}
              value={i18n.language}
            >
              <Option value='ru'>RUS</Option>
              <Option value='en'>ENG</Option>
            </Select>
          </div>
          <span className={styles.wrapperNotifsTrigger}>
            <Tooltip
                color='var(--color-light)'
                overlayClassName={styles.tooltipNotifs}
                trigger='click'
                placement='bottomRight'
                onOpenChange={onNotificationsClose}
                title={
                  <div className={styles.wrapperNotifs} ref={notificationsDropdown}>
                    {
                      !!notifications && !!notifications.notifications && notifications.notifications.length > 0 ?
                        notifications.notifications.map((notification, idx) => (
                          <div className={styles.notificationWrapper} key={idx} ref={el => setObserver(el, notification)}>
                            <Announcement
                              date={formatDate(notification.dateOfPublication)}
                              info={notification.notificationText}
                              title={notification.notificationType}
                              link={notification.url}
                            />
                          </div>
                        )) :
                        <div>{t("noNotifications")}</div>
                    }
                  </div>
                }
              >
                <NotificationIcon className={styles.icon} />
                {
                  newNotifications > 0 &&
                    <div className={styles.newNotifs}>
                      {newNotifications}
                    </div>
                }
            </Tooltip>
          </span>
          <div className={`${styles.lk} userInfo`}>
            <Tooltip
              color='var(--color-light)'
              overlayClassName={styles.tooltip}
              trigger='hover'
              placement='bottomLeft'
              title={
                <div className={styles.wrapperAccount}>
                  <Button
                    className={styles.button}
                    htmlType='button'

                    type='text'
                    onClick={() => {
                      keycloak.logout({ redirectUri: window.location.origin })
                    }}
                  >
                    {t("signOut")}
                  </Button>
                  <Button htmlType='button' type='text' className={styles.button} onClick={() => history.push(`/className/Users/id/${user.id}`)}>{t('userProfile')}</Button>
                </div>
              }
            >
              <UserIcon className={styles.icon} />
              <div className={styles.userName}> {userName}</div>
            </Tooltip>
          </div>
        </div>
      </AntHeader>
    </>
  );
};


export default connect((state: AppState) => {
  return {
    user: state.typeUser.userData.user,
  };
})(withRouter(Header));
