import axios from 'axios';
import Title from 'components/layout/content/title/Title';
import { InboxGroupWrapper } from 'components/navigation/leftmenu/LeftMenu';
import { ILandingPageData } from 'interfaces/LandingPageData';
import React, { useEffect, useState } from 'react';
import styles from './InboxBlocksPage.module.css';
import DropdownBlock from 'components/DropdownBlock/DropdownBlock';

interface IInboxBlocksPage {
  inboxGroup: InboxGroupWrapper;
}

const InboxBlocksPage = ({ inboxGroup }: IInboxBlocksPage) => {
  const [pageData, setPageData] = useState<ILandingPageData[] | null>(null);
  useEffect(() => {
    axios.get(inboxGroup.inboxGroup.controllerName!).then((res) => {
      setPageData(res.data);
    });
  }, []);
  return (
    <div className={styles.pageContainer}>
      {pageData && (
        <>
          <div className={styles.title}>
            <Title title={pageData[0].label} />
          </div>
          <div className={styles.blocksWrapper}>
            {pageData[0].columns.map((col, idx) => (
              <div key={idx}>
                <DropdownBlock content={col.content} title={col.title} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default InboxBlocksPage;
