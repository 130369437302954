import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Popconfirm, Spin } from 'antd';
import axios from 'axios';
import notification from '../../messages/notification';
import DeleteIcon from 'components/icons/DeleteIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import FileIcon from 'components/icons/FileIcon';
import PreviewIcon from 'components/icons/PreviewIcon';
import FileModalPreview from './FileModalPreview';
import { useEditorChanged } from 'hooks';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import GlobalLoaderContext from 'components/layout/loader/GlobalLoaderContext';

import styles from './FilesList.module.css';

import FormatUtils from 'utils/FormatUtils';
import { ActionType } from 'store/actionTypes';
import { EditorFile } from 'store/reducers/editor_files';
import { JsObject } from 'interfaces/Object';
import { getChildrenParentID } from 'utils/ObjectUtils';
import { useWindowWidth } from '@react-hook/window-size';
import { useTranslation } from 'react-i18next';

interface FileItemProps {
  fileInfo?: EditorFile;
  deleteIsEnabled?: boolean;
  standalone?: boolean;
  deleteFile?: () => void;
  withoutDownload?: boolean;
  withoutPreview?: boolean;
  propName?: string;
}

export const downloadItem = (url: string, width: number, fileName?: string) => {
  if (url) {
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', fileName || '');
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } else {
    notification.error({
      text: 'Нет данных для скачивания',
      width,
    });
  }
};

export const getSize = (initialSize: number, decimals: number = 2) => {
  if (initialSize === 0) return '0 Байт';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб'];

  const i = Math.floor(Math.log(initialSize) / Math.log(k));

  return parseFloat((initialSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const getBgClass = (ext: any) => {
  return styles[ext];
};

export const getExt = (name?: string): string => {
  return name?.split('.').pop()?.toLowerCase() || '';
};

const FileItem: React.FC<FileItemProps> = (props) => {
  const { fileInfo, deleteIsEnabled, standalone, deleteFile, withoutDownload, withoutPreview, propName } =
    props;

  const fileParams: JsObject = fileInfo?.file?.params || {};
  const fileId: string = fileInfo?.file?.id || '';
  const spinning: boolean = fileInfo?.uploading === true;
  const toSave: boolean = Boolean(fileInfo?.toSave);
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const globalLoader = useContext(GlobalLoaderContext);
  const { form, className } = useContext(EntityEditorContext) || {};
  const { updateEditorChanged } = useEditorChanged();

  const {t} = useTranslation();

  const CONSTANTS: React.MutableRefObject<{
    typeItem: string[];
    extStyle: any;
  }> = useRef({
    typeItem: ['png', 'pdf', 'jpg', 'jpeg', 'gif', 'doc', 'docx'],
    extStyle: {
      top: `${1 * 0.85}rem`,
      left: `${1 * 0.2}rem`,
      width: `${1 * 1.5}rem`,
      height: `${1 * 0.8}rem`,
    },
  });

  const [fileSize, setFileSize] = useState<string>('');
  const [ext, setExt] = useState<string>('');
  const [bgClass, setBgClass] = useState<string>('');
  const [isShowModalPreview, setIsShowModalPreview] = useState<boolean>(false);
  const [result, setResult] = useState<any>();
  const [pdf, setPdf] = useState<any>();

  const openModalPreview = () => {
    setIsShowModalPreview(true);
  };

  const closeModalPreview = () => {
    setIsShowModalPreview(false);
  };

  const getPreview = () => {
    globalLoader.setLoading(true);
    const body: FormData = new FormData();
    const saveParams: any = {
      className,
      linkedId: getChildrenParentID(undefined, form),
    };
    body.append('contentId', fileId);
    body.append('property', JSON.stringify(saveParams));
    axios
      .post('/ContentCrud/preview', body)
      .then((res: any) => {
        if (res.error) {
          notification.error({
            text: res.error,
            width,
          });
        } else {
          if (res.data.converted) {
            setPdf(res.data.file);
          } else {
            setResult(res.data.file);
          }
          openModalPreview();
        }
      })
      .finally(() => {
        globalLoader.setLoading(false);
      });
  };

  const deleteItem = () => {
    if (standalone && deleteFile) {
      deleteFile();
      return;
    }
    dispatch({
      type: ActionType.REMOVE_EDITOR_FILES,
      payload: fileId,
    });

    if (!toSave) updateEditorChanged(true);

    const currentFiles: string[] = form?.getFieldValue(propName || 'Files') || [];
    const newData: string[] = currentFiles.filter((id: string) => id !== fileId);
    form?.setFieldsValue({ [propName || 'Files']: newData });
  };

  useEffect(() => {
    if (fileParams.fileName) {
      const extention: string = getExt(fileParams.fileName);
      setBgClass(getBgClass(extention));
      setExt(extention);
      setFileSize(getSize(fileParams.fileSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CONSTANTS, fileParams, fileParams.fileName]);

  // const typeItem = ["png", "pdf", "jpg", "jpeg", "gif", "doc", "docx"];
  // const isDisabled = typeItem.includes(ext) ? false : true;

  return (
    <div>
      <Spin spinning={spinning}>
        <div className={FormatUtils.getClassName([styles.item, toSave ? styles.toSave : ''])}>
          <div className={styles.leftItem}>
            <div className={styles['item-row']}>
              <div className={styles.icon}>
                <FileIcon className={styles.file} />
                <span
                  className={[styles.ext, bgClass].join(' ')}
                  style={CONSTANTS.current.extStyle}
                >
                  {ext}
                </span>
              </div>
              <span className={styles.size} title={fileSize}>
                {fileSize}
              </span>
            </div>
            <div className={styles['item-row']}>
              <span className={styles.name} title={fileParams.fileName}>
                {fileParams.fileName}
              </span>
            </div>
          </div>
          <div
            className={FormatUtils.getClassName([
              styles.icons,
              withoutDownload ? styles.withoutDownload : '',
            ])}
          >
            <Popconfirm
              disabled={!deleteIsEnabled}
              title={t("isDeleteAttachment")}
              onConfirm={deleteItem}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Button
                htmlType='button'
                className={FormatUtils.getClassName([
                  !deleteIsEnabled ? styles.buttonDisabled : '',
                  styles.button,
                ])}
                disabled={!deleteIsEnabled}
              >
                <DeleteIcon className={styles.icon} />
              </Button>
            </Popconfirm>
            {!withoutDownload && (
              <Button
                disabled={toSave}
                htmlType='button'
                className={styles.button}
                onClick={() => downloadItem(fileParams.Url, width, fileParams.fileName)}
              >
                <DownloadIcon className={styles.icon} />
              </Button>
            )}
            {!withoutPreview && (
              <Button
                htmlType='button'
                disabled={toSave}
                className={styles.button}
                onClick={getPreview}
              >
                <PreviewIcon className={styles.icon} />
              </Button>
            )}
          </div>
        </div>
      </Spin>
      <FileModalPreview
        isOpen={isShowModalPreview}
        onClose={closeModalPreview}
        result={result}
        pdf={pdf}
      />
    </div>
  );
};

export default React.memo(FileItem);
