import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { FormInstance } from 'antd';

import { useCancelToken, useEditorChanged, useRefreshComponent } from 'hooks';

import ObjectCrud, {
  PreFillNewObjectOutput,
  ObjectInfoOutput,
  CreateObjectOutput,
  UpdateObjectOutput,
  OnChangeDataOutput,
} from 'controllers/ObjectCrud';

import ControlFactory from 'utils/ControlFactory';
import { AppState } from 'store/reducers';
import { EditorFilesState } from 'store/reducers/editor_files';
import { ObjectData, JsObject } from 'interfaces/Object';
import { FormObjectData } from 'interfaces/Form';
import { RefreshID } from 'enums/refresh';
import ServerError from 'integration/ServerError';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';

export interface OnChangeTimerMap {
  anyProp?: any;
  propMap: JsObject;
}

export type PreFillNewObject = (
  parentDetails?: ObjectData,
  parentForm?: FormInstance
) => Promise<PreFillNewObjectOutput>;
export type GetObjectInfo = (objectData?: ObjectData) => Promise<ObjectInfoOutput>;
export type CreateObject = (details?: ObjectData, toolName?: string) => Promise<CreateObjectOutput>;
export type UpdateObject = (details?: ObjectData, toolName?: string) => Promise<UpdateObjectOutput>;
export type GetOnChangeData = (
  propName: string,
  details?: ObjectData,
  factory?: ControlFactory
) => Promise<OnChangeDataOutput>;
export type HandleValuesChange = (
  changedValues: FormObjectData,
  details?: ObjectData,
  factory?: ControlFactory
) => void;
export type UpdatePassword = (
  newPassword: string,
  repeatedPassword: string,
  userId?: string
) => Promise<AxiosResponseExt | ServerError | undefined>;

type UseObjectCrud = (args: {
  form: FormInstance;
  objectID?: string;
  id?: string; // alias for objectID
  className: string;
  parentId?: string;
  parentClassName?: string;
  toolName?: string;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setFactory?: React.Dispatch<React.SetStateAction<ControlFactory | undefined>>;
}) => {
  preFillNewObject: PreFillNewObject;
  getObjectInfo: GetObjectInfo;
  createObject: CreateObject;
  updateObject: UpdateObject;
  getOnChangeData: GetOnChangeData;
  handleValuesChange: HandleValuesChange;
  updatePassword: UpdatePassword;
};

export const useObjectCrud: UseObjectCrud = (args) => {
  const { form, className, parentId, parentClassName, setLoading, setFactory } = args;

  const editorFiles: EditorFilesState = useSelector((state: AppState) => state.editorFiles) || {};

  const objectID: string | undefined = args.objectID || args.id;

  const dispatch = useDispatch();
  const width = useWindowWidth();
  const onChangeTimerMap = useRef<OnChangeTimerMap>({ propMap: {} });

  const { cancelToken } = useCancelToken();
  const { updateEditorChanged, updateEditorGeneralChanged, checkEditorGeneralChanged } = useEditorChanged({ form });
  const { refreshComponent } = useRefreshComponent();

  const preFillNewObject: PreFillNewObject = (parentDetails, parentForm) =>
    ObjectCrud.preFillNewObject({
      className,
      parentId,
      parentClassName,
      parentDetails,
      parentForm,
      cancelToken,
      width
    });

  const getObjectInfo: GetObjectInfo = (objectData) =>
    ObjectCrud.getObjectInfo({
      objectData,
      className,
      objectID,
      cancelToken,
      width,
      dispatch,
    });

  const createObject: CreateObject = (details, toolName = args.toolName) =>
    ObjectCrud.createObject({
      toolName,
      details,
      editorFiles,
      form,
      cancelToken,
      width,
      dispatch,
    });

  const updateObject: UpdateObject = (details, toolName = args.toolName) =>
    ObjectCrud.updateObject({
      toolName,
      details,
      editorFiles,
      form,
      cancelToken,
      width,
      dispatch,
    });

  const updatePassword: UpdatePassword = (newPassword, repeatedPassword, userId) => 
    ObjectCrud.updatePassword({
        newPassword,
        repeatedPassword,
        form,
        userId,
        width
    });

  const getOnChangeData: GetOnChangeData = (propName, details, factory) =>
    ObjectCrud.getOnChangeData({ propName, form, details, factory, width });

  const handleValuesChange: HandleValuesChange = (changedValues, details, factory) => {
    clearTimeout(onChangeTimerMap.current.anyProp);
    onChangeTimerMap.current.anyProp = setTimeout(() => {
      updateEditorChanged(true);
      if (!checkEditorGeneralChanged()) {
        Object.keys(changedValues).forEach(key => {
            const panels = factory?.getPanels();
            if (panels && panels.length > 0) {
              panels[0].componentsRows.forEach((row: any) => {
                row.components.forEach((component: any) => {
                  if (component.propName === key) updateEditorGeneralChanged(true);
                })
              })
            }
        })
      }
      if (factory?.isFetchValue(changedValues)) factory.handleComponentChange(changedValues, form, details);
    }, 300);

    // usually only one prop changed
    Object.keys(changedValues).forEach((changedProp: string) => {
      if (!changedProp) return;

      clearTimeout(onChangeTimerMap.current.propMap[changedProp]);

      const component = factory?.componentMap?.[changedProp];
      const cardDisplay = component?.jsonConfig?.cardDisplay;

      const fireOnChangeAutofill: boolean | undefined = cardDisplay?.fireOnChangeAutofill;
      const fireOnChangeAutofillLoading: boolean | undefined =
        cardDisplay?.fireOnChangeAutofillLoading;

      const { refreshOnChange, refreshCardOnChange, reloadCardOnChange } = component?.params || {};
      const refreshIDs: string | string[] | undefined =
        (reloadCardOnChange && RefreshID.EDITOR_DETAILS_DATA) ||
        (refreshCardOnChange && RefreshID.EDITOR_DETAILS) ||
        refreshOnChange;

      if (!fireOnChangeAutofill && !refreshIDs) return;

      // fireOnChangeAutofill - событие onChange на беке
      // прокидываем и получаем новые данные объекта и возможно параметры компонентов карточки
      // refreshIDs - рефреш карточки и/или контролов
      // если предоставлены оба fireOnChangeAutofill и refreshIDs - autofill должен идти первым
      onChangeTimerMap.current.propMap[changedProp] = setTimeout(async () => {
        if (fireOnChangeAutofill) {
          if (fireOnChangeAutofillLoading) setLoading?.(true);
          const result = await getOnChangeData(changedProp, details, factory);
          if (result.factory) setFactory?.(result.factory);
          if (fireOnChangeAutofillLoading) setLoading?.(false);
        }
        if (refreshIDs) {
          refreshComponent(refreshIDs)
        } else {
          refreshComponent(RefreshID.EDITOR_DETAILS)
        };
      }, 500);
    });
  };

  return {
    preFillNewObject,
    getObjectInfo,
    createObject,
    updateObject,
    getOnChangeData,
    handleValuesChange,
    updatePassword
  };
};

export default useObjectCrud;
