import { cellWidth } from '../../utils/types';

export const widthForMaxSize: cellWidth[] = [
  {
    name: 'PlanedChangeDate',
    width: 285,
  },
  {
    name: 'EDAdjustmentPeriod',
    width: 285,
  },
  {
    name: 'Responsible',
    width: 325,
  },
  {
    name: 'NotesDITS',
    width: 250,
  },
  {
    name: 'TimeBeforePlannedEndDate',
    width: 240,
  },
  {
    name: 'OriginalManufacturer',
    width: 350,
  },
  {
    name: 'RequestNumber',
    width: 210,
  },
  {
    name: 'SignDate',
    width: 220,
  },
  {
    name: 'CheckingRequestNumber',
    width: 200
  },
  {
    name: 'TypeOfAircraft',
    width: 100
  },
  {
    name: 'DocumentName',
    width: 240
  },
  {
    name: 'DocumentationType',
    width: 240,
  },
  {
    name: 'RequestNumberWithRevision',
    width: 200,
  },
  {
    name: 'ClientOrganization',
    width: 340,
  },
  {
    name: 'ExpirationDate',
    width: 210,
  },
  {
    name: 'ContractStatus',
    width: 220,
  },
  {
    name: 'Level',
    width: 230,
  },
  {
    name: 'InternalOnly',
    width: 360,
  },
  {
    name: 'ProductFlightCycles',
    width: 240,
  },
  {
    name: 'ProductFlightHours',
    width: 370,
  },
  {
    name: 'AircraftType',
    width: 110,
  },
  {
    name: 'BoardNumber',
    width: 180,
  },
  {
    name: 'Priority',
    width: 165,
  },
  {
    name: 'DateOfRequestCreation',
    width: 260,
  },
  {
    name: 'PlannedEndDate',
    width: 250,
  },
  {
    name: 'ATA',
    width: 150,
  },
  {
    name: 'RequestSubject',
    width: 180,
  },
  {
    name: 'InternalStatus',
    width: 235,
  },
  {
    name: 'ExternalStatus',
    width: 185,
  },
  {
    name: 'DesiredEndDate',
    width: 220,
  },
  {
    name: 'RequestType',
    width: 180,
  },
  {
    name: 'ATARefined',
    width: 260,
  },
  {
    name: 'RequestSubType',
    width: 360,
  },
  {
    name: 'FactoryNumber',
    width: 360,
  },
  {
    name: 'ExpectedDate',
    width: 480,
  },
  {
    name: 'SerNumber',
    width: 290,
  },
  {
    name: 'DN',
    width: 250,
  },
  {
    name: 'FaultType',
    width: 400,
  },
  {
    name: 'Executor',
    width: 210,
  },
  {
    name: 'Department',
    width: 250,
  },
  {
    name: 'ActualEndDate',
    width: 220,
  },
  {
    name: 'ReasonOfRequest',
    width: 371,
  },
  {
    name: 'RequestDetails',
    width: 280,
  },
  {
    name: 'Notes',
    width: 170,
  },
  {
    name: 'CreatedBy',
    width: 180,
  },
  {
    name: 'CreatedByEmail',
    width: 240,
  },
  {
    name: 'TrackingNumber',
    width: 180,
  },
  {
    name: 'ActionsTaken',
    width: 290,
  },
  {
    name: 'MPD_ALS_AD_MRBR',
    width: 275,
  },
  {
    name: 'NTO',
    width: 140,
  },
  {
    name: 'AircraftModel',
    width: 160,
  },
  {
    name: 'Airport',
    width: 260,
  },
  {
    name: 'ChangeType',
    width: 240,
  },
  {
    name: 'AdjustmentMethod',
    width: 260,
  },
  {
    name: 'UpdatedPublication',
    width: 250,
  },
  {
    name: 'ChangeCategory',
    width: 240,
  },
  {
    name: 'ChangeStatus',
    width: 275,
  },
  {
    name: 'UpdatedCodeMD',
    width: 260,
  },
  {
    name: 'AircraftFlightCycles',
    width: 110,
  },
  {
    name: 'AircraftFlightHours',
    width: 110,
  },
  {
    name: 'OperationPhase',
    width: 270,
  },
  {
    name: 'InfluenceOnFlight',
    width: 210,
  },
  {
    name: 'PartNumber',
    width: 185,
  },
  {
    name: 'SerialNumber',
    width: 155,
  },
  {
    name: 'ComponentName',
    width: 320,
  },
  {
    name: 'parentRequestNumber',
    width: 280,
  },
  {
    name: 'PIREP_MAREP',
    width: 190,
  },
  {
    name: 'AirworthinessImpact',
    width: 190,
  },
  {
    name: 'ApplicableToAircraftFleet',
    width: 260,
  },
  {
    name: 'GlobalProblem',
    width: 250,
  },
  {
    name: 'ApplicableToModel',
    width: 280,
  },
  {
    name: 'Sequence',
    width: 180,
  },
  {
    name: 'Category',
    width: 264,
  },
  {
    name: 'Status',
    width: 162,
  },
  {
    name: 'Title',
    width: 190,
  },
  {
    name: 'DateOfNewsCreation',
    width: 210,
  },
  {
    name: 'DateOfPublication',
    width: 270,
  },
  {
    name: 'HidingDate',
    width: 198,
  },
  {
    name: 'DateLastModified',
    width: 222,
  },
  {
    name: 'AuthorOfLastVersion',
    width: 237,
  },
  {
    name: 'Important',
    width: 120,
  },
  {
    name: 'Organization',
    width: 217,
  },
  {
    name: 'Country',
    width: 135,
  },
  {
    name: 'Address',
    width: 264,
  },
  {
    name: 'Email',
    width: 207,
  },
  {
    name: 'Phone',
    width: 181,
  },
  {
    name: 'Contact',
    width: 274,
  },
  {
    name: 'UnbreakableControl',
    width: 337,
  },
  {
    name: 'UnbreakableControlService',
    width: 337,
  },
  {
    name: 'UnbreakableControlService',
    width: 337,
  },
  {
    name: 'StatusServiceSupplier',
    width: 185,
  },
  {
    name: 'StatusService',
    width: 185,
  },
  {
    name: 'AircraftTypeService',
    width: 150,
  },
  {
    name: 'OrganizationService',
    width: 217,
  },
  {
    name: 'CityService',
    width: 176,
  },
  {
    name: 'AirportService',
    width: 437,
  },
  {
    name: 'RestrictionsService',
    width: 598,
  },
  {
    name: 'OTOService',
    width: 127,
  },
  {
    name: 'PTOService',
    width: 121,
  },
  {
    name: 'DataFullAmountAndCorrectness',
    width: 300
  }
];

export const widthForMiddleSize: cellWidth[] = [
  {
    name: 'PlanedChangeDate',
    width: 210,
  },
  {
    name: 'EDAdjustmentPeriod',
    width: 200,
  },
  {
    name: 'Responsible',
    width: 235,
  },
  {
    name: 'NotesDITS',
    width: 200,
  },
  {
    name: 'CheckingRequestNumber',
    width: 165
  },
  {
    name: 'RequestNumber',
    width: 180,
  },
  {
    name: 'OriginalManufacturer',
    width: 300,
  },
  {
    name: 'UpdatedPublication',
    width: 220,
  },
  {
    name: 'ChangeCategory',
    width: 220,
  },
  {
    name: 'UpdatedCodeMD',
    width: 220,
  },
  {
    name: 'ATARefined',
    width: 230,
  },
  {
    name: 'ChangeType',
    width: 210,
  },
  {
    name: 'DocumentationType',
    width: 210,
  },
  {
    name: 'AdjustmentMethod',
    width: 220,
  },
  {
    name: 'FactoryNumber',
    width: 235,
  },
  {
    name: 'ExpectedDate',
    width: 340,
  },
  {
    name: 'SerNumber',
    width: 260,
  },
  {
    name: 'DN',
    width: 220,
  },
  {
    name: 'ChangeStatus',
    width: 270,
  },
  {
    name: 'MPD_ALS_AD_MRBR',
    width: 230,
  },
  {
    name: 'TimeBeforePlannedEndDate',
    width: 200,
  },
  {
    name: 'RequestNumberWithRevision',
    width: 170,
  },
  {
    name: 'ClientOrganization',
    width: 150,
  },
  {
    name: 'SignDate',
    width: 190,
  },
  {
    name: 'ExpirationDate',
    width: 180,
  },
  {
    name: 'ContractStatus',
    width: 190,
  },
  {
    name: 'Level',
    width: 200,
  },
  {
    name: 'InternalOnly',
    width: 330,
  },
  {
    name: 'ProductFlightCycles',
    width: 210,
  },
  {
    name: 'ProductFlightHours',
    width: 340,
  },
  {
    name: 'AircraftType',
    width: 110,
  },
  {
    name: 'BoardNumber',
    width: 120,
  },
  {
    name: 'Priority',
    width: 120,
  },
  {
    name: 'ProductType',
    width: 140,
  },
  {
    name: 'DateOfRequestCreation',
    width: 175,
  },
  {
    name: 'PlannedEndDate',
    width: 170,
  },
  {
    name: 'ATA',
    width: 100,
  },
  {
    name: 'RequestSubject',
    width: 80,
  },
  {
    name: 'InternalStatus',
    width: 160,
  },
  {
    name: 'ExternalStatus',
    width: 160,
  },
  {
    name: 'DesiredEndDate',
    width: 170,
  },
  {
    name: 'RequestType',
    width: 130,
  },
  {
    name: 'TypeOfAircraft',
    width: 60
  },
  {
    name: 'DocumentName',
    width: 160
  },
  {
    name: 'RequestSubType',
    width: 300,
  },
  {
    name: 'FaultType',
    width: 350,
  },
  {
    name: 'Executor',
    width: 170,
  },
  {
    name: 'ExecutorDuplicate',
    width: 160,
  },
  {
    name: 'Department',
    width: 170,
  },
  {
    name: 'ActualEndDate',
    width: 160,
  },
  {
    name: 'ReasonOfRequest',
    width: 300,
  },
  {
    name: 'RequestDetails',
    width: 110,
  },
  {
    name: 'Notes',
    width: 140,
  },
  {
    name: 'CreatedBy',
    width: 140,
  },
  {
    name: 'CreatedByEmail',
    width: 200,
  },
  {
    name: 'TrackingNumber',
    width: 160,
  },
  {
    name: 'ActionsTaken',
    width: 250,
  },
  {
    name: 'ALS_AD_MRBR',
    width: 140,
  },
  {
    name: 'NTO',
    width: 126,
  },
  {
    name: 'AircraftModel',
    width: 130,
  },
  {
    name: 'Airport',
    width: 160,
  },
  {
    name: 'AircraftFlightCycles',
    width: 90,
  },
  {
    name: 'AircraftFlightHours',
    width: 90,
  },
  {
    name: 'OperationPhase',
    width: 227,
  },
  {
    name: 'InfluenceOnFlight',
    width: 180,
  },
  {
    name: 'PartNumber',
    width: 155,
  },
  {
    name: 'SerialNumber',
    width: 120,
  },
  {
    name: 'ComponentName',
    width: 260,
  },
  {
    name: 'parentRequestNumber',
    width: 240,
  },
  {
    name: 'PIREP_MAREP',
    width: 150,
  },
  {
    name: 'AirworthinessImpact',
    width: 160,
  },
  {
    name: 'ApplicableToAircraftFleet',
    width: 230,
  },
  {
    name: 'GlobalProblem',
    width: 230,
  },
  {
    name: 'ApplicableToModel',
    width: 260,
  },
  {
    name: 'Sequence',
    width: 150,
  },
  {
    name: 'Category',
    width: 180,
  },
  {
    name: 'Status',
    width: 110,
  },
  {
    name: 'Title',
    width: 200,
  },
  {
    name: 'DateOfNewsCreation',
    width: 180,
  },
  {
    name: 'DateOfPublication',
    width: 240,
  },
  {
    name: 'HidingDate',
    width: 170,
  },
  {
    name: 'DateLastModified',
    width: 190,
  },
  {
    name: 'AuthorOfLastVersion',
    width: 170,
  },
  {
    name: 'Important',
    width: 100,
  },
  {
    name: 'Organization',
    width: 177,
  },
  {
    name: 'Country',
    width: 105,
  },
  {
    name: 'Address',
    width: 194,
  },
  {
    name: 'Email',
    width: 187,
  },
  {
    name: 'Phone',
    width: 151,
  },
  {
    name: 'Contact',
    width: 204,
  },
  {
    name: 'UnbreakableControl',
    width: 230,
  },
  {
    name: 'UnbreakableControlService',
    width: 260,
  },
  {
    name: 'StatusServiceSupplier',
    width: 155,
  },
  {
    name: 'StatusService',
    width: 130,
  },
  {
    name: 'AircraftTypeService',
    width: 110,
  },
  {
    name: 'OrganizationService',
    width: 257,
  },
  {
    name: 'CityService',
    width: 126,
  },
  {
    name: 'AirportService',
    width: 327,
  },
  {
    name: 'RestrictionsService',
    width: 458,
  },
  {
    name: 'OTOService',
    width: 97,
  },
  {
    name: 'PTOService',
    width: 91,
  },
  {
    name: 'DataFullAmountAndCorrectness',
    width: 300
  }
];

export const widthForSmallSize: cellWidth[] = [
  {
    name: 'PlanedChangeDate',
    width: 120,
  },
  {
    name: 'ChangeStatus',
    width: 130,
  },
  {
    name: 'EDAdjustmentPeriod',
    width: 130,
  },
  {
    name: 'Responsible',
    width: 145,
  },
  {
    name: 'NotesDITS',
    width: 150,
  },
  {
    name: 'CheckingRequestNumber',
    width: 140
  },
  {
    name: 'RequestNumber',
    width: 150,
  },
  {
    name: 'DocumentationType',
    width: 150,
  },
  {
    name: 'OriginalManufacturer',
    width: 150,
  },
  {
    name: 'FactoryNumber',
    width: 200,
  },
  {
    name: 'ExpectedDate',
    width: 230,
  },
  {
    name: 'SerNumber',
    width: 230,
  },
  {
    name: 'DN',
    width: 190,
  },
  {
    name: 'TimeBeforePlannedEndDate',
    width: 100,
  },
  {
    name: 'RequestNumberWithRevision',
    width: 110,
  },
  {
    name: 'ClientOrganization',
    width: 280,
  },
  {
    name: 'SignDate',
    width: 160,
  },
  {
    name: 'ExpirationDate',
    width: 150,
  },
  {
    name: 'ContractStatus',
    width: 160,
  },
  {
    name: 'Level',
    width: 170,
  },
  {
    name: 'InternalOnly',
    width: 300,
  },
  {
    name: 'ProductFlightCycles',
    width: 180,
  },
  {
    name: 'ProductFlightHours',
    width: 310,
  },
  {
    name: 'AircraftType',
    width: 45,
  },
  {
    name: 'BoardNumber',
    width: 80,
  },
  {
    name: 'Priority',
    width: 80,
  },
  {
    name: 'DateOfRequestCreation',
    width: 130,
  },
  {
    name: 'PlannedEndDate',
    width: 110,
  },
  {
    name: 'ATA',
    width: 73,
  },
  {
    name: 'RequestSubject',
    width: 95,
  },
  {
    name: 'InternalStatus',
    width: 100,
  },
  {
    name: 'ExternalStatus',
    width: 80,
  },
  {
    name: 'DesiredEndDate',
    width: 90,
  },
  {
    name: 'RequestType',
    width: 80,
  },
  {
    name: 'RequestSubType',
    width: 140,
  },
  {
    name: 'FaultType',
    width: 160,
  },
  {
    name: 'Executor',
    width: 145,
  },
  {
    name: 'Department',
    width: 75,
  },
  {
    name: 'ActualEndDate',
    width: 100,
  },
  {
    name: 'ReasonOfRequest',
    width: 215,
  },
  {
    name: 'RequestDetails',
    width: 250,
  },
  {
    name: 'Notes',
    width: 95,
  },
  {
    name: 'CreatedBy',
    width: 80,
  },
  {
    name: 'CreatedByEmail',
    width: 110,
  },
  {
    name: 'TrackingNumber',
    width: 80,
  },
  {
    name: 'ActionsTaken',
    width: 215,
  },
  {
    name: 'ALS_AD_MRBR',
    width: 85,
  },
  {
    name: 'NTO',
    width: 70,
  },
  {
    name: 'AircraftModel',
    width: 60,
  },
  {
    name: 'Airport',
    width: 105,
  },
  {
    name: 'AircraftFlightCycles',
    width: 45,
  },
  {
    name: 'AircraftFlightHours',
    width: 45,
  },
  {
    name: 'OperationPhase',
    width: 100,
  },
  {
    name: 'InfluenceOnFlight',
    width: 85,
  },
  {
    name: 'PartNumber',
    width: 125,
  },
  {
    name: 'SerialNumber',
    width: 65,
  },
  {
    name: 'ComponentName',
    width: 115,
  },
  {
    name: 'parentRequestNumber',
    width: 115,
  },
  {
    name: 'PIREP_MAREP',
    width: 70,
  },
  {
    name: 'AirworthinessImpact',
    width: 70,
  },
  {
    name: 'ApplicableToAircraftFleet',
    width: 115,
  },
  {
    name: 'GlobalProblem',
    width: 100,
  },
  {
    name: 'ApplicableToModel',
    width: 110,
  },

  {
    name: 'Sequence',
    width: 70,
  },
  {
    name: 'TypeOfAircraft',
    width: 65
  },
  {
    name: 'DocumentName',
    width: 210
  },
  {
    name: 'Category',
    width: 87,
  },
  {
    name: 'Status',
    width: 54,
  },
  {
    name: 'Title',
    width: 73,
  },
  {
    name: 'DateOfNewsCreation',
    width: 80,
  },
  {
    name: 'DateOfPublication',
    width: 210,
  },
  {
    name: 'HidingDate',
    width: 75,
  },
  {
    name: 'DateLastModified',
    width: 75,
  },
  {
    name: 'AuthorOfLastVersion',
    width: 75,
  },
  {
    name: 'Important',
    width: 50,
  },

  {
    name: 'Organization',
    width: 86,
  },
  {
    name: 'Country',
    width: 44,
  },
  {
    name: 'Address',
    width: 119,
  },
  {
    name: 'Email',
    width: 86,
  },
  {
    name: 'Phone',
    width: 67,
  },
  {
    name: 'Contact',
    width: 108,
  },
  {
    name: 'UnbreakableControl',
    width: 133,
  },
  {
    name: 'UnbreakableControlService',
    width: 120,
  },
  {
    name: 'StatusServiceSupplier',
    width: 67,
  },
  {
    name: 'StatusService',
    width: 70,
  },
  {
    name: 'AircraftTypeService',
    width: 86,
  },
  {
    name: 'OrganizationService',
    width: 86,
  },
  {
    name: 'CityService',
    width: 75,
  },
  {
    name: 'AirportService',
    width: 118,
  },
  {
    name: 'RestrictionsService',
    width: 260,
  },
  {
    name: 'OTOService',
    width: 62,
  },
  {
    name: 'PTOService',
    width: 33,
  },
  {
    name: 'DataFullAmountAndCorrectness',
    width: 300
  }
];
