import { Action, ActionType } from '../actionTypes';
import { JsObject } from 'interfaces/Object';

export interface FileSaveParams {
  className?: string;
  VersionSeriesId?: string;
}

export interface EditorFile {
  documentId: string;
  saveParams?: FileSaveParams;
  uploading?: boolean;
  toSave: boolean;
  file?: any;
  propName?: string;
}

export type DocumentEditorFiles = JsObject<EditorFile>;

export type EditorFilesState = JsObject<DocumentEditorFiles>;

const initialState: EditorFilesState = {};

const editorFiles = (
  state: EditorFilesState = initialState,
  { type, payload }: Action<ActionType, any>
) => {
  switch (type) {
    case ActionType.REMOVE_EDITOR_FILES: {
      if (!payload) return state;
      const fileIDs = Array.isArray(payload) ? payload : [payload];
      if (!fileIDs.length) return state;
      const newState = { ...state };
      Object.keys(newState).forEach((documentId) => {
        let deleted = false;
        fileIDs.forEach((fileId: string) => {
          if (newState[documentId][fileId]) {
            delete newState[documentId][fileId];
            deleted = true;
          }
        });
        if (deleted) {
          if (!Object.keys(newState[documentId]).length) {
            delete newState[documentId];
          } else {
            newState[documentId] = { ...newState[documentId] };
          }
        }
      });
      return newState;
    }
    case ActionType.UPDATE_EDITOR_FILE_STATUS: {
      const newStatus: any = payload;
      if (
        state[newStatus.documentId] != null &&
        state[newStatus.documentId][newStatus.id] != null
      ) {
        state[newStatus.documentId] = { ...state[newStatus.documentId] };
        state[newStatus.documentId][newStatus.id].toSave = newStatus.toSave || false;
        state[newStatus.documentId][newStatus.id].uploading = newStatus.uploading || false;
        return { ...state };
      }
      return state;
    }
    case ActionType.UPDATE_EDITOR_FILES: {
      const files: any[] = payload;
      if (files != null && files.length > 0) {
        const newState = { ...state };
        files.forEach((file: EditorFile) => {
          if (!newState[file.documentId]) {
            newState[file.documentId] = {};
          }
          newState[file.documentId][file.file.id] = {
            saveParams: file.saveParams,
            toSave: file.toSave || false,
            documentId: file.documentId,
            uploading: file.uploading || false,
            file: file.file,
            propName: file.propName
          };
        });
        return newState;
      }
      return state;
    }
    case ActionType.CLEAR_EDITOR_FILES: {
      return {};
    }
    default:
      return state;
  }
};

export default editorFiles;
