import { DataTypesEnum } from 'interfaces/LandingPageData';
import React, { useRef, useState } from 'react';
import DropdownIcon from 'components/icons/DropdownIcon';
import styles from './DropdownBlock.module.css';
import DropdownBlockSelect from './DropdownBlockSelect';

interface IDropdownBlockProps {
  title: string;
  content: {
    dataType: DataTypesEnum;
    dataSource?: string;
    data: string;
    viewConfig?: {
      label: string;
      key: string;
    }[];
  }[];
}

const DropdownBlock = ({ content, title }: IDropdownBlockProps) => {
  const toggler: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const block: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [isBlockOpened, setIsBlockOpened] = useState(false);

  const onTitleClick = () => {
    if (block.current) {
      if (!isBlockOpened) {
        block.current.style.height = `${block.current.scrollHeight}px`;
      } else {
        block.current.style.height = `${block.current.scrollHeight}px`;
        window.getComputedStyle(block.current, null).getPropertyValue('height');
        block.current.style.removeProperty('height');
      }
    }
    setIsBlockOpened((prev) => !prev);
  };

  const onTransitionEnd = () => {
    if (block.current) {
      if (isBlockOpened) {
        block.current.style.height = 'auto';
      }
    }
  };
  return (
    <div ref={block} onTransitionEnd={onTransitionEnd} className={styles.blockWrapper}>
      <div ref={toggler} className={styles.title} onClick={onTitleClick}>
        <div className={`${styles.icon} ${isBlockOpened && styles.opened}`}>
          <DropdownIcon />
        </div>
        <div>{title}</div>
      </div>
      <div>
        {content.map((item, idx) => {
          switch (item.dataType) {
            case DataTypesEnum.HTML_TEXT:
              return <div key={idx} dangerouslySetInnerHTML={{ __html: item.data }}></div>;

            case DataTypesEnum.CHOICE_INFO:
              return (
                <DropdownBlockSelect viewConfig={item.viewConfig!} choiceName={item.dataSource!} />
              );
            default:
              break;
          }
        })}
      </div>
    </div>
  );
};

export default DropdownBlock;
