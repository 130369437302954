import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { useEditorChanged, useModalForm, useObjectDetails } from 'hooks';
import { updateFiltersConfig } from 'pages/entityList/entityListFilters/EntityListFilters';
import notification from 'components/messages/notification';
import GlobalLoaderContext from 'components/layout/loader/GlobalLoaderContext';
import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';

import composeLink from 'utils/composeLink';
import { TransitionType } from 'interfaces/CardTransition';
import buildFormData, { appendFormData } from 'utils/formData';
import FormatUtils from 'utils/FormatUtils';
import { getChildrenParentID } from 'utils/ObjectUtils';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import { ISort } from 'interfaces/Table';
import { processExportResponse } from 'utils/ProcessExportResponse';

export const useTableViewTools = ({
  tableId,
  addGuidItemsTable,
  refreshID,
  cardForm,
  massReplaceTool,
  inboxName,
  className,
  isCardTable,
  parentClassName,
  parentId,
  filters,
  filtersConfiguration,
  loading,
  filtersForm,
  conditionId,
  fillCriteriaFrom,
  width,
}: any) => {
  const history = useHistory();
  const globalLoader = useContext(GlobalLoaderContext);
  const { handleEditorChanged } = useEditorChanged();
  const { showModalAttach, showModalMassReplace, showModalCreate, showModalImportFiles } =
    useModalForm();
  const { details } = useObjectDetails();

  const getSearchForExport = () => {
    const config = updateFiltersConfig(filters, filtersConfiguration, filtersForm);

    const newConfig: any = config.config.criteria.conditions.map((field: any) => {
      try {
        if (details?.params && field.id === conditionId) {
          field.value = field.value.length ? null : details.params[fillCriteriaFrom];
        }
        if (field.value === JSON.parse(field.jsonConfig).defaultValue) {
          field.value = null;
        }
      } catch {
        /* no-op */
      }
      return field;
    });

    config.config.criteria.conditions = newConfig;

    return config.config;
  };

  const tableViewAttachTool = async () => {
    const obj = await handleEditorChanged();
    if (!obj) return;

    const parentData: { parentId?: string; parentClassName?: string } = {};
    let layoutParentId: string | undefined;
    if (isCardTable && obj !== true) {
      parentData.parentId = getChildrenParentID(obj);
      parentData.parentClassName = obj.classType;
      layoutParentId = obj.id;
    }

    // const newLink: string = composeLink({
    //   inbox: inboxName,
    //   className,
    //   id: 'new',
    //   ...parentData,
    // });

    showModalAttach({
      attachParams: {
        simpleTable: true,
        tableId,
        ...addGuidItemsTable,
      },
      refreshIDs: refreshID,
      cardForm,
      ...parentData,
    });
  };

  const tableViewMassReplaceTool = async () => {
    showModalMassReplace({
      objectClassName: massReplaceTool.openModal.className,
      massReplaceParams: {
        occurrencesField: massReplaceTool.openModal.occurrencesField,
        tableId,
      },
      refreshIDs: refreshID,
    });
  };

  const tableViewAddTool = async (creationType: TransitionType = TransitionType.SAME_TAB) => {
    const obj = await handleEditorChanged();
    if (!obj) return;

    const parentData: { parentId?: string; parentClassName?: string } = {};
    let layoutParentId: string | undefined;
    if (isCardTable && obj !== true) {
      parentData.parentId = getChildrenParentID(obj);
      parentData.parentClassName = obj.classType;
      layoutParentId = obj.id;
    }

    const newLink: string = composeLink({
      inbox: inboxName,
      className,
      id: 'new',
      ...parentData,
    });

    switch (creationType) {
      case TransitionType.SAME_TAB:
      case TransitionType.SOME_TAB: {
        history.push(newLink);
        return;
      }
      case TransitionType.NEW_TAB: {
        window.open(newLink, '_blank')?.focus();
        return;
      }
      case TransitionType.MODAL: {
        showModalCreate({
          objectClassName: className,
          refreshIDs: [refreshID],
          ...parentData,
          layoutParentId,
          parentDetails: obj !== true ? obj : null,
        });
        return;
      }
    }
  };

  const tableViewExportTool = async (sort: ISort, controller?: string) => {
    if (!filters || !filtersConfiguration || loading) return;
    globalLoader.setLoading(true);
    const response: AxiosResponseNull = !!controller ? 
      await ServerAPI.axiosFormDataPost(controller, {
        inboxName: inboxName || '',
        encoding: 'UNICODE',
        sortColumn: sort.nameColumn || '',
        orderDirection: sort.orderDirection || '',
        search: getSearchForExport(),
        className
      }).catch((err: ServerError) => err.notify(width)) : 
      await ServerAPI.exportRegistry({
        inboxName: inboxName || '',
        encoding: 'UNICODE',
        sortColumn: sort.nameColumn || '',
        orderDirection: sort.orderDirection || '',
        search: getSearchForExport(),
      }).catch((err: ServerError) => err.notify(width));
    globalLoader.setLoading(false);
    processExportResponse(response);
  };

  const tableViewAskExportResult = async (id: string) => {
    return ServerAPI.askExportResult({
      inboxName: inboxName || '',
      reportId: id,
      search: getSearchForExport(),
    }).catch((err: ServerError) => err.notify(width));
  }

  const tableViewExportMessagesTool = async (e: any, toolName: string, toolRequestId: string) => {
    e?.preventDefault();
    if (!filters || !filtersConfiguration || loading) return;
    globalLoader.setLoading(true);
    let response: AxiosResponseNull = await ServerAPI.exportMessages({
      inboxName: inboxName || '',
      encoding: 'UNICODE',
      toolName,
      toolRequestId,
      search: getSearchForExport(),
    }).catch((err: ServerError) => err.notify(width));
    while (!!response?.data.id) {
      response = await tableViewAskExportResult(response?.data.id);
    }
    globalLoader.setLoading(false);
    processExportResponse(response);
  };

  const tableViewExportTemplateTool = async () => {
    globalLoader.setLoading(true);

    const response: AxiosResponseNull = await ServerAPI.exportTemplate(
      { className }
    ).catch((err: ServerError) => err.notify(width));

    globalLoader.setLoading(false);
    processExportResponse(response);
  };

  const tableViewImportTool = () => {
    showModalImportFiles({ objectClassName: className, refreshIDs: refreshID });
  };

  return {
    tableViewAttachTool,
    tableViewMassReplaceTool,
    tableViewAddTool,
    tableViewExportTool,
    tableViewExportMessagesTool,
    tableViewExportTemplateTool,
    tableViewImportTool,
  };
};
