export const CSDP_CONSOLE_LOGO = `
_______  _______  ______   _______ 
(  ____ \\(  ____ \\(  __  \\ (  ____ )
| (    \\/| (    \\/| (  \\  )| (    )|
| |      | (_____ | |   ) || (____)|
| |      (_____  )| |   | ||  _____)
| |            ) || |   ) || (      
| (____/\\/\\____) || (__/  )| )      
(_______/\\_______)(______/ |/       
`;

export const IRK_CONSOLE_LOGO = `
___________ _   __
|_   _| ___ \\ | / /
 | | | |_/ / |/ / 
 | | |    /|    \\ 
_| |_| |\\ \\| |\\  \\
\\___/\\_| \\_\\_| \\_/
                  
                  
`

