import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, AutoComplete, RefSelectProps } from 'antd';
import { cloneDeep } from 'lodash';

import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import DropdownIcon from 'components/icons/DropdownIcon';
import DisabledIcon from 'components/icons/DisabledIcon';
import TransitionButton from './TransitionButton';
import DisabledSelect from './DisabledSelect';
import HintIcon from './HintIcon';
import withComponentRefresh from 'components/hocs/withComponentRefresh';
import FormHandler, { getNewComponentsProps } from 'controllers/FormHandler';

import styles from './SelectControl.module.css';

import { BaseComponentProps, WithRefreshComponentProps } from 'interfaces/BaseComponentProps';
import { DataType } from 'interfaces/BaseComponentInterface';
import { AppState } from 'store/reducers';
import { LinkedChoiceRequestParams } from 'utils/ControlFactory';
import { useRefreshComponent } from 'hooks';
import { RefreshID } from 'enums/refresh';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export function getPopupContainer(props: any) {
  const parentContainer: any = props.closest('.js-parentNode');
  if (parentContainer) {
    return parentContainer;
  }
  return document.body;
}

export interface SelectProps extends BaseComponentProps, WithRefreshComponentProps {
  mockData?: any;
}

export function getLinkedData(ownProps: any, context: any) {
  const isLinked: boolean = ownProps.component?.params?.linkedChoice;
  if (isLinked && context.factory) {
    const propName: string = ownProps.component?.propName || '';
    const formValues: any = context.form.getFieldsValue(true);
    const params: LinkedChoiceRequestParams = {
      classType: context.className,
      propName,
      value: formValues[propName],
      choiceName: ownProps.component?.choiceName || '',
    };
    context.factory
      .fetchLinkedChoiceData(params)
      .then((linkedValues: any) => {
        if (linkedValues && Array.isArray(linkedValues)) {
          linkedValues.forEach((linkedValue: any) => {
            context.form.setFieldsValue(linkedValue);
          });
        }
      })
      .catch(() => {
        /* no-op */
      });
  }
}

export const convertDataToType = (dataType: DataType, data: any[], value?: any, propName?: string) => {
  const filtred: any[] = cloneDeep(data);
  filtred.forEach((item: any, i: number) => {
    switch (dataType) {
      case DataType.LONG:
        filtred[i].index = Number(item.index);
        break;
      case DataType.STRING:
      case DataType.GUID:
        filtred[i].index = `${item.index}`;
        break;
      // default:
      //   notification.warn({
      //     message: `unhandled data type: ${ownProps.component?.dataType}`
      //   });
    }
  });
  return filtred;
};

export interface OptionData {
  key: string;
  value: string | null;
  label: string;
}

export interface OptionComponentData {
  key: string;
  value: string | null;
  children: string;
}

type HandleChangeFunction = (value?: string, option?: OptionComponentData) => void;

export const valueToOption = (value: any): OptionData => ({
  key: JSON.stringify(value),
  value: value.index,
  label: value.label,
});

export const getOptionsJSX = (options: OptionData[]): ReactNode[] =>
  options.map((item) => (
    <Option key={item.key} value={item.value}>
      {item.label}
    </Option>
  ));

export const getSelectParams = (component: SelectProps['ownProps']['component']) => {
  let { jsonConfig, params } = component || {};

  if (jsonConfig && typeof jsonConfig === 'string') {
    try {
      jsonConfig = JSON.parse(jsonConfig);
    } catch {
      jsonConfig = null;
    }
  }

  return {
    ...params,
    ...jsonConfig?.cardDisplay?.select,
    ...jsonConfig?.cardDisplay?.linkedSelect,
  };
};

export const getLinkedSelectProp = (
  component: SelectProps['ownProps']['component']
): string | undefined => {
  const { secondProperty } = getSelectParams(component);
  if (typeof secondProperty === 'string' && secondProperty) return secondProperty;
  return;
};

export const isLinkedSelect = (component: SelectProps['ownProps']['component']): boolean => {
  return !!getLinkedSelectProp(component);
};

export const emptyOption: OptionData = {
  key: '-$$__EMPTY__$$!',
  value: '-$$__EMPTY__$$!',
  label: 'Выбрать значение',
};
export const nullOption: OptionData = {
  key: '-$$__NULL__$$!',
  value: '-$$__NULL__$$!',
  label: 'N/A',
};

const isEmptyOption = (option?: any): boolean => option?.key === emptyOption.key;
const isNullOption = (option?: any): boolean => option?.key === nullOption.key;

export const isSelectEmptyValue = (value: any): boolean =>
  [emptyOption.value, nullOption.value].includes(value);

export const clearIfSelectEmptyValue = (value: any) => {
  if (isSelectEmptyValue(value)) return '';

  return value;
};

export const clearSelectEmptyValues = (values: any) => {
  if (values === null || typeof values !== 'object') return values;

  for (const key in values) {
    values[key] = clearIfSelectEmptyValue(values[key]);
  }

  return values;
};

export const SelectControl: React.FC<SelectProps> = (props) => {
  const { ownProps } = props;
  const {
    templateName,
    renderer,
    multiple,
    readonly,
    dataType = DataType.STRING,
    hint,
    propName,
    choiceName,
  } = ownProps?.component || {};

  const {
    transition = false,
    tags = false,
    emptyOption: addEmptyOption = true,
    nullOption: addNullOption = false,
    secondProperty: secondPropName,
    manualInputOnChange,
  } = getSelectParams(ownProps?.component);

  const choiceValues = useSelector(
    (state: AppState) => choiceName && state.choiceLists[choiceName]?.choiceItems
  );
  const context = useContext(EntityEditorContext);
  const { form, onValuesChange } = context;
  const ref = useRef<RefSelectProps>(null);
  const focusTimeoutRef: any = useRef(null);
  const filterInputRef = useRef<string>('');
  const [options, setOptions] = useState<any[]>([]);
  const [opened, setOpened] = useState<boolean>(false);
  const {refreshComponent} = useRefreshComponent();

  const getFieldValue = useCallback(
    (prop?: string) => {
      return (prop && form?.getFieldValue(prop)) || null;
    },
    [form]
  );

  const setFieldValue = useCallback(
    (prop?: string, value?: any) => {
      if (prop) form?.setFieldsValue({ [prop]: value });
    },
    [form]
  );

  const {t} = useTranslation();

  const autoComplete = templateName === 'autocomplete' || renderer === 'autocomplete';
  const linkedSelect = !autoComplete && !!secondPropName;

  const mode: 'multiple' | 'tags' | undefined =
    (tags && 'tags') || (multiple && 'multiple') || undefined;

  const defaultValue = props.value || mode ? [] : '';

  useEffect(() => {
    if (renderer?.includes('WithListener') || templateName?.includes('WithListener')) {
      const newComponents = getNewComponentsProps('/OnChangeField/changeLayout', {propName: propName!, value: getFieldValue(autoComplete ? secondPropName : propName), object: {classType: context.factory!.classType!, id: context.factory?.layoutConfig.id}});
      newComponents.then(components => {
        components.forEach(component => {
          context.factory?.modifyComponent(component.propName, {...component});
        })
      })
    }
  }, [])

  // for linkedSelect
  const renderSecondValue: string = getFieldValue(secondPropName) || '';
  const [searchValue, setSearchValue] = useState<string>(renderSecondValue);

  useEffect(() => {
    if (linkedSelect) setSearchValue(renderSecondValue);
  }, [renderSecondValue]);

  // for linkedSelect - to control clear and showing all choices
  const preventClear = useRef<boolean>(true);
  const allChoices = useRef<boolean>(false);

  useEffect(() => {
    if (!autoComplete) ref.current?.blur();

    let rawValues: any[] = [];

    if (ownProps.values) {
      rawValues = convertDataToType(dataType, Object.values(ownProps.values));
    } else if (context && form && choiceValues && ownProps && propName) {
      const formValue: any = getFieldValue(propName);
      const _choiceValues: any[] = convertDataToType(
        dataType,
        Object.values(choiceValues),
        formValue,
        ownProps?.component?.propName
      );
      rawValues = _choiceValues;
    }

    rawValues =
      rawValues.length > 0 ? rawValues : props.mockData !== undefined ? props.mockData : [];

    if (!rawValues?.length) {
      setOptions((prev) => (prev.length ? [] : prev));
      return;
    }

    const optValues: OptionData[] = [];

    if (!mode) {
      if (addEmptyOption) optValues.push({
        ...emptyOption,
        label: process.env.REACT_APP_TYPE === 'irk' ? t('selectValue') : t('noValue')
      });
      if (addNullOption) optValues.push(nullOption);
    }

    optValues.push(...rawValues.map(valueToOption));

    // set default opt label for autoComplete using second prop with id
    if (autoComplete) {
      const secondPropValue = getFieldValue(secondPropName);
      if (secondPropValue) {
        const defOpt: OptionData | undefined = optValues.find(
          (opt) => opt.value === secondPropValue
        );
        if (defOpt && getFieldValue(propName) !== defOpt.label) {
          setFieldValue(propName, defOpt.label);
        }
      }
    }
    // if valid option value - show choices
    else if (linkedSelect) {
      const propValue: string | number = getFieldValue(propName);
      if (propValue && optValues.find((opt) => opt.value === propValue)) allChoices.current = true;
    }

    setOptions(optValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, form, choiceValues, ownProps]);

  const shouldUpdateProp = (name?: string, value?: string | null): boolean => {
    if (!name) return false;
    const fieldValue = getFieldValue(name);
    return !!(value || fieldValue) && value !== fieldValue;
  };

  const handlePropChange = (propValue?: string | null): void => {
    if (!shouldUpdateProp(propName, propValue)) return;

    setFieldValue(propName, propValue);
    getLinkedData(ownProps, context);

    if (onValuesChange && propName) onValuesChange({ [propName]: propValue });
  };

  const handleSecondPropChange = (secondPropValue?: string | null, option?: any): void => {
    if (!shouldUpdateProp(secondPropName, secondPropValue)) return;

    setFieldValue(secondPropName, secondPropValue);

    if (onValuesChange && propName) {
      if (option) {
        if (!isEmptyOption(option) && !isNullOption(option)) {
          // for onChange to get actual secondPropValue in formValues
          onValuesChange({ [propName]: option.value });
        }
      } else if (manualInputOnChange) {
        // triggers onChange on every manual input
        onValuesChange({ [propName]: '' });
      }
    }
  };

  // autocomplete is not used and pretty likely needs logic update
  const handleAutocompleteChange: HandleChangeFunction = (value, option) => {
    if (!autoComplete) return;

    let propValue: string | null | undefined;
    let secondPropValue: string | undefined;
    let openValue: boolean | undefined;

    // if empty or null option chosen - set ''
    if (isEmptyOption(option) || isNullOption(option)) {
      propValue = '';
      openValue = false;
    }
    // for autoComplete options use label - get from children
    else if (option) {
      propValue = option.children;
      openValue = false;
      getLinkedData(ownProps, context);
    } else if (value) {
      openValue = true;
    }

    secondPropValue = option?.value || '';

    handleSecondPropChange(secondPropValue, option);

    if (propValue !== undefined) handlePropChange(propValue);

    if (openValue !== undefined) setOpened(openValue);

    if (focusTimeoutRef.current) clearTimeout(focusTimeoutRef.current);

    focusTimeoutRef.current = setTimeout(() => {
      ref.current?.focus();
    }, 300);
  };

  const handleLinkedSelectChange: HandleChangeFunction = (value, option) => {
    if (!linkedSelect) return;

    let propValue: string | null | undefined;
    let secondPropValue: string | undefined;

    // if empty or null option chosen - set ''
    if (isEmptyOption(option) || isNullOption(option)) propValue = '';
    else if (option) getLinkedData(ownProps, context);

    if (option) {
      secondPropValue = isEmptyOption(option) ? '' : option.children;
    } else if (value || !preventClear.current) {
      secondPropValue = value || '';
      propValue = '';
    }

    if (secondPropValue !== undefined) {
      handleSecondPropChange(secondPropValue, option);
      setSearchValue(secondPropValue);
    }

    if (propValue !== undefined) handlePropChange(propValue);
  };

  const handleSelectChange: HandleChangeFunction = (value, option) => {
    if (autoComplete || linkedSelect) return;

    if (isEmptyOption(option) || isNullOption(option)) {
      handlePropChange('');
    } else if (option) {
      getLinkedData(ownProps, context);
    }
  };

  const handleChange: HandleChangeFunction = (value, option) => {
    if (autoComplete) return handleAutocompleteChange(value, option);
    if (linkedSelect) return handleLinkedSelectChange(value, option);
    return handleSelectChange(value, option);
  };

  const onSelect = (value: string, option: any) => {
    if (linkedSelect) allChoices.current = true;
    if (renderer?.includes('WithListener') || templateName?.includes('WithListener')) {
      const newComponents = getNewComponentsProps('/OnChangeField/changeLayout', {propName: propName!, value, object: {classType: context.factory!.classType!, id: context.factory?.layoutConfig.id}});
      newComponents.then(components => {
        components.forEach(component => {
          context.factory?.modifyComponent(component.propName, {...component});
        })
        refreshComponent(RefreshID.EDITOR_DETAILS)
      })
    }

    handleChange(value, option);
  };

  const onSearch = (value: string) => {
    if (linkedSelect && value && allChoices.current) allChoices.current = false;

    handleChange(value);

    // prevent clear only once - for automatic clear
    if (linkedSelect && preventClear.current) preventClear.current = false;
  };

  const filterOption = useCallback((input: string, option: any): boolean => {
    filterInputRef.current = input;

    if (allChoices.current || !input) return true;

    return option.children.toLowerCase().indexOf(input.toLowerCase()) > -1;
  }, []);

  const filterSort = useCallback((optionA: any, optionB: any): 0 | 1 | -1 => {
    const input = filterInputRef.current;

    if (input) {
      const aIndex: number = optionA?.children.toLowerCase().indexOf(input.toLowerCase());
      const bIndex: number = optionB?.children.toLowerCase().indexOf(input.toLowerCase());

      if (aIndex === 0 && bIndex !== 0) return -1;
      if (bIndex === 0 && aIndex !== 0) return 1;
    }

    return 0;
  }, []);

  const onDropdownVisibleChange = useCallback(
    (open: boolean) => {
      // for linkedSelect - prevent automatic clear when dropdown closes
      if (linkedSelect && !open) preventClear.current = true;

      setOpened(open);
    },
    [linkedSelect]
  );

  const onBlur = useCallback(() => {
    if (opened) preventClear.current = true;
    setOpened(false);
  }, [opened]);

  const onClickDropdown = useCallback(
    (e: any) => {
      if (!opened) ref.current?.focus();
      else ref.current?.blur();

      if (linkedSelect && opened) preventClear.current = true;
      setOpened(!opened);
    },
    [opened]
  );

  const suffixIcon = (
    <>
      {readonly ? (
        <DisabledIcon />
      ) : (
        <DropdownIcon onClick={onClickDropdown} className={styles.icon} />
      )}
      {!!transition && (
        <TransitionButton
          transition={transition}
          options={options}
          value={getFieldValue(autoComplete ? secondPropName : propName)}
        />
      )}
    </>
  );
  const showAction: ('click' | 'focus')[] = ['click', 'focus'];
  const baseComponentProps = {
    ref,
    getPopupContainer,
    onSelect,
    onSearch,
    className: `${styles.input} js-parentNode`,
    placeholder: '',
    showSearch: true,
    showArrow: true,
    showAction,
    disabled: readonly,
    suffixIcon,
    dropdownClassName: styles.dropdown,
    optionFilterProp: 'children',
    filterOption,
    filterSort,
    ...FormHandler.getFormProps(props),
  };

  return (
    <div className={styles.container}>
      {!multiple && !tags && readonly ? (
        <DisabledSelect
          suffixIcon={suffixIcon}
          value={
            getFieldValue(autoComplete ? propName : secondPropName) ||
            choiceValues?.[`_${getFieldValue(autoComplete ? secondPropName : propName)}`]?.label ||
            null
          }
        />
      ) : autoComplete ? (
        <AutoComplete
          {...baseComponentProps}
          defaultValue={defaultValue}
          onBlur={onBlur}
          open={opened}
        >
          {getOptionsJSX(options)}
        </AutoComplete>
      ) : linkedSelect ? (
        <Select
          {...baseComponentProps}
          searchValue={searchValue}
          onDropdownVisibleChange={onDropdownVisibleChange}
          open={opened}
          onBlur={onBlur}
        >
          {getOptionsJSX(options)}
        </Select>
      ) : (
        <Select
          {...baseComponentProps}
          defaultValue={defaultValue}
          mode={mode}
          onDropdownVisibleChange={onDropdownVisibleChange}
          value={
            getFieldValue(autoComplete ? secondPropName : propName) ||
            choiceValues?.[`_${getFieldValue(autoComplete ? secondPropName : propName)}`]?.label ||
            []
          }
        >
          {getOptionsJSX(options)}
        </Select>
      )}
      {!!hint && <div className={styles.info}><HintIcon hint={hint} /></div>}
    </div>
  );
};

export default withComponentRefresh(SelectControl);
