import styles from './DropdownBlockSelect.module.css';
import { getPopupContainer } from 'components/controls/SelectControl/SelectControl';
import DropdownIcon from 'components/icons/DropdownIcon';
import React, { useEffect, useRef, useState } from 'react';
import stylesSelect from '../../components/controls/SelectControl/SelectControl.module.css';
import { Select } from 'antd';
import axios from 'axios';
import { DefaultOptionType } from 'antd/lib/select';
import { isArray } from 'lodash';

interface ChoiceParams {
  [key: string]: string;
}

interface Choices {
  [key: string]: {
    Version: string;
    choiceItems: {
      [key: string]: {
        index: string;
        label: string;
        params: ChoiceParams;
      };
    };
  };
}

interface IDropdownBlockSelectProps {
  choiceName: string;
  viewConfig: {
    label: string;
    key: string;
  }[];
}

interface ICustomOption extends DefaultOptionType {
  params: ChoiceParams;
}

const DropdownBlockSelect = ({ choiceName, viewConfig }: IDropdownBlockSelectProps) => {
  const [options, setOptions] = useState<ICustomOption[]>([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [chosen, setChosen] = useState<ICustomOption>();

  useEffect(() => {
    axios
      .get<Choices>('SPChoice/list', {
        params: {
          choiceNames: `${choiceName}=null`,
        },
      })
      .then((res) => {
        const choices = Object.values(res.data)[0].choiceItems;
        setOptions(
          Object.values(choices).map((choice) => ({
            label: choice.label,
            value: choice.params.ChoiceCode,
            params: {
              ...choice.params,
            },
          }))
        );
      })
      .catch((err) => err);
  }, []);

  const ref: any = useRef(null);

  const dropdownIcon = (
    <DropdownIcon
      onClick={() => {
        if (ref && ref.current && ref.current.focus && ref.current.blur) {
          ref.current.focus();
        }
      }}
      className={stylesSelect.icon}
    />
  );

  const onSelectClick = (isOpen?: boolean) => {
    const setStateFunc = (prev: boolean) => (typeof isOpen !== 'undefined' ? isOpen : !prev);
    setIsSelectOpen(setStateFunc);
  };

  const onSelectChange = (_: number, value: ICustomOption | ICustomOption[]) => {
    if (!isArray(value)) {
      setChosen(value);
    }
  };

  return (
    <div>
      <Select
        ref={ref}
        suffixIcon={dropdownIcon}
        getPopupContainer={getPopupContainer}
        dropdownClassName={stylesSelect.dropdown}
        className={stylesSelect.input}
        options={options}
        onChange={onSelectChange}
        open={isSelectOpen}
        onClick={() => onSelectClick()}
        onBlur={() => onSelectClick(false)}
      ></Select>
      {chosen && (
        <div className={styles.infoWrapper}>
          <div className={styles.infoRowsContainer}>
            <div className={styles.infoRow}>
              {viewConfig.map((item, idx) => (
                <div key={idx}>
                {
                    chosen.params[item.key] && item.label
                }
                </div>
              ))}
            </div>
            <div className={styles.infoRow}>
              {viewConfig.map((item, idx) => (
                <div key={idx}>{chosen.params[item.key]}</div>
              ))}
            </div>
            <div className={styles.infoRow}></div>
          </div>
          <div
            className={styles.innerHtml}
            dangerouslySetInnerHTML={{ __html: chosen.params.htmlText }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default DropdownBlockSelect;
