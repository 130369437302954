import { Dispatch } from 'redux';
import { FormInstance } from 'antd';
import { CancelToken } from 'axios';

import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import notification from 'components/messages/notification';
import ControlFactory from 'utils/ControlFactory';
import ContentCrud from './ContentCrud';
import FormHandler from './FormHandler';

import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import { JsObject, JsObjectNested, ObjectData, ObjectParams } from 'interfaces/Object';
import { EditorFile, EditorFilesState } from 'store/reducers/editor_files';
import { isNewID, getChildrenParentID, getDocumentId } from 'utils/ObjectUtils';

export type PrepareObjectForServer = (args: {
  form?: FormInstance;
  editorFiles?: EditorFilesState;
  details?: ObjectData;
  create?: boolean;
}) => {
  objectData?: ObjectData;
  fileIDsToAttach?: IFileIdsToAttach;
};

export interface IFileIdsToAttach {
    [key: string]: string[]
}

// обработка объекта и файлов перед отправкой на сервер
export const prepareObjectForServer: PrepareObjectForServer = ({
  form,
  editorFiles,
  details,
  create,
}) => {
  const objectData: ObjectData = FormHandler.buildObjectData(form, details);

  const fileIDsToAttach: IFileIdsToAttach = {};

  if (objectData.params && Object.entries(objectData.params).find(([propName, _]) => propName.includes('Files'))) {
    // добавленные файлы, которые ещё не привязаны к объекту
    const newFileIDs: string[] = [];
    const documentId = getDocumentId(details, form);
    const objectFiles = (documentId && editorFiles?.[documentId]) || {};

    Object.values(objectFiles).forEach((fileInfo: EditorFile) => {
      if (fileInfo.toSave) newFileIDs.push(fileInfo.file.id);
    });

    // новые файлы добавляются в массив fileIDsToAttach на серверную привязку
    // в params.Files остаются айди файлов, которые уже привязаны
    Object.entries(objectData.params).forEach(([propName, _]) => {
      if (propName.includes('Files')) {
        objectData.params![propName] = objectData.params![propName].filter((fileId: string) => {
        const isNewFile: boolean = newFileIDs.includes(fileId);
        if (isNewFile) {
          fileIDsToAttach[propName] ? fileIDsToAttach[propName].push(fileId) : fileIDsToAttach[propName] = [fileId];
        }
        return !isNewFile;
        });
      }
    })
  }

  if (create) {
    objectData.isDirty = false;
    delete objectData.id;
  }

  return { objectData, fileIDsToAttach };
};

export interface PreFillNewObjectOutput {
  objectData?: ObjectData; // from prefill response
}

export type PreFillNewObject = (args: {
  className: string;
  parentId?: string;
  parentClassName?: string;
  parentDetails?: ObjectData;
  parentForm?: FormInstance;
  cancelToken?: CancelToken;
  width?: number;
  inboxName?: string;
}) => Promise<PreFillNewObjectOutput>;

export const preFillNewObject: PreFillNewObject = async ({
  className,
  parentId,
  parentClassName,
  parentDetails,
  parentForm,
  cancelToken,
  width,
  inboxName,
}) => {
  const objectData: ObjectData = FormHandler.buildObjectData(parentForm, parentDetails);
  const objectParams: ObjectParams = objectData.params || {};
  const parentID = parentId || getChildrenParentID(objectData) || undefined;
  const parentClassType = parentClassName || objectParams.classType || undefined;
  const response: AxiosResponseNull = await ServerAPI.preFillNewObject(
    {
      className,
      inboxName: parentDetails?.inboxName,
      map: {
        parentID,
        parentClassType,
        AircraftType: objectParams.AircraftType,
        ClientOrganization: objectParams.ClientOrganization,
      },
    },
    { cancelToken }
  ).catch((serverError: ServerError) => serverError.notify(width));

  const serverData: ObjectData = response?.data?.[0];

  if (serverData?.params) {
    const nowd = new Date().toISOString();
    serverData.params.DateCreated = nowd;
    serverData.params.DateLastModified = nowd;
  }

  return { objectData: serverData };
};

export interface ObjectInfoOutput {
  objectData?: ObjectData; // from get object response
  newID?: boolean; // if got object with another id
  files?: any; // from get files response
}

export type GetObjectInfo = (args: {
  objectData?: ObjectData;
  className?: string;
  objectID?: string;
  cancelToken?: CancelToken;
  width?: number;
  dispatch?: Dispatch;
}) => Promise<ObjectInfoOutput>;

export const getObjectInfo: GetObjectInfo = async ({
  objectData,
  className,
  objectID,
  cancelToken,
  width,
  dispatch,
}) => {
  let data: ObjectData | undefined = objectData;

  if (!data?.params && className && objectID) {
    const infoResponse: AxiosResponseNull = await ServerAPI.getObjectInfoById(
      { property: { className, objectID } },
      { cancelToken }
    ).catch((serverError: ServerError) => serverError.notify(width));

    data = infoResponse?.data;
  }

  if (!data || !data.params || !data.id) return {};

  const output: ObjectInfoOutput = { objectData: data };

  if (objectID && data.id && data.id !== objectID) {
    output.newID = true;
    return output;
  }

  className = className || data.classType;

  if (Object.entries(data.params).find(([propName, val]) => propName.includes('Files') && val?.length > 0) && className)
    output.files = await ContentCrud.getFiles({
      className,
      linkedId: getChildrenParentID(data),
      dispatch,
      cancelToken,
      width,
    });

  return output;
};

export interface CreateObjectOutput {
  valueObject?: ObjectData; // from create response
  choicesForUpdate?: any; // from create response
  notification?: string; // from create response
  choiceLists?: any; // from choice list response
  objectData?: ObjectData; // processed valueObject
}

export type CreateObject = (args: {
  toolName?: string;
  details?: ObjectData;
  editorFiles?: EditorFilesState;
  form?: FormInstance;
  cancelToken?: CancelToken;
  width?: number;
  dispatch?: Dispatch;
}) => Promise<CreateObjectOutput>;

export const createObject: CreateObject = async ({
  toolName,
  details,
  editorFiles,
  form,
  cancelToken,
  width,
  dispatch,
}) => {
  const { objectData, fileIDsToAttach } = prepareObjectForServer({
    form,
    editorFiles,
    details,
    create: true,
  });

  if (!objectData) return {};

  const response: AxiosResponseNull = await ServerAPI.createObject(
    {
      toolName: toolName || '',
      object: objectData,
    },
    { cancelToken }
  ).catch((serverError: ServerError) => serverError.notify(width));

  const data = response?.data;

  if (!data) return {};

  const output: CreateObjectOutput = { ...data };

  const newObjectData = (output.objectData = data.valueObject);

  if (!newObjectData?.id || !newObjectData?.classType) return output;

  const attachObjectData = await ContentCrud.attachFiles({
    fileIDs: fileIDsToAttach,
    linkedClassName: newObjectData.classType,
    linkedId: getChildrenParentID(newObjectData),
    cancelToken,
    width,
    dispatch,
  });

  if (attachObjectData) output.objectData = attachObjectData;

  notification.success({
    data: data.notification,
    screenWidth: width,
  });

  return output;
};

export interface UpdateObjectOutput {
  newObject?: ObjectData; // from update response
  notification?: string; // from update response
  objectData?: ObjectData; // processed newObject
  reload?: boolean; // if needs rerender
  answer?: string;
  success?: boolean;
}

export type UpdateObject = (args: {
  toolName?: string;
  details?: ObjectData;
  editorFiles?: EditorFilesState;
  form?: FormInstance;
  cancelToken?: CancelToken;
  width?: number;
  dispatch?: Dispatch;
}) => Promise<UpdateObjectOutput>;

export const updateObject: UpdateObject = async ({
  toolName,
  details,
  editorFiles,
  form,
  cancelToken,
  width,
  dispatch,
}) => {
  const { objectData, fileIDsToAttach } = prepareObjectForServer({ form, editorFiles, details });

  if (!objectData) return {};

  const response: AxiosResponseNull = await ServerAPI.updateObject(
    {
      toolName: toolName || '',
      object: objectData,
    },
    { cancelToken }
  ).catch((serverError: ServerError) => {
    if ((serverError as any).error.password || (serverError as any).error.repeatPassword) {
      form?.setFields([
        {
          name: 'Password',
          errors: [(serverError as any).error.password],
        },
        {
          name: 'PasswordRepeat',
          errors: [(serverError as any).error.repeatPassword],
        },
      ]);
      notification.error({
        //@ts-ignore
        data: serverError.error.repeatPassword || serverError.error.password,
        screenWidth: width,
      });

      return null;
    }
    return serverError.notify(width);
  });

  const data = response?.data;

  if (!data) return {};

  const output: UpdateObjectOutput = { ...data };
  const newObjectData = (output.objectData = data.newObject);
  output.reload = newObjectData?.classType === 'DocumentationMto';

  if (!newObjectData?.id || !newObjectData?.classType) return output;

  const attachObjectData = await ContentCrud.attachFiles({
    fileIDs: fileIDsToAttach,
    linkedClassName: newObjectData.classType,
    linkedId: getChildrenParentID(newObjectData),
    cancelToken,
    width,
    dispatch,
  });

  if (attachObjectData) {
    output.objectData = attachObjectData;
    output.reload = true;
  }

  notification.success({
    data: data.notification,
    screenWidth: width,
  });

  return output;
};

export interface OnChangeDataOutput {
  rawValues?: JsObject;
  formValues?: JsObject;
  paramsMap?: JsObjectNested;
  factory?: ControlFactory;
}

export type GetOnChangeData = (args: {
  propName: string;
  form?: FormInstance;
  details?: ObjectData;
  factory?: ControlFactory;
  width?: number;
}) => Promise<OnChangeDataOutput>;

export const getOnChangeData: GetOnChangeData = async ({
  propName,
  form,
  details,
  factory,
  width,
}) => {
  const object: ObjectData = FormHandler.buildObjectData(form, details);
  object.id = isNewID(object.id) ? '' : object.id;

  const response: AxiosResponseNull = await ServerAPI.getOnChangeData({ object, propName }).catch(
    (serverError: ServerError) => serverError.notify(width)
  );

  const data = response?.data?.filledFields;
  if (!data) return {};

  const rawValues: JsObject | undefined = data.values;
  const formValues: JsObject | undefined = FormHandler.normalizeFormData(rawValues);
  const paramsMap: JsObjectNested = {}; // {propName: componentParams}
  const output: OnChangeDataOutput = { rawValues, formValues, paramsMap };

  if (form && formValues) form.setFieldsValue(formValues);

  // fill paramsMap
  ['readonly', 'visible'].forEach((param: string) => {
    if (!data[param]) return;

    Object.keys(data[param]).forEach((propName: string) => {
      paramsMap[propName] = paramsMap[propName] || {};
      paramsMap[propName][param] = data[param][propName];
    });
  });

  output.factory = factory?.modifyComponentsAndCloneIfModified(paramsMap);

  return output;
};

interface IUpdatePassword {
  newPassword: string;
  repeatedPassword: string;
  userId?: string;
  form?: FormInstance;
  width?: number;
}

const updatePassword = async ({
  newPassword,
  repeatedPassword,
  form,
  width,
  userId,
}: IUpdatePassword) => {
  return ServerAPI.updatePassword({
    'new-password': newPassword,
    'repeated-password': repeatedPassword,
    userId: userId,
  })
    .then((res) => {
      notification.success({
        data: res?.data.message,
        screenWidth: width,
      });
      return res;
    })
    .catch((serverError: ServerError) => {
      if (serverError.message) {
        form?.setFields([
          {
            name: 'Password',
            errors: [''],
          },
          {
            name: 'PasswordRepeat',
            errors: [''],
          },
        ]);
        notification.error({
          //@ts-ignore
          data: serverError.message,
          screenWidth: width,
        });

        return serverError;
      }
    });
};

const ObjectCrud = {
  prepareObjectForServer,
  preFillNewObject,
  getObjectInfo,
  createObject,
  updateObject,
  getOnChangeData,
  updatePassword,
};

export default ObjectCrud;
