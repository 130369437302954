import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useWindowSize } from '@react-hook/window-size';
import { Spin } from 'antd';
import axios from 'axios';
import cn from 'classnames';

import UploadIcon from 'components/icons/UploadIcon';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import notification from 'components/messages/notification';

import styles from './FileNew.module.css';

import { useEditorChanged } from 'hooks';
import useObjectDetails from 'hooks/useObjectDetails';

import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import { ActionType } from 'store/actionTypes';
import HintIcon from '../SelectControl/HintIcon';
import { useTranslation } from 'react-i18next';
import { getSize } from './FileItem';
import { TFunction } from 'i18next';

interface FileNewProps extends BaseComponentProps {
  standalone?: boolean;
  onFileSelect?: (file: File) => void;
  totalMaxSize?: number;
  allFilesSize?: number
}

export const convertNewFileToUploadedFiles = (file: File) => {
  return {
    params: {
      fileName: file.name,
      fileSize: file.size,
      Url: '#',
      unsaved: true,
    },
  };
};

export const addFile = (dispatch: any, saveParams: any, newAcceptedFiles: any, context: any, ownProps: any, width: any, updateEditorChanged: (isUpdate: boolean) => void, callBack?: () => void, t?: TFunction<"translation", undefined>) => {

  const params: any[] = newAcceptedFiles.reduce((result: any[], current: File) => {
    result.push({
      classType: 'Content',
      params: {
        fileName: current.name,
        mimeType: current.type,
      },
    });
    return result;
  }, []);

  const formData = new FormData();
  formData.append('contentList', JSON.stringify(params));
  axios.post('/ContentCrud/getUploadLinks', formData).then((response: AxiosResponseExt) => {
    if (!response.error) {
      response.data.forEach((fileToUpload: any, index: number) => {
        updateEditorChanged(true);
        dispatch({
          type: ActionType.UPDATE_EDITOR_FILES,
          payload: [
            {
              toSave: true,
              uploading: true,
              documentId: context.documentId,
              saveParams,
              propName: ownProps.component.propName,
              file: {
                ...convertNewFileToUploadedFiles(newAcceptedFiles[index]),
                ...{
                  id: fileToUpload.id,
                },
              },
            },
          ],
        });
        const files: any = [...context.form?.getFieldValue(ownProps.component.propName)];
        if (files.indexOf(fileToUpload.id) === -1) {
          files.push(fileToUpload.id);
        }
        context.form?.setFieldsValue({
          [ownProps.component.propName]: files,
        });

        // sending file to server
        const uploadUrl: string = fileToUpload.url;
        axios
          .put(uploadUrl, newAcceptedFiles[index], {
            headers: {
              'Content-Type': newAcceptedFiles[index].type,
              Authorization: '',
            },
            transformRequest: (d) => {
              return d;
            },
          })
          .then((uploadResponse: AxiosResponseExt) => {
            if (!uploadResponse.error) {
              if (callBack) {
                callBack()
              }
              dispatch({
                type: ActionType.UPDATE_EDITOR_FILE_STATUS,
                payload: {
                  id: fileToUpload.id,
                  toSave: true,
                  uploading: false,
                  documentId: context.documentId,
                },
              });
              notification.success({
                text: t ? t('attachmentSuccessful') : 'Вложение добавлено',
                screenWidth: width,
              });
            } else {
              notification.error({
                text: t ? t('attachmentError') : 'Ошибка прикрепления вложения',
                screenWidth: width,
              });

              dispatch({
                type: ActionType.REMOVE_EDITOR_FILES,
                payload: fileToUpload.id,
              });
            }
          });
      });
    } else {
      notification.error({
        text: response.error,
        screenWidth: width,
      });
    }
  });
}

const FileNew: React.FC<FileNewProps> = (props) => {
  const dispatch = useDispatch();
  const context = useContext(EntityEditorContext);
  const [ready, setReady] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [newAcceptedFiles, setNewAcceptedFiles] = useState<any[]>([]);
  const [newStandaloneFiles, setNewStandaloneFiles] = useState<File>();
  const [width] = useWindowSize();
  const { t } = useTranslation();

  const { updateEditorChanged } = useEditorChanged();
  const { details } = useObjectDetails();

  const { ownProps, standalone, onFileSelect, totalMaxSize, allFilesSize } = props;
  const componentParams = ownProps.component?.params || {};
  const isVisible = componentParams.hideIconAdd;
  const maxFiles: any = componentParams.maxFiles;

  const formFiles = context.form?.getFieldValue(ownProps.component?.propName || 'Files');
  const multiple: boolean = !maxFiles;
  const disableAttaching =
    (!formFiles || Object.keys(formFiles).length >= +maxFiles) && !standalone;

  const acceptExts: string = !!ownProps.component?.params && !!ownProps.component?.params.suffix
    ? ownProps.component.params.suffix.join(',')
    : undefined;

  // TODO: сюда прописать компонент "загрузить файлы"

  useEffect(() => {
    if ((context != null && context.form != null) || standalone) {
      setReady(true);
    }
  }, [context, context.form, standalone]);

  useEffect(() => {
    if (standalone && onFileSelect) {
      onFileSelect(newStandaloneFiles!);
      return;
    }
    if (newAcceptedFiles.length > 0) {
      if (disableAttaching) return;
      if (totalMaxSize) {
        if (newAcceptedFiles[0].size + allFilesSize < totalMaxSize) {
          addFile(dispatch, saveParams, newAcceptedFiles, context, ownProps, width, updateEditorChanged, () => {}, t)
        } else {
          notification.error({
            text: `${t('maximumSizeOfAttachedFilesIs')} ${getSize(totalMaxSize)}`,
            screenWidth: width,
          });
        }
      } else {
        addFile(dispatch, saveParams, newAcceptedFiles, context, ownProps, width, updateEditorChanged, () => {}, t)
      }
    }
  }, [newAcceptedFiles, newStandaloneFiles]);

  const onDrop = useCallback((acceptedFile: File[]) => {
    !standalone ? setNewAcceptedFiles([...acceptedFile]) : setNewStandaloneFiles(acceptedFile[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: acceptExts,
    maxSize: ownProps.component?.params?.maxSize
  });

  const saveParams: any = {
    className: details?.classType,
    VersionSeriesId: details?.params?.VersionSeriesId,
  };

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((rej) => {
        if (rej.errors[0].code === 'file-invalid-type') {
          notification.error({ text: rej.file.name, description: t('wrongFileFormat') })
        }
        if (rej.errors[0].code === 'file-too-large') {
          notification.error({ text: rej.file.name, description: t('fileSizeExceeded') })
        }
      })
    }
  }, [fileRejections])

  return (
    <>
      {ready && !isVisible ? (
        <>
          <Spin spinning={uploading} >
            <div
              {...getRootProps()}
              className={cn(styles.uploadArea, { [styles.uploadArea_disabled]: disableAttaching })}
            >
              <UploadIcon className={styles.icon} />
              <input {...getInputProps()} multiple={multiple} disabled={disableAttaching} />
              {isDragActive ? (
                <p>{t("dragAttachmentHere")}</p>
              ) : (
                <>
                  <span className={styles.title}>{t("addAttachments")}</span>
                  {maxFiles && formFiles && (
                    <span className={styles.subTitle}>
                      {Object.keys(formFiles).length} {t('from')} {maxFiles}
                    </span>
                  )}
                </>
              )}
            </div>
          </Spin>
          {ownProps.component?.hint && <HintIcon hint={ownProps.component?.hint} />}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FileNew;
