import React, { useEffect, useRef, useState } from 'react';
import styles from './RequestsChart.module.css';
// import loadable from '@loadable/component';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';

import LegendBrownIcon from '../../components/icons/LegendBrownIcon';
import LegendRedIcon from '../../components/icons/LegendRedIcon';
import LegendYellowIcon from '../../components/icons/LegendYellowIcon';
import LegendBlueIcon from '../../components/icons/LegendBlueIcon';
import { useWindowSize } from '@react-hook/window-size';
import ReactDOMServer from 'react-dom/server';
import 'chartjs-adapter-date-fns';
import dataLabels from 'chartjs-plugin-datalabels';
import {
  labelsForReport,
  labelsForReportTooltip,
  orderForReport,
  stackForReport,
  typeForReport,
  yAxisIDForReport,
} from './utils';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import {useTranslation} from 'react-i18next';

interface RequestsChartProps {
  dataSource?: any;
  width: number;
  className?: string;
  isRA?: boolean;
}

export const handleFontSize = (width: number) => {
  if (width >= 2560) {
    return 20;
  } else if (width <= 2559 && width >= 1950) {
    return 20;
  } else if (width <= 1949 && width >= 1500) {
    return 15;
  } else if (width <= 1499 && width >= 200) {
    return 10;
  }
};

const RequestsChart: React.FC<RequestsChartProps> = (props) => {
  const ref = useRef<any>();
  const [chart, setChart] = useState<Chart>();

  const style = getComputedStyle(document.body);
  const colorDark = style.getPropertyValue('--color-dark');
  const colorBright = style.getPropertyValue('--color-bright')
  const colorBright2 = style.getPropertyValue('--color-bright-2');
  const colorBright3 = style.getPropertyValue('--color-bright-3');
  const colorBright4 = style.getPropertyValue('--color-bright-4');
  const colorBright5 = style.getPropertyValue('--color-bright-5');
  const colorBright6 = style.getPropertyValue('--color-bright-6');
  const colorBright10 = style.getPropertyValue('--color-bright-10');
  const colorSecondary = style.getPropertyValue('--color-secondary');
  const colorLight = style.getPropertyValue('--color-light');
  const {t} = useTranslation();

  const colorsForReport: any = {
    open_at_the_beginning_of_the_period: colorBright2,
    created_requests: colorBright3,
    finished_on_time: colorBright5,
    finished_out_of_time: colorBright,
    not_finished_processed_on_time: colorSecondary,
    incomplete_out_of_time: colorBright10,
    average_processing_time: colorLight,
    average_processing_time_aog: colorLight,
    average_processing_time_critical: colorLight,
    average_processing_time_routine: colorLight,
    average_processing_time_work_stoppage: colorBright6
  };

  const borderColorForReport: any = {
    open_at_the_beginning_of_the_period: '',
    created_requests: '',
    finished_on_time: '',
    finished_out_of_time: '',
    not_finished_processed_on_time: '',
    incomplete_out_of_time: '',
    average_processing_timeaverage_processing_time: colorBright6,
    average_processing_time_aog: colorBright,
    average_processing_time_critical: colorBright4,
    average_processing_time_routine: colorBright10,
    average_processing_time_work_stoppage: colorBright6,
  };


  const options: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const [dataRequest, setDataRequest] = useState<any>({
    labels: [],
    datasets: [],
  });

  const foundElements: any = [];

  const { dataSource, width, isRA } = props;

  const [rawDataSource, setRawDataSource] = useState<any>();

  useEffect(() => {
    if (dataSource) {
      setRawDataSource(cloneDeep(dataSource));
    }
  }, [dataSource]);

  const handleDifferenceDate = (dateOne: any, dateTwo: any) => {
    return (dateTwo - dateOne) / (60 * 60 * 24 * 1000);
  };

  const handleLocaleDateString = (date: any) => {
    return date.toLocaleDateString('ru', options);
  };

  useEffect(() => {
    if (rawDataSource) {
      const result: any = [];
      [...rawDataSource].forEach((item: any, i: number) => {
        let res;
        Object.keys(item).forEach((label) => {
          res = {
            label: labelsForReport(t)[label],
            title: label,
            backgroundColor: colorsForReport[label],
            type: typeForReport[label],
            yAxisID: yAxisIDForReport[label],
            stack: stackForReport[label],
            borderColor: borderColorForReport[label],
            order: orderForReport[label],
          };
          if (res.label !== undefined && !foundElements.includes(res.title)) {
            result.push(res);
            foundElements.push(res.title);
          }
          if (res.type === '') {
            delete res.type;
          }
          if (res.stack === '') {
            delete res.stack;
          }
          if (res.borderColor === '') {
            delete res.borderColor;
          }
        });
      });
      let labels: any;
      [...result].forEach((item: any) => {
        const date: any = [];
        const res: any = [];
        [...rawDataSource].forEach((obj: any) => {
          if (
            item.title === 'average_processing_time_aog' ||
            item.title === 'average_processing_time_critical' ||
            item.title === 'average_processing_time_routine' ||
            item.title === 'average_processing_time_work_stoppage'
          ) {
            const seconds = obj[item.title];
            obj[item.title] = (seconds / 60 / 60).toFixed(2);
          }
          const result = obj.time_period.map((x: any, i: number) => {
            return new Date(x);
          });
          if (handleDifferenceDate(result[0], result[1]) > 1) {
            date.push(
              `${handleLocaleDateString(result[0])} - ${handleLocaleDateString(result[1])}`
            );
          } else {
            date.push(`${handleLocaleDateString(result[0])}`);
          }
          res.push(obj[item.title]);
        });
        labels = date;
        item.data = res;
      });

      const dataTooltip: any = [];
      [...rawDataSource].forEach((itemSource: any) => {
        const newObj: any = {};
        const data: any = [];
        const result = itemSource.time_period.map((x: any, i: number) => {
          return new Date(x);
        });
        if (handleDifferenceDate(result[0], result[1]) > 1) {
          newObj.date = `${handleLocaleDateString(result[0])} - ${handleLocaleDateString(
            result[1]
          )}`;
        } else {
          newObj.date = `${handleLocaleDateString(result[0])}`;
        }
        Object.keys(labelsForReport(t)).forEach((itemLabel) => {
          const dataObj: any = {};
          dataObj.key = labelsForReport(t)[itemLabel];
          const dataWithLabels: any = [];
          const itemSourceLabels = Object.keys(itemSource)
            .filter((key) => key.includes(itemLabel))
            .slice(1);
          itemSourceLabels.forEach((x) => {
            const statusParse = x.split('_');
            let status = statusParse[statusParse.length - 1];
            switch (status) {
                case "aog":
                    status = status.toUpperCase();
                    break;
                case "critical":
                case "routine":
                    status = status[0].toUpperCase() + status.slice(1);
                    break;
            
                case "stoppage":
                    status = "Work stoppage";
                    break;
                default:
                    break;
            }
            dataWithLabels.push({
              status: status,
              count: itemSource[x],
            });
          });
          dataObj.data = dataWithLabels;
          data.push(dataObj);
        });

        Object.keys(labelsForReportTooltip(t)).forEach((itemLabel) => {
          const dataObj: any = {};
          dataObj.key = labelsForReportTooltip(t)[itemLabel];
          dataObj.data = itemSource[itemLabel];
          data.push(dataObj);
        });

        newObj.data = data;
        dataTooltip.push(newObj);
      });

      const newDataRequest: any = {
        labels,
        datasets: result,
        dataTooltip,
      };
      setDataRequest({ ...newDataRequest });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawDataSource]);

  useEffect(() => {
    handleFontSize(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const drawTooltip = (context: any) => {
    let tooltipEl: any = document.getElementById('chartjs-tooltip');
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<div></div>';
      tooltipEl.classList.add(`${styles.test}`);
      if(process.env.REACT_APP_TYPE==='csdp'){
        tooltipEl.style.width = '50%'
      }
      document.body.appendChild(tooltipEl);
    }
    const tooltipModel: any = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
    tooltipEl.classList.remove('above', 'below', 'no-transform');

    function getBody(bodyItem: any) {
      return bodyItem.lines;
    }

    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);
      const wrapperTooltip = `${isRA ? styles.wrapperTooltipRA : styles.wrapperTooltip}`;

      let innerHtml = '<div class="' + '">';
      titleLines.forEach((title: any) => {
        const titleTooltip: any = `${styles.textTooltip}`;
        innerHtml += '<div class="' + titleTooltip + '">' + title + '</div>';
      });
      innerHtml += '</div><div class="' + wrapperTooltip + '">';
      const subtitleTooltip = `${isRA ? styles.subtitleTooltipRA : styles.subtitleTooltip}`;
      const elementTable = `${styles.elementTable}`;
      const elementTableWrap = `${isRA ? styles.elementTableWrapRA : styles.elementTableWrap}`;
      const wrapTime = `${styles.wrapTime}`;
      const elementTableContainer = `${styles.elementTableContainer}`;
      const wrapCount = `${styles.wrapCount}`;
      const statusWrap = `${styles.statusWrap}`;
      const statusTextWrap = `${styles.statusTextWrap}`;
      bodyLines.forEach((body: any, i: any) => {
        if (
          body[0].split(',')[0] === `${t('averageProcessingTime')} AOG` ||
          body[0].split(',')[0] === `${t('averageProcessingTime')} Critical` ||
          body[0].split(',')[0] === `${t('averageProcessingTime')} Routine` ||
          body[0].split(',')[0] === `${t('averageProcessingTime')} Work Stoppage`
        ) {
          return;
        } else {
          const colors = tooltipModel.labelColors[i];
          let style = 'background:' + colors.backgroundColor;
          style += '; border-color:' + colors.borderColor;
          style += '; border-width: 2px';
          const colorWrap = `${styles.color}`;
          const span: any = '<div style="' + style + '"class="' + colorWrap + '"></div>';
          let title;
          const _dataRequest = context.chart.data;
          if (_dataRequest && _dataRequest.dataTooltip && _dataRequest.dataTooltip.length > 0) {
            _dataRequest.dataTooltip.forEach((item: any, i: number) => {
              if (tooltipModel.title[0] === item.date) {
                if (body[0].split(':')[0] === t('finishedOnTime')) {
                  title =
                    '<div class="' +
                    subtitleTooltip +
                    '">' +
                    '<div class="' +
                    elementTable +
                    '">' +
                    `${item.data[process.env.REACT_APP_TYPE === 'irk' ? 10 : 11].key}, ${t('piece')}:` +
                    '</div>' +
                    '<div class="' +
                    wrapCount +
                    '">' +
                    `${item.data[process.env.REACT_APP_TYPE === 'irk' ? 10 : 11].data}` +
                    '</div>' +
                    '</div>';
                } else if (body[0].split(':')[0] === t('notFinishedProcessedOnTime')) {
                  title =
                    '<div class="' +
                    subtitleTooltip +
                    '">' +
                    '<div class="' +
                    elementTable +
                    '">' +
                    `${item.data[process.env.REACT_APP_TYPE === 'irk' ? 11 : 12].key}, ${t('piece')}:` +
                    '</div>' +
                    '<div class="' +
                    wrapCount +
                    '">' +
                    `${item.data[process.env.REACT_APP_TYPE === 'irk' ? 11 : 12].data}` +
                    '</div>' +
                    '</div>';
                } else if (body[0].split(':')[0] === t('openAtBeginningOfPeriod')) {
                  title =
                    '<div class="' +
                    subtitleTooltip +
                    '">' +
                    '<div class="' +
                    elementTable +
                    '">' +
                    `${item.data[process.env.REACT_APP_TYPE === 'irk' ? 9 : 10].key}, ${t('piece')}:` +
                    '</div>' +
                    '<div class="' +
                    wrapCount +
                    '">' +
                    `${item.data[process.env.REACT_APP_TYPE === 'irk' ? 9 : 10].data}` +
                    '</div>' +
                    '</div>';
                } else {
                  title = '';
                }
                innerHtml +=
                  title +
                  '<div class="' +
                  elementTableWrap +
                  '">' +
                  '<div class="' +
                  elementTableContainer +
                  '">' +
                  span +
                  '<div class="' +
                  elementTable +
                  '">' +
                  body[0].split(':')[0] +
                  '</div>' +
                  '</div>' +
                  '<div class="' +
                  wrapCount +
                  '">' +
                  body[0].split(':')[1] +
                  '</div>' +
                  '</div>';
                return item.data.forEach((element: any) => {
                  if (!!element.data && typeof element.data !== 'number') {
                    return element.data.forEach((el: any) => {
                      if (body[0].split(':')[0] === element.key) {
                        return (innerHtml +=
                          '<div class="' +
                          statusTextWrap +
                          '"><div class="' +
                          statusWrap +
                          '">' +
                          '<div class="' +
                          elementTable +
                          '">' +
                          el.status +
                          '</div>' +
                          '<div class="' +
                          wrapCount +
                          '">' +
                          el.count +
                          '</div>' +
                          '</div></div>');
                      } else {
                        return;
                      }
                    });
                  }
                });
              }
            });
          }
        }
      });
      innerHtml += '</div>';
      innerHtml += '<div class="' + wrapTime + '">';
      if (!isRA) {
        bodyLines.forEach((body: any) => {
          if (
            body[0].split(',')[0] === `${t('averageProcessingTime')} AOG` ||
            body[0].split(',')[0] === `${t('averageProcessingTime')} Critical` ||
            body[0].split(',')[0] === `${t('averageProcessingTime')} Routine` ||
            body[0].split(',')[0] === `${t('averageProcessingTime')} Work Stoppage`
          ) {
            let icon: any;
            if (body[0].split(',')[0] === `${t('averageProcessingTime')} AOG`) {
              icon = ReactDOMServer.renderToString(<LegendRedIcon className={styles.icon} />);
            } else if (body[0].split(',')[0] === `${t('averageProcessingTime')} Critical`) {
              icon = ReactDOMServer.renderToString(<LegendYellowIcon className={styles.icon} />);
            } else if (body[0].split(',')[0] === `${t('averageProcessingTime')} Routine`) {
              icon = ReactDOMServer.renderToString(<LegendBlueIcon className={styles.icon} />);
            } else if (body[0].split(',')[0] === `${t('averageProcessingTime')} Work Stoppage`) {
              icon = ReactDOMServer.renderToString(<LegendBrownIcon className={styles.icon} />);
            }
            return (innerHtml +=
              '<div class="' +
              elementTableWrap +
              '">' +
              '<div class="' +
              elementTableContainer +
              '">' +
              icon +
              '<div class="' +
              elementTable +
              '">' +
              body[0].split(',')[0] +
              '</div>' +
              '</div>' +
              '<div class="' +
              wrapCount +
              '">' +
              body[0].split(':')[1] +
              '</div>' +
              '</div>');
          }
        });
      } else {
        bodyLines.forEach((body: any) => {
          if (
              body[0].split(',')[0] === t('averageProcessingTime')
          ) {
            let icon: any;
            icon = ReactDOMServer.renderToString(<LegendBrownIcon className={styles.icon} />);
            return (innerHtml +=
                '<div class="' +
                elementTableWrap +
                '">' +
                '<div class="' +
                elementTableContainer +
                '">' +
                icon +
                '<div class="' +
                elementTable +
                '">' +
                body[0].split(',')[0] +
                '</div>' +
                '</div>' +
                '<div class="' +
                wrapCount +
                '">' +
                body[0].split(':')[1] +
                '</div>' +
                '</div>');
          }
        });
      }
      innerHtml += '</div>';
      const tableRoot = tooltipEl.querySelector('div');
      tableRoot.innerHTML = innerHtml;
    }
    const position = context.chart.canvas.getBoundingClientRect();
    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    const { offsetLeft: positionX, offsetTop: positionY } = context.chart.canvas;
    const handleTooltipPosition = (caret: any) => {
      if (window.innerWidth > 1400) {
        const edgeDistance = window.innerWidth - (caret * 1.45 + position.left);
        if (edgeDistance <= context.tooltip.width) {
          return caret - context.tooltip.width - width / 3;
        }
        return position.left + tooltipModel.caretX;
      } else if (window.innerWidth <= 1400 && window.innerWidth >= 1350) {
        const edgeDistance = window.innerWidth - (caret * 1.5 + position.left);
        if (edgeDistance <= context.tooltip.width) {
          return caret - context.tooltip.width - width / 8;
        }
        return position.left + tooltipModel.caretX;
      } else if (window.innerWidth <= 1349) {
        const edgeDistance = window.innerWidth - (caret * 1.6 + position.left);
        if (edgeDistance <= context.tooltip.width) {
          return caret - context.tooltip.width;
        }
        return position.left + tooltipModel.caretX;
      }
    };
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = handleTooltipPosition(tooltipModel.caretX) + 'px';
    tooltipEl.style.top = isRA ? context.chart.chartArea.top + position.top + 250 + 'px' : context.chart.chartArea.top + position.top + positionX + 'px';
    tooltipEl.style.zIndex = 100000;
  };

  useEffect(() => {
    if (ref.current != null && !chart) {
      Chart.register([
        LineController,
        LineElement,
        PointElement,
        LinearScale,
        CategoryScale,
        BarController,
        BarElement,
        Tooltip,
        TimeScale,
        dataLabels,
      ]);

      const chartInstance: Chart = new Chart(ref.current, {
        type: 'bar',
        data: dataRequest,
        options: {
          interaction: {
            intersect: false,
            mode: 'index',
          },
          responsive: true,
          plugins: {
            datalabels: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: drawTooltip.bind(this),
            },
            legend: {
              labels: {
                font: {
                  size: handleFontSize(width),
                },
                color: colorDark,
              },
              position: 'top',
            },
          },
          scales: {
            x: {
              ticks: {
                font: {
                  size: handleFontSize(width),
                },
                color: colorDark,
              },
            },
            y: {
              stacked: true,
              // type: 'linear',
              position: 'left',
              ticks: {
                stepSize: 50,
                font: {
                  size: handleFontSize(width),
                },
                color: colorDark,
              },
            },
            y2: {
              position: 'right',
              reverse: false,
              min: 0,
              ticks: {
                stepSize: 1,

                font: {
                  size: handleFontSize(width),
                },
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      });
      setChart(chartInstance);
    }
  }, [ref]);

  useEffect(() => {
    if (chart) {
      chart.data = dataRequest;
      chart.update();
    }
  }, [dataRequest, chart]);

  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);

  const showTooltip = () => {
    setIsOpenTooltip(true);
  };

  return (
    <div className={styles.requestChart}>
      <div className={styles.wrapChart}>
        <div className={styles.titleLeft}>{t('numberOf')} {isRA ? 'РА' : t("requests")}, {t('piece')}</div>
        <canvas className={styles.chart} ref={ref} onClick={showTooltip} />
        <div className={styles.titleRight}>{t('averageProcessingTimeH')}</div>
      </div>
      <div className={styles.legend}>
        <div className={styles.container}>
          <div className={styles.color} style={{ backgroundColor: 'var(--color-bright-2)' }} />
          <div className={styles.text}>{t('openAtBeginningOfPeriod')}</div>
          <div className={styles.color} style={{ backgroundColor: 'var(--color-bright-3)' }} />
          <div className={styles.text}>{t('received')} {isRA ? 'РА' : t("requests")}</div>
          <div className={styles.color} style={{ backgroundColor: 'var(--color-bright-5)' }} />
          <div className={styles.text}>{t('finishedOnTime')}</div>
          <div className={styles.color} style={{ backgroundColor: 'var(--color-bright' }} />
          <div className={styles.text}>{t('finishedOutOfTime')}</div>
        </div>
        <div className={styles.containerLineTwo}>
          <div className={styles.color} style={{ backgroundColor: 'var(--color-secondary)' }} />
          <div className={styles.text}>{t('notFinishedProcessedOnTime')}</div>
          <div className={styles.color} style={{ backgroundColor: 'var(--color-bright-10)' }} />
          <div className={styles.text}>{t('incompleteOutOfTime')}</div>
          {
            !isRA &&
            <>
              <LegendRedIcon className={styles.icon} />
              <div className={styles.text}>{t('averageProcessingTime')} AOG</div>
            </>
          }
        </div>
        <div className={styles.containerLineThree}>
          {
            !isRA &&
            <>
              <LegendYellowIcon className={styles.icon} />
              <div className={styles.text}>{t('averageProcessingTime')} Critical</div>
              <LegendBlueIcon className={styles.icon} />
              <div className={styles.text}>{t('averageProcessingTime')} Routine</div>
              <LegendBrownIcon className={styles.icon} />
              <div className={styles.text}>{t('averageProcessingTime')} Work Stoppage</div>
            </>
          }
        </div>
      </div>
    </div>
  );
};
export default RequestsChart;
