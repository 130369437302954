import { FormInstance } from 'antd';
import _ from 'lodash';

import notification from 'components/messages/notification';

import { clearIfSelectEmptyValue } from 'components/controls/SelectControl/SelectControl';
import { tryConvertToMoment, tryDateStringify } from 'utils/DateUtils';
import { isSystemProperty } from 'utils/FormatUtils';
import { isSimpleObjectOrArray } from 'utils/MiscUtils';

import { ObjectData, ObjectParams, JsObject } from 'interfaces/Object';
import ServerAPI from 'integration/ServerAPI';
import i18n from 'i18n';

// fromForm: true - formValues => objectData
// fromForm: falsy - objectData => formValues
export const normalizeFormData = (data?: any, fromForm?: boolean): any => {
  if (!isSimpleObjectOrArray(data)) return data;

  const newData: any = Array.isArray(data) ? [] : {};

  for (const k in data) {
    if (isSystemProperty(k)) continue;

    let val = data[k];
    val = fromForm ? tryDateStringify(val) : tryConvertToMoment(val);
    val = clearIfSelectEmptyValue(val);
    val = normalizeFormData(val, fromForm);
    newData[k] = val;
  }

  return newData;
};

// objectData => formValues and set to form
export const setFormData = (form?: FormInstance, objectData?: ObjectData): void => {
  const formValues: JsObject | undefined = normalizeFormData(objectData?.params);
  if (form && formValues) form.setFieldsValue(formValues);
};

// build objectData by form values and maybe redux object details (editorDetails, modalDetails)
export const buildObjectData = (form?: FormInstance, details: ObjectData = {}): ObjectData => {
  const formValues: ObjectParams = form?.getFieldsValue(true) || {};

  const id: string | undefined = details.id || formValues.ID;
  const classType: string | undefined = details.classType || formValues.ClassName;
  const isDirty: boolean = details.isDirty || false;

  return {
    id,
    classType,
    isDirty,
    params: normalizeFormData({ ...details.params, ...formValues }, true),
  };
};

export const getNewComponentsProps = async (controller: string, component: { propName: string, value: any, object: { classType: string, id: string } }): Promise<{
  propName: string;
  readonly?: boolean;
  required?: boolean;
}[]> => (await ServerAPI.axiosFormDataPost(controller, component)).data;

export interface ValidateOptions {
  notify?: boolean;
  width?: number;
}

const getValidateNotifText = (propName: string): any => {
  let notification = ''
  if (propName === 'Executor') {
    notification = i18n.t('selectTheExecutor')
  } else if (propName === 'Files') {
    notification = i18n.t('attachFiles')
  } else {
    notification = i18n.t('fillTheRequiredFields');
  }
  return notification
}


export const handleValidateError = (
  error?: any,
  form?: FormInstance,
  options?: ValidateOptions
): void => {
  const errorPropName = error?.errorFields?.[0]?.name?.[0];

  // Валидация предыдущих ошибок
  if (errorPropName && (errorPropName === 'Executor' || errorPropName === 'Files' || error?.errorFields?.[0]?.errors?.[0] === i18n.t('requiredField'))) {
    form?.scrollToField(errorPropName, {
      behavior: 'smooth',
      block: 'start',
      inline: 'end',
    });

    const notify = options?.notify || errorPropName === 'Executor';

    if (notify) {
      notification.error({
        text: getValidateNotifText(errorPropName),
        screenWidth: options?.width,
      });
    }
  }

  // Валидация ошибок в полях, для которых приходит регулярное выражение с бэкенда.
  let otherErrors: string[] = [];
  error?.errorFields?.forEach((err: any) => {
    let otherError = err?.errors?.find((e: any) => e !== i18n.t("requiredField"));
    if (!!otherError) otherErrors.push(otherError);
  });
  if (otherErrors.length > 0) {
    otherErrors.forEach(error => {
      notification.error({
        text: error,
        screenWidth: options?.width,
      });
    })
  }
};

export const validateFields = async (
  form?: FormInstance,
  options?: ValidateOptions
): Promise<boolean> => {
  if (!form) return true;

  try {
    await form.validateFields();

    return true;
  } catch (error: any) {
    handleValidateError(error, form, options);

    return false;
  }
};

export const formControlProps = ['id', 'value', 'onChange'] as const;

export const getFormProps = (props: JsObject) => _.pick(props, formControlProps);

const FormHandler = {
  normalizeFormData,
  setFormData,
  buildObjectData,
  handleValidateError,
  validateFields,
  formControlProps,
  getFormProps,
};

export default FormHandler;
