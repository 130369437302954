import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg width='1.2rem' height='1.2rem' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_166_598)'>
      <path d='M4 21C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4ZM8 10H5V19H8V10ZM19 10H10V19H19V10ZM19 5H5V8H19V5Z' />
    </g>
    <defs>
      <clipPath id='clip0_166_598'>
        <rect width='24' height='24' />
      </clipPath>
    </defs>
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
