import { ObjectParams } from './Object';

export const IS_CHANGED_KEY = '.editorIsChanged';

export const IS_GENERAL_CHANGED_KEY = '.editorGeneralIsChanged';

export interface FormObjectData extends ObjectParams {
  [IS_CHANGED_KEY]?: boolean;
  [IS_GENERAL_CHANGED_KEY]?: boolean;
}
