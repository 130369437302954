import { Action, ActionType } from '../actionTypes';

const initialState: IHiddenEntities = {
  hiddenTables: [],
};

export interface IHiddenEntities {
  hiddenTables: string[];
}

const hiddenEntities = (state = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.ADD_HIDDEN_TABLES: {
      const newState = JSON.parse(JSON.stringify(state));
      action.payload.forEach((newPropName: string) => {
        if (!newState.hiddenTables.find((propName: string) => propName === newPropName)) {
          newState.hiddenTables.push(newPropName);
        }
      });
      return newState;
    }

    case ActionType.RESET_HIDDEN_TABLES: {
        return initialState
    }

    default:
      return state;
  }
};

export default hiddenEntities;
