import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { isBoolean } from 'lodash';

import {
  FiltersConfig,
  updateFiltersConfig,
} from 'pages/entityList/entityListFilters/EntityListFilters';

import {
  useKnowledgeBase,
  useTableViewHandleChange,
  useTableViewLoad,
  useTableViewRowTools,
  useTableViewSettings,
  useTableViewUpdateColumns,
  nullSort,
} from 'components/TableView/hooks';

import { useObjectDetails } from 'hooks';

import { TransitionType } from 'interfaces/CardTransition';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';
import { TableType, ISort, IScroll } from 'interfaces/Table';
import { getFilters } from 'store/selectors/filters';
import { ModalType } from 'store/reducers/modal';
import { generateTableId } from 'utils/MiscUtils';
import { isNewID, isNewObjectData } from 'utils/ObjectUtils';
import { getTableHeightForShortView, tryParsePercentage } from '../utils/size';

export interface TableComponentParams {
  fillCriteriaFrom: string;
  conditionId: string;
  className: string;
  inboxViewName: string;
  searchName: string;
  transitionType?: TransitionType;
  modalType?: ModalType;
  rowTools?: any[];
  massLoadTabLayout?: boolean;
  showFiltersTools?: boolean;
  tableType?: TableType;
  addGuidItemsTable?: TableComponentParams;
}

export type UseProcessesArgsType = {
  componentData: any;
  readonly?: boolean;
};

export type UseProcessesReturnsType = {
  width: number;
  filtersConfiguration: any;
  filtersForm: FormInstance;
  factory: any;
  doSearch: any;
  openedInbox: any;
  count: any;
  setIsScrollEnd: any;
  handleTableChange: any;
  items: any;
  loading: any;
  filters: any;
  height: number;
  isScrollEnd: any;
  sort: ISort;
  result: any;
  pdf: any;
  tableConfiguration: any;
  showFiltersToolsState: any;
  columns: any;
  closeFilePreviewModal: any;
  heightTable: any;
  setHeightTable: any;
  screenResolution: IScroll;
};

export type UseProcessesType = (args: UseProcessesArgsType) => UseProcessesReturnsType;

export const useTableViewProcesses: UseProcessesType = ({ componentData, readonly }) => {
  const {
    className,
    isCardTable,
    objectID,
    inboxName,
    controllerName,
    propsTableId,
    inboxGroups,
    groupID,
    setIsReset,
    setIsSearch,
    showFiltersTools,
    isSearch,
    isReset,
  } = componentData;

  const dispatch = useDispatch();
  const [width, height] = useWindowSize();
  const [filtersForm] = useForm();
  const inboxNameRef = useRef<string>(inboxName);
  const { inModal, details } = useObjectDetails();
  const filtersConfigLoadingRef = useRef<boolean>(false);
  const tableIdRef = useRef<string>(
    propsTableId || generateTableId(isCardTable, inboxName, className)
  );

  const filters = useSelector((state: AppState) => getFilters(state, tableIdRef.current));
  const isExternalUser: boolean | null = useSelector((state: AppState) => {
    return state?.typeUser?.userData?.user?.params?.External;
  });

  const [openedInbox, setOpenedInbox] = useState<string>('');
  const [isScrollEnd, setIsScrollEnd] = useState<boolean>(false);
  const [result, setResult] = useState<any>(null);
  const [pdf, setPdf] = useState<any>(null);
  const [refresh, setRefresh] = useState<number>(0); // temporary legacy compatibility
  const [heightTable, setHeightTable] = useState<any>();
  const [showFiltersToolsState, setShowFiltersToolsState] = useState<boolean>(
    isBoolean(showFiltersTools) ? showFiltersTools : false
  );
  const setShowFiltersTools = (value: boolean) =>
    setShowFiltersToolsState(isBoolean(showFiltersTools) ? showFiltersTools : value);

  const refreshCount = componentData.refreshCount || refresh;
  const refreshSelf = () =>
    componentData.refreshSelf ? componentData.refreshSelf() : setRefresh((num: number) => num + 1);

  const closeFilePreviewModal = () => {
    setResult(null);
    setPdf(null);
  };

  const { getColumnsWithRowTools, tableViewDeleteRow } = useTableViewRowTools({
    componentData,
    setResult,
    setPdf,
  });

  const { isKnowledgeBase, knowledgeBaseDelete } = useKnowledgeBase({
    componentData,
    refreshSelf,
  });

  const readyToLoad: boolean = Boolean(
    ((isCardTable && details.id === objectID && !isNewID(objectID)) || inModal || inboxName) &&
      !controllerName &&
      className !== 'WaterfallChart' &&
      isExternalUser !== null &&
      isKnowledgeBase !== null
  );

  const {
    resetSettings,
    items,
    offset,
    limit,
    count,
    columns,
    tableConfiguration,
    filtersConfiguration,
    factory,
    setTableConfiguration,
    setFactory,
    setColumns,
    setFiltersConfiguration,
    setCount,
    setItems,
    setOffset,
  } = useTableViewSettings({
    dispatch,
  });

  const { updateColumns } = useTableViewUpdateColumns({
    componentData,
    knowledgeBaseDelete,
    tableConfiguration,
    setColumns,
    width,
    filtersForm,
    tableIdRef,
    tableViewDeleteRow
  });

  const { handleTableChange, sort, resetSort } = useTableViewHandleChange();

  const { loadTableConfigs, doSearch, loading } = useTableViewLoad({
    componentData,
    updateColumns,
    isKnowledgeBase,
    isExternalUser,
    filtersConfiguration,
    tableConfiguration,
    offset,
    limit,
    items,
    sort,
    setIsScrollEnd,
    setOffset,
    setFiltersConfiguration,
    setFactory,
    dispatch,
    setTableConfiguration,
    setCount,
    setItems,
    setShowFiltersTools,
    readyToLoad,
    filtersConfigLoadingRef,
    readonly
  });

  const screenResolution: IScroll = {
    y:
      componentData.isShortView || componentData.simpleTable
        ? getTableHeightForShortView(width)
        : heightTable,
    x: tryParsePercentage(tableConfiguration?.params?.tableWidth),
  };

  // when inbox changes - new register table
  // remove previous filters and generate new tableId
  // wait for filtersConfiguration to load - needed for register tables with same className
  if (inboxNameRef.current !== inboxName) {
    dispatch({
      type: ActionType.CLEAR_SELECTED_ROWS,
      payload: { name: tableIdRef.current },
    });

    dispatch({
      type: ActionType.REMOVE_FILTERS,
      payload: {
        filtersId: tableIdRef.current,
      },
    });
    filtersConfigLoadingRef.current = true;
    inboxNameRef.current = inboxName;
    tableIdRef.current = generateTableId(isCardTable, inboxName, className);
    resetSort();
  }

  // clear filters on unmount
  useEffect(
    () => () => {
      dispatch({
        type: ActionType.CLEAR_SELECTED_ROWS,
        payload: { name: tableIdRef.current },
      });

      dispatch({
        type: ActionType.REMOVE_FILTERS,
        payload: {
          filtersId: tableIdRef.current,
        },
      });
    },
    []
  );

  useEffect(() => {
    if (readyToLoad) {
      if(isCardTable){
        loadTableConfigs(objectID);
      }else{
        loadTableConfigs();
      }
    }
  }, [readyToLoad, inboxName, className]);

  useEffect(() => {
    const inbox: any[] = inboxGroups.filter((inboxGroup: any) => inboxGroup.isOpen);
    if (inbox.length === 1) setOpenedInbox(inbox[0].inboxGroup.label);
  }, [inboxGroups]);

  useEffect(() => {
    resetSettings();
  }, [inboxName, controllerName]);

  useEffect(() => {
    if ((groupID && filters && isSearch) || isReset) {
      const newSearchConfig: FiltersConfig = updateFiltersConfig(filters, filtersConfiguration);
      doSearch(newSearchConfig);
      setIsReset(false);
      setIsSearch(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!isSearch && !isReset) return;

    dispatch({
      type: ActionType.UPDATE_FILTERS,
      payload: {
        filtersId: tableIdRef.current,
        filters: isSearch ? filtersForm.getFieldsValue() : {},
        reset: isReset && true,
      },
    });
  }, [isSearch, isReset]);

  useEffect(() => {
    if (filtersConfiguration && (refreshCount || sort !== nullSort))
      doSearch(updateFiltersConfig(filters, filtersConfiguration));
  }, [refreshCount, sort]);

  useEffect(() => {
    if (isScrollEnd && filtersConfiguration) {
      if (items.length < count.count) {
        doSearch(filtersConfiguration, true);
      }
    }
  }, [isScrollEnd]);

  useEffect(() => {
    const res: HTMLDivElement | null = document.querySelector('#wrapTable');
    if (res) {
      setHeightTable(res.clientHeight - 50);
    }
  }, [height, items]);

  return {
    width,
    filtersConfiguration,
    filtersForm,
    factory,
    doSearch,
    openedInbox,
    count,
    setIsScrollEnd,
    handleTableChange,
    items,
    loading,
    filters,
    height,
    isScrollEnd,
    sort,
    result,
    pdf,
    tableConfiguration,
    showFiltersToolsState,
    columns: getColumnsWithRowTools(columns),
    closeFilePreviewModal,
    heightTable,
    setHeightTable,
    tableIdRef,
    filtersConfigLoadingRef,
    screenResolution,
  };
};
