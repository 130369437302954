import { JsObject } from 'interfaces/Object';
import { isString } from 'lodash';

export const getKeyValue =
  <T extends object, U extends keyof T>(obj: T) =>
  (key: U) =>
    obj[key];

export enum OS {
  WINDOWS = 'windows',
  MacOS = 'MacOS',
  UNIX = 'UNIX',
  Linux = 'Linux',
  UNKNOW = 'unkown',
}

export const notNullString = (str: string | undefined | null, defaultValue: string = '') => {
  if (typeof str === 'undefined' || str === null) {
    return defaultValue;
  }
  return isString(str) ? str.trim() : str;
};

export const isBlankString = (str: string | undefined | null) => {
  if (!isString(str)) return false;

  return notNullString(str).length === 0;
};

export const isZeroValue = (val: string | number) => {
    return val === '0' || val === 0 || val === '0:00' || val === '';
}

export const getOS = () => {
  const navigator: Navigator = window.navigator;
  const userAgent: string = navigator.userAgent;

  if (userAgent.indexOf('Win') !== -1) return OS.WINDOWS;

  if (userAgent.indexOf('Mac') !== -1) return OS.MacOS;

  if (userAgent.indexOf('X11') !== -1) return OS.UNIX;

  if (userAgent.indexOf('Linux') !== -1) return OS.Linux;

  return OS.UNKNOW;
};

export const cleanUpHtml = (str: string): string => {
  const div = document.createElement('div');
  div.innerHTML = notNullString(str);
  return div.textContent || div.innerText || '';
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const jsonParseSafe = (json: any) => {
  if (!isString(json)) return null;

  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
};

export const isBlob = (obj: any): obj is Blob => {
  return obj instanceof Blob;
};

export const isObject = (obj: any): boolean => {
  return !!obj && typeof obj === 'object';
};

export const isSimpleObject = (obj: any): obj is JsObject => {
  return isObject(obj) && obj.constructor === Object;
};

export const isSimpleObjectOrArray = (obj: any): obj is JsObject | any[] => {
  return isSimpleObject(obj) || Array.isArray(obj);
};

// process values in object own properties if they pass shouldProcessValue check
// if initialObj passed - change value to initialObj value
// if not - delete value field
export const deleteOrResetValues = (
  obj?: any,
  shouldProcessValue?: (value: any) => boolean,
  initialObj?: any
): any => {
  if (!isSimpleObject(obj) || !shouldProcessValue) return obj;

  const newObj = { ...obj };

  if (initialObj) {
    for (const key in newObj) {
      if (shouldProcessValue(newObj[key])) newObj[key] = initialObj[key];
    }
  } else {
    for (const key in newObj) {
      if (shouldProcessValue(newObj[key])) delete newObj[key];
    }
  }

  return newObj;
};

// process all undefined values in object own properties
// if initialObj passed - change undefined value to initialObj value
// if not - delete undefined value field
export const processUndefined = (obj?: any, initialObj?: any): any =>
  deleteOrResetValues(obj, (value) => value === undefined, initialObj);

// process all falsy values in object own properties
// if initialObj passed - change falsy value to initialObj value
// if not - delete falsy value field
export const processFalsy = (obj?: any, initialObj?: any): any =>
  deleteOrResetValues(obj, (value) => !value, initialObj);

export const generateId = (prefix?: string, suffix?: string): string => {
  let id: string = `${('' + Date.now()).slice(-6)}${('' + Math.random()).slice(-6)}`;
  if (prefix) id = prefix + '_' + id;
  if (suffix) id = id + '_' + suffix;
  return id;
};

export const generateTableId = (
  isCardTable?: boolean,
  inboxName?: string,
  className?: string
): string => {
  const cardPrefix: string = `cardtable${className ? '_' + className : ''}`;
  return isCardTable ? generateId(cardPrefix) : `regtable_${inboxName}`;
};

export const ensureArray = (
  value: any,
  {
    flat,
    noFalsy,
  }: {
    flat?: boolean;
    noFalsy?: boolean;
  } = {}
): any[] => {
  if (!value) return [];

  let arr: any[] = Array.isArray(value) ? value : [value];

  if (flat) arr = arr.flat(Infinity);
  if (noFalsy) arr = arr.filter((val: any) => val);

  return arr;
};

// modifies obj - adds nested params to nested object
// obj = { a: 5, b: { c: 4 } }, paramsToAdd = { a: 7, d: 2, b: { f: 4 } }
// => { a: 7, d: 2, b: { c: 4, f: 4 } }
// returns true if modified
export const addToObjectRecursive = (obj: any, paramsToAdd: JsObject): boolean => {
  if (!isObject(obj)) return false;

  let modified: boolean = false;

  Object.keys(paramsToAdd).forEach((paramKey: string) => {
    const paramValue = paramsToAdd[paramKey];
    const objValue = obj[paramKey];

    let paramModified: boolean = false;

    if (isObject(objValue) && isSimpleObject(paramValue)) {
      paramModified = addToObjectRecursive(objValue, paramValue);
    } else if (objValue !== paramValue) {
      obj[paramKey] = paramValue;
      paramModified = true;
    }

    if (paramModified) modified = true;
  });

  return modified;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const isEmptyValue = (value?: any): boolean => value === null || value === undefined;

export const isEmptyObject = (obj: JsObject) => !Object.keys(obj).length;

export const isFilledObject = (obj: JsObject) => !isEmptyObject(obj);

export const isNaturalNumber = (value: any): boolean => {
  return Number.isInteger(value) && value > 0;
};

const MiscUtils = {
  notNullString,
  isBlankString,
  getOS,
  cleanUpHtml,
  randomIntFromInterval,
  jsonParseSafe,
  isBlob,
  isObject,
  isSimpleObject,
  isSimpleObjectOrArray,
  deleteOrResetValues,
  processUndefined,
  processFalsy,
  generateId,
  ensureArray,
  addToObjectRecursive,
  delay,
  isEmptyValue,
  isEmptyObject,
  isFilledObject,
  isNaturalNumber,
};

export default MiscUtils;
