export enum DataTypesEnum {
  HTML_TEXT = 'htmlText',
  CHOICE_INFO = 'choiceInfo',
}

export interface ILandingPageData {
  id: string;
  link: string;
  label: string;
  columns: {
    displayType: string;
    title: string;
    content: {
      dataType: DataTypesEnum;
      dataSource?: string;
      viewConfig?: {
        label: string;
        key: string;
      }[];
      data: string;
    }[];
  }[];
}
