import React from 'react';
import moment from 'moment';
import { CheckCircleOutlined, EllipsisOutlined, StopOutlined } from '@ant-design/icons';

import MiscUtils from './MiscUtils';

import { TableRecord } from 'interfaces/Table';
import { JsObject, ObjectData, ObjectParams } from 'interfaces/Object';

export const isSystemProperty = (propName: string): boolean => propName.startsWith('.');

export const isObjectMetaProperty = (propName: string): boolean =>
  ['id', 'classType', 'isDirty'].includes(propName);

export const dateFormat: string = 'DD.MM.YYYY';

export const dateTimeFormat: string = 'DD.MM.YYYY HH:mm';

export const getClassName = (classNames: string[]): string => {
  if (classNames != null && Array.isArray(classNames)) {
    return classNames.join(' ');
  }
  return '';
};

export interface FormattedFieldData {
  value: any; // отформатированное значение для таблицы
  manualField?: any; // название поля select свободного ввода
  key?: any; // ключ до форматирования, например элемента справочника - в select, choice и тд
  date?: string | null; // строка даты до форматирования - по смыслу как key только для дат
}

export const formatEntity = (value: any, field: any, choiceData?: any, isManualSelect: boolean = false): FormattedFieldData => {
  switch (field.renderer || field.templateName) {
    // Most likely deprecated
    case 'date': {
      if (value === null || value === undefined) return { value: '-', date: value };
      return { value: moment(value).format(dateTimeFormat), date: value };
    }
    case 'datePicker': {
      if (value === null || value === undefined) return { value: '-', date: null };
      return { value: moment(value).format(dateFormat), date: value };
    }
    case 'select':
    case 'dynamicSelect':
    case 'choice': {
      if (isManualSelect) return {manualField: field.fieldName, value: MiscUtils.notNullString(value)}
      const dict: any = choiceData[field.choiceName]?.choiceItems;
      const dictKey: string = `_${value}`;
      if (!dict?.hasOwnProperty(dictKey)) break;
      return { value: dict[dictKey].label, key: value };
    }
    case 'multiselect': {
      const dict: any = choiceData[field.choiceName]?.choiceItems;
      const values =
        value?.map((val: any) => {
          const dictKey: string = `_${field.choiceName === 'Users' ? val.toLowerCase() : val}`;
          if (dict?.hasOwnProperty(dictKey)) {
            return dict[dictKey].label;
          }
          return val;
        }) || [];
      return { value: values.join(', '), key: value };
    }
    case 'checkbox': {
      if (field.fieldName === 'isKwonledgeBase') return { value, key: value };
      return { value: value ? 'Да' : 'Нет', key: value };
    }
    case 'choiceIcon': {
      const dict: any = choiceData[field.choiceName]?.choiceItems;
      if (dict?.hasOwnProperty(`_${value}`)) {
        const label = dict[`_${value}`].label;
        const index = dict[`_${value}`].index;
        return { value: { label, index }, key: value };
      }
      return { value: { label: '', index: -1 }, key: value };
    }
    case 'dateTimePicker': {
      if (value === null || value === undefined) return { value: '-', date: null };
      return { value: moment(value).format(dateTimeFormat), date: value };
    }
  }
  return { value: MiscUtils.notNullString(value) };
};

export const formatFieldForTable = (
  value: any,
  fieldName?: string,
  config?: any,
  choiceData?: any
): FormattedFieldData => {
  if (fieldName != null && config != null && config.fields != null) {
    let field: any[] = config.fields.filter((field: any) => field.fieldName === fieldName);
    let isManualSelect = false;
    if (field.length === 0) {
      field = config.fields.filter((field: any) => JSON.parse(field.jsonConfig)?.cardDisplay?.select?.secondProperty === fieldName);
      field.length === 1 ? isManualSelect = true : isManualSelect = false;
    }
    if (field.length === 1) {
      return formatEntity(value, field[0], choiceData, isManualSelect);
    }
  }
  return { value };
};

export const formatEntityForTable = (
  value: any,
  fieldName?: string,
  config?: any,
  choiceData?: any
) => {
  return formatFieldForTable(value, fieldName, config, choiceData).value;
};

export const createTableRecord = (
  objectData: ObjectData,
  tableConfiguration: any,
  choiceLists: any,
  systemProps?: JsObject
): TableRecord => {
  const record: TableRecord = {
    id: objectData.id,
    classType: objectData.classType,
    isDirty: objectData.isDirty,
    ...systemProps,
  };

  const objectParams: ObjectParams = objectData.params || {};

  Object.keys(objectParams).forEach((propName: string) => {
    const propValue = objectParams[propName];

    const formattedData = formatFieldForTable(propValue, propName, tableConfiguration, choiceLists);

    Object.keys(formattedData).forEach((dataKey: string) => {
      if (dataKey === 'value') record[propName] = formattedData.value;
      else record[`.${dataKey}.${propName}`] = formattedData[dataKey as keyof FormattedFieldData];
      if (dataKey === 'manualField') record[formattedData.manualField] = formattedData.value;
    });
  });

  return record;
};

export const getObjectDataByTableRecord = (record: TableRecord): ObjectData => {
  const objectParams: ObjectParams = {};

  const keyPrefixes = ['.key.', '.date.'];
  for (const [key, value] of Object.entries(record)) {
    if (isSystemProperty(key) || isObjectMetaProperty(key)) continue;

    objectParams[key] = value;
    keyPrefixes.forEach((keyPrefix: string) => {
      if (record.hasOwnProperty(keyPrefix + key)) {
        objectParams[key] = record[keyPrefix + key];
      }
    });
  }

  return {
    id: record.id,
    classType: record.classType,
    isDirty: record.isDirty,
    params: objectParams,
  };
};

export const getDiffDays = (values: any[]) => {
  const start: moment.Moment = moment(values[0]);
  const end: moment.Moment = moment(values[1]);
  const diffDays: number = end.diff(start, 'days');
  return diffDays;
};

export const formatDatesRagne = (dates: any[], days: number) => {
  const start: moment.Moment = moment(dates[0]);
  const end: moment.Moment = moment(dates[1]);
  if (days > 31) {
    return `${end.get('quarter')}кв. ${end.format('YYYY')}`;
  } else if (days >= 28 && days <= 31) {
    return start.format('MMMM YYYY');
  } else if (days === 7) {
    if (start.get('month') !== end.get('month')) {
      return `${start.format('DD.MM')}-${end.format('DD.MM.YYYY')}`;
    }
    return `${start.format('DD')}-${end.format('DD.MM.YYYY')}`;
  }
  return `${start.format('DD.MM.YYYY')}`;
};

export const formatFilenameFromResponse = (disposition: any) => {
  let fileName = 'default';
  if (disposition && disposition.indexOf('filename') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }
  return fileName;
};

export const formatNumberNN = (value: string) => {
  if (MiscUtils.isBlankString(value)) {
    return '';
  }
  const numValue: number = Number(value.replaceAll(',', '.'));

  if (numValue === 0 || isNaN(numValue)) {
    return '';
  }
  return `${numValue}`;
};

export const convertMinsToHrsMins = (mins: number) => {
  let h: any = Math.floor(mins / 60);
  let m: any = mins % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return `${h}:${m}`;
};

export const formatSecondsToTime = (
  seconds: number,
  bigHours: boolean = false,
  hoursLimit: boolean = false
) => {
  const pad = (num: number) => ('0' + num).slice(-2);

  const hhmmss = (secs: number) => {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${
      bigHours ? (hours < 10 ? '0' + hours : hoursLimit && hours > 9999 ? 9999 : hours) : pad(hours)
    }:${pad(minutes)}:${pad(secs)}`;
  };

  return hhmmss(seconds);
};

export const formatMinutesToTime = (
  minutes: number,
  bigHours: boolean = false,
  hoursLimit: boolean = false
) => {
  const seconds = minutes * 60;
  return formatSecondsToTime(seconds, bigHours, hoursLimit);
};

const getTimeZone = () => {
    let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + Math.floor(o / 60);
}

const FormatUtils = {
  isSystemProperty,
  isObjectMetaProperty,
  dateFormat,
  dateTimeFormat,
  getClassName,
  formatEntity,
  formatFieldForTable,
  formatEntityForTable,
  createTableRecord,
  getObjectDataByTableRecord,
  getDiffDays,
  formatDatesRagne,
  formatFilenameFromResponse,
  formatNumberNN,
  convertMinsToHrsMins,
  formatSecondsToTime,
  formatMinutesToTime,
  getTimeZone
};

export default FormatUtils;
