import React, { useContext, useEffect, useRef, useState } from 'react';
import Title from 'components/layout/content/title/Title';
import styles from './reports.module.css';
import stylesSelect from '../../components/controls/SelectControl/SelectControl.module.css';
import stylesDatePicker from '../../components/controls/InputDate/InputDate.module.css';
import { Button, Collapse, DatePicker, Form, Select, Spin } from 'antd';
import entityEditorDetalisStyle from '../entityEditor/Tabs/EntityEditorDetails.module.css';
import AccordionIcon from '../../components/icons/AccordionIcon';
import IntegralIndicators from './IntegralIndicators';
import MultiPieChart from './MultiPieChart';
import {
  valueForPeriod,
  valuesForTypeRA,
  valuesForTypeReport,
  valuesForTypeRequests,
} from './utils';
import axios from 'axios';
import FormatUtils from '../../utils/FormatUtils';
import { widthForMaxSize, widthForMiddleSize, widthForSmallSize } from '../entityList/utils';
import { handleIcon } from '../entityList/iconsForStatus';
import { getPopupContainer } from 'components/controls/SelectControl/SelectControl';
import stylesButtons from '../entityEditor/Buttons/EntityEditorButtons.module.css';
import RequestsChart from './RequestsChart';
import { AxiosResponseExt } from '../../interfaces/AxiosResponseExt';
import TableRequestsForThePeriod from './TableRequestsForThePeriod';
import DropdownIcon from '../../components/icons/DropdownIcon';
import { baseUrl } from '../../integration/axiosMiddleware';
import notification from 'components/messages/notification';
import MiscUtils from 'utils/MiscUtils';
import FloatingChart from './FloatingChart';
import { ItemDataTooltip, ItemDataTooltipForPie } from 'utils/types';
import { ColumnsType } from 'antd/lib/table';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
import GlobalLoaderContext from '../../components/layout/loader/GlobalLoaderContext';
import { handleWidthForTableCell } from 'components/TableView/utils/size';
import { saveAs } from 'file-saver';
import TableGrid from 'components/TableGrid';
import { useKeycloak } from '@react-keycloak/web';
import { TDocumentDefinitions, ContentImage, Content } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/lib/select';
import CacheStorage from 'utils/CacheStorage';

interface ReportProps {
  width: number;
  isRA?: boolean;
}

const Reports: React.FC<ReportProps> = (props) => {
  const style = getComputedStyle(document.body);
  const colorBright = style.getPropertyValue('--color-bright');
  const colorBright2 = style.getPropertyValue('--color-bright-2');
  const colorBright5 = style.getPropertyValue('--color-bright-5');
  const colorBright6 = style.getPropertyValue('--color-bright-6');
  const colorBright7 = style.getPropertyValue('--color-bright-7');
  const colorBright10 = style.getPropertyValue('--color-bright-10');
  const colorSecondary = style.getPropertyValue('--color-secondary');

  const { Panel } = Collapse;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { keycloak } = useKeycloak();
  const { width } = props;
  const [nameColumn, setNameColumn] = useState<any>('');
  const [orderDirection, setOrderDirection] = useState<any>(null);
  const [reportType, setReportType] = useState<string>('openRequests');
  const [period, setPeriod] = useState<string>('');
  const [organizationFilter, setOrganizationFilter] = useState<string>('');
  const [date, setDate] = useState<any>();
  const [requestType, setRequestType] = useState<string>('engineeringTechnical');
  const [dataIntegralIndicatorsForMultiPie, setDataIntegralIndicatorsForMultiPie] = useState<
    ItemDataTooltipForPie[]
  >([]);
  const [dataIntegralIndicatorsForPeriod, setDataIntegralIndicatorsForPeriod] = useState<
    ItemDataTooltip[]
  >([]);
  const [items, setItems] = useState<any>([]);
  const [columns, setColumns] = useState<ColumnsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const [exportPdfEnabled, setExportPdfEnabled] = useState<boolean>(false);
  const ref: any = useRef(null);
  const [dataFloatingChart, setDataFloatingChart] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [isReportTypeOpen, setIsReportTypeOpen] = useState(false);
  const [isRequestTypeOpen, setIsRequestTypeOpen] = useState(false);
  const [isPeriodOpen, setIsPeriodOpen] = useState(false);
  const [isOrganizationOpen, setIsOrganizationOpen] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState<DefaultOptionType[]>([]);
  const globalLoader = useContext(GlobalLoaderContext);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const onSelectClick = (selectNumber: number, isOpen?: boolean) => {
    const setStateFunc = (prev: boolean) => (typeof isOpen !== 'undefined' ? isOpen : !prev);
    switch (selectNumber) {
      case 0:
        setIsReportTypeOpen(setStateFunc);
        break;

      case 1:
        setIsRequestTypeOpen(setStateFunc);
        break;

      case 2:
        setIsPeriodOpen(setStateFunc);
        break;

      case 3:
        setIsOrganizationOpen(setStateFunc);
        break;
      default:
        break;
    }
  };

  const dropdownIcon = (
    <DropdownIcon
      onClick={() => {
        if (ref && ref.current && ref.current.focus && ref.current.blur) {
          ref.current.focus();
        }
      }}
      className={stylesSelect.icon}
    />
  );

  const onChangeReportType = (event: any) => {
    setReportType(event);
  };

  const onChangePeriod = (event: any) => {
    setPeriod(event);
    valueForPeriod(t).forEach((item: any) => {
      if (event === item.value) {
        setDate(item.date);
        return;
      }
    });
  };

  const onChangeOrganizationFilter = (event: any) => {
    setOrganizationFilter(event);
  };

  const onChangeDate = (event: any) => {
    setDate(event);
  };

  const getDataTable = (isSorting?: boolean) => {
    setIsTableLoading(true);
    const body: FormData = new FormData();
    body.append('sortColumn', nameColumn);
    body.append('orderDirection', orderDirection);
    axios
      .post(
        `${
          process.env.REACT_APP_TYPE === 'irk'
            ? '/OpenEngineeringTechnicalRequestReport'
            : props.isRA
            ? '/OpenRAReport'
            : 'OpenRequestReport'
        }${isSorting ? '/sort' : ''}`,
        body
      )
      .then((res: AxiosResponseExt) => {
        if (!res.error) {
          const tableColumns: ColumnsType[] = [];
          if (!isSorting) {
            setDataIntegralIndicatorsForMultiPie(res.data.integralIndicators);
          }
          if (res) {
            if (!isSorting) {
              res.data.fields.forEach((field: any) => {
                const newObj: any = {
                  title: field.label,
                  dataIndex: field.fieldName,
                  render: (fieldName: string) => {
                    if (field.renderer === 'choiceIcon') {
                      return (
                        <div className={styles.iconWrapper}>
                          {handleIcon(fieldName)}
                          {` ${fieldName}`}
                        </div>
                      );
                    }
                    return fieldName;
                  },
                };
                if (field.sortable === true) {
                  newObj.sorter = true;
                }
                if (width >= 2560) {
                  handleWidthForTableCell(widthForMaxSize, field, newObj);
                } else if (width <= 2070 && width > 1300) {
                  handleWidthForTableCell(widthForMiddleSize, field, newObj);
                } else if (width <= 1300) {
                  handleWidthForTableCell(widthForSmallSize, field, newObj);
                }
                tableColumns.push(newObj);
              });
            }
            const newItems: any[] = (isSorting ? res.data : res.data.data).reduce(
              (result: any, current: any) => {
                const newItem: any = {
                  classType: current.classType,
                  id: current.id,
                };
                Object.keys(current.params).forEach((key: string) => {
                  newItem[key] = current.params[key];
                });
                result.push(newItem);
                return result;
              },
              []
            );
            setColumns(isSorting ? columns : tableColumns);
            setItems(newItems);
          }
        } else {
          notification.error({
            text: res.error,
            width,
          });
        }
      })
      .finally(() => {
        setLoading(false);
        setIsTableLoading(false);
      });
  };

  useEffect(() => {
    if (reportType !== 'requestsForThePeriod') {
      getDataTable(orderDirection !== null);
    } else {
      CacheStorage.get('OrganizationList').then((listFromCache) => {
        if (!listFromCache) {
          axios.get(`/SPChoice/list?choiceNames=OrganizationList=null`).then((res) => {
            if (res.data) {
              CacheStorage.set('OrganizationList', res.data.OrganizationList);
              const choiceItems = res.data.OrganizationList.choiceItems;
              setOrganizationOptions([
                {
                  label: 'Выбрать значение',
                  value: '',
                },
                ...Object.keys(choiceItems).map((key: string) => ({
                  label: choiceItems[key].label,
                  value: choiceItems[key].index,
                })),
              ]);
            }
          });
        } else {
          axios
            .get(`/SPChoice/list?choiceNames=OrganizationList=${listFromCache.Version}`)
            .then((res) => {
              if (res.data?.OrganizationList) {
                CacheStorage.set('OrganizationList', res.data.OrganizationList);
                const choiceItems = res.data.OrganizationList.choiceItems;
                setOrganizationOptions([
                  {
                    label: 'Выбрать значение',
                    value: '',
                  },
                  ...Object.keys(choiceItems).map((key: string) => ({
                    label: choiceItems[key].label,
                    value: choiceItems[key].index,
                  })),
                ]);
              } else {
                const choiceItems = listFromCache.choiceItems;
                setOrganizationOptions([
                  {
                    label: 'Выбрать значение',
                    value: '',
                  },
                  ...Object.keys(choiceItems).map((key: string) => ({
                    label: choiceItems[key].label,
                    value: choiceItems[key].index,
                  })),
                ]);
              }
            });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, orderDirection, nameColumn]);

  const handleWidthTableForShortView = (width: number) => {
    if (width >= 2560) {
      return {
        y: 1117,
      };
    } else if (width <= 2559 && width >= 1950) {
      return {
        y: 900,
      };
    } else if (width <= 1949 && width >= 1500) {
      return {
        y: 700,
      };
    } else if (width <= 1499 && width >= 1024) {
      return {
        y: 535,
      };
    } else if (width <= 1023 && width >= 200) {
      return {
        y: 420,
      };
    }
  };

  const resetForm = () => {
    setDate('');
    setPeriod('');
    setRequestType('');
    setDataSource([]);
  };

  const onChangeRequestType = (event: any) => {
    setRequestType(event);
  };

  const colorPie =
    process.env.REACT_APP_TYPE === 'irk'
      ? {
          datasets: [
            [colorBright, colorBright6],
            [colorBright10, colorSecondary],
            [colorBright5, colorBright7],
          ],
        }
      : {
          datasets: [
            [colorBright, colorBright6],
            [colorBright10, colorSecondary],
            ['#DC118C', colorBright2],
            [colorBright5, colorBright7],
          ],
        };

  const exportRegistry = (e: any) => {
    e.preventDefault();
    if (!exportEnabled) {
      setExportEnabled(true);
      axios
        .post(
          process.env.REACT_APP_TYPE === 'irk'
            ? '/ExportOpenETRReport'
            : props.isRA
            ? '/OpenRAReport/export'
            : '/ExportOpenRequestReport'
        )
        .then((response: AxiosResponseExt) => {
          if (response.error) {
            notification.error({
              text: response.error,
              width,
            });
          } else {
            const element = document.createElement('a');
            element.setAttribute('href', `${response.data.file}`);
            element.setAttribute('target', '_blank');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            notification.success({
              text: response.data.message,
              width,
            });
          }
        })
        .finally(() => {
          setExportEnabled(false);
          setLoading(false);
        });
    }
  };

  const exportForThePeriod = (e: any) => {
    e.preventDefault();
    if (!exportEnabled) {
      setExportEnabled(true);
      setLoading(true);
      const bodyFormData: any = new FormData();
      bodyFormData.append(
        'start',
        new Date(new Date(date[0]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      bodyFormData.append(
        'end',
        new Date(new Date(date[1]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      axios
        .post(
          process.env.REACT_APP_TYPE === 'irk'
            ? '/ExportETRByPeriod'
            : props.isRA
            ? '/ByPeriodRAReport/export'
            : '/ExportRequestsByPeriod',
          bodyFormData
        )
        .then((response: AxiosResponseExt) => {
          if (response.error) {
            notification.error({
              text: response.error,
              width,
            });
          } else {
            const element = document.createElement('a');
            element.setAttribute('href', `${response.data.file}`);
            element.setAttribute('target', '_blank');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            notification.success({
              text: response.data.message,
              width,
            });
          }
        })
        .finally(() => {
          setExportEnabled(false);
          setLoading(false);
        });
    }
  };

  const getReportByPeriod = () => {
    if (date) {
      globalLoader.setLoading(true);
      const bodyFormData = new FormData();
      bodyFormData.append(
        'start',
        new Date(new Date(date[0]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      bodyFormData.append(
        'end',
        new Date(new Date(date[1]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      if (organizationFilter.length > 0) {
        bodyFormData.append('clientOrganization', organizationFilter);
      }
      axios
        .post(
          process.env.REACT_APP_TYPE === 'irk'
            ? '/ByPeriodEngineeringTechnicalRequestReport'
            : props.isRA
            ? '/ByPeriodRAReport'
            : '/ByPeriodRequestsReport',
          bodyFormData
        )
        .then((res: AxiosResponseExt) => {
          if (!res.error && res.data && res.data.data && res.data.integralData) {
            setDataSource(res.data.data);
            setDataIntegralIndicatorsForPeriod(res.data.integralData);
          }
        })
        .catch((err) => {
          notification.error(`${t('errorHappened')} ${err}`);
        })
        .finally(() => {
          globalLoader.setLoading(false);
        });
    } else {
      notification.error(t('selectAPeriod'));
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    return current && current > moment().endOf('day');
  };

  const newDates =
    date &&
    date.map((date: Date) => {
      const newDate: Date = new Date(date);
      const options: any = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return newDate.toLocaleDateString('ru', options);
    });
  const wrapDate: HTMLDivElement | null = document.querySelector('.wrapDate');

  const getPdf = () => {
    setExportPdfEnabled(true);
    setLoading(true);
    if (wrapDate !== null) {
      const integralIndicators: HTMLElement | null = document.querySelector('.integralIndicators');
      const chart: HTMLElement | null = document.querySelector('.chartRequest');
      const chartFloating: HTMLElement | null = document.querySelector('.chartFloating');
      const pie = document.getElementById('pie');
      if (!!newDates) wrapDate.textContent = `Период с ${newDates[0]} по ${newDates[1]}`;
      if (integralIndicators !== null) {
        html2canvas(integralIndicators)
          .then((integralIndicatorsCanvas: HTMLCanvasElement) => {
            setExportPdfEnabled(false);
            if (chart !== null && chartFloating !== null) {
              html2canvas(chart)
                .then((chartCanvas: HTMLCanvasElement) => {
                  html2canvas(chartFloating)
                    .then((chartFloatingCanvas: HTMLCanvasElement) => {
                      html2canvas(wrapDate)
                        .then((dateCanvas) => {
                          const imgDate = {
                            image: dateCanvas.toDataURL(),
                            width: 200,
                            style: {
                              alignment: 'center',
                              marginBottom: 20,
                            },
                          };

                          const imgIntegralIndicators = {
                            image: integralIndicatorsCanvas.toDataURL(),
                            width: 800,
                            style: {
                              alignment: 'center',
                            },
                          };
                          const chart = {
                            image: chartCanvas.toDataURL(),
                            width: 800,
                            height: 450,
                          };
                          const chartFloating = {
                            image: chartFloatingCanvas.toDataURL(),
                            width: 800,
                          };
                          const documentDefinition: TDocumentDefinitions = {
                            content: [imgDate, chart, chartFloating, imgIntegralIndicators],
                            defaultStyle: {
                              font: 'NimbusSans',
                            },
                            // pageSize: "A4",
                            pageOrientation: 'landscape',
                            pageMargins: [40, 60, 40, 60],
                          };

                          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
                          pdfDocGenerator.download(
                            `График запросов за период ${date[0].format(
                              'DD.MM.YYYY'
                            )}-${date[1].format('DD.MM.YYYY')}.pdf`
                          );
                        })
                        .catch((err) => {
                          setLoading(false);
                          notification.error(`${t('errorHappened')}: ${err}`);
                        })
                        .finally(() => {
                          setLoading(false);
                          setExportPdfEnabled(false);
                        });
                    })
                    .catch((err) => {
                      setLoading(false);
                      notification.error(`${t('errorHappened')}: ${err}`);
                    });
                })
                .catch((err) => {
                  setLoading(false);
                  notification.error(`${t('errorHappened')}: ${err}`);
                });
            } else if (!chartFloating && !!chart) {
              html2canvas(chart)
                .then((chartCanvas: HTMLCanvasElement) => {
                  html2canvas(wrapDate)
                    .then((dateCanvas) => {
                      const imgDate: Content = {
                        image: dateCanvas.toDataURL(),
                        width: 200,
                        style: {
                          alignment: 'center',
                          marginBottom: 20,
                        },
                      };

                      const imgIntegralIndicators: Content = {
                        image: integralIndicatorsCanvas.toDataURL(),
                        width: 800,
                        style: {
                          alignment: 'center',
                        },
                      };
                      const chart = {
                        image: chartCanvas.toDataURL(),
                        width: 800,
                        height: 450,
                      };
                      const documentDefinition: TDocumentDefinitions = {
                        content: [imgDate, chart, imgIntegralIndicators],
                        defaultStyle: {
                          font: 'NimbusSans',
                        },
                        // pageSize: "A4",
                        pageOrientation: 'landscape',
                        pageMargins: [40, 60, 40, 60],
                      };

                      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
                      pdfDocGenerator.download();
                    })
                    .catch((err) => {
                      setLoading(false);
                      notification.error(`${t('errorHappened')}: ${err}`);
                    })
                    .finally(() => {
                      setLoading(false);
                      setExportPdfEnabled(false);
                    });
                })
                .catch((err) => {
                  setLoading(false);
                  notification.error(`${t('errorHappened')}: ${err}`);
                });
              setLoading(false);
              setExportPdfEnabled(false);
            }
            if (!!pie) {
              html2canvas(pie)
                .then((pieCanvas) => {
                  const imgIntegralIndicators: ContentImage = {
                    image: integralIndicatorsCanvas.toDataURL(),
                    width: 800,
                    style: {
                      alignment: 'center',
                    },
                  };
                  const chart: ContentImage = {
                    image: pieCanvas.toDataURL(),
                    width: 800,
                    height: 300,
                  };
                  const documentDefinition: TDocumentDefinitions = {
                    content: [chart, imgIntegralIndicators],
                    defaultStyle: {
                      font: 'NimbusSans',
                    },
                    // pageSize: "A4",
                    pageOrientation: 'landscape',
                    pageMargins: [40, 60, 40, 60],
                  };

                  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
                  pdfDocGenerator.download();
                })
                .catch((err) => {
                  setLoading(false);
                  notification.error(`${t('errorHappened')}: ${err}`);
                })
                .finally(() => {
                  setLoading(false);
                  setExportPdfEnabled(false);
                });
            }
          })
          .catch((err) => {
            setLoading(false);
            notification.error(`${t('errorHappened')}: ${err}`);
          });
      } else {
        setLoading(false);
        setExportPdfEnabled(false);
      }
    } else {
      setLoading(false);
      setExportPdfEnabled(false);
    }
  };

  const handleNioKseReport = () => {
    if (date) {
      setLoading(true);
      setExportEnabled(true);
      const bodyFormData: any = new FormData();
      bodyFormData.append(
        'start',
        new Date(new Date(date[0]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      bodyFormData.append(
        'end',
        new Date(new Date(date[1]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      axios
        .post('/NioKseReport', bodyFormData)
        .then((response: AxiosResponseExt) => {
          if (response.error) {
            notification.error({
              text: response.error,
              width,
            });
          } else {
            const element = document.createElement('a');
            element.setAttribute('href', `${response.data.file}`);
            element.setAttribute('target', '_blank');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            setExportEnabled(true);
            notification.success({
              text: response.data.message,
              width,
            });
          }
        })

        .finally(() => {
          setExportEnabled(false);
          setLoading(false);
        });
    } else {
      notification.error({
        text: 'Укажите период',
        width,
      });
    }
  };

  const handleExport = (e: any) => {
    if (dataSource.length > 0 || dataIntegralIndicatorsForMultiPie.length > 0) {
      if (reportType === 'requestsForThePeriod') {
        exportForThePeriod(e);
      } else {
        exportRegistry(e);
      }
    } else {
      notification.error({
        text: 'Сформируйте отчет',
        width,
      });
    }
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order !== undefined) {
      setNameColumn(sorter.field);
      setOrderDirection(sorter.order);
    } else {
      setOrderDirection(null);
      setNameColumn('');
    }
  };

  useEffect(() => {
    if (
      dataIntegralIndicatorsForPeriod.length > 0 &&
      reportType === 'requestsForThePeriod' &&
      date !== undefined
    ) {
      if (props.isRA) {
        setDataFloatingChart([
          [0, dataIntegralIndicatorsForPeriod[0]?.rows[0]?.values[0]],
          [
            dataIntegralIndicatorsForPeriod[0]?.rows[0]?.values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value),
          ],
          [0, Number(dataIntegralIndicatorsForPeriod[0]?.value)],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value),
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[1]?.rows[0].values[0],
          ],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[1]?.rows[0].values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[1]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[1]?.rows[1].values[0],
          ],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[1]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[1]?.rows[1].values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[1]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[1]?.rows[1].values[0] -
              dataIntegralIndicatorsForPeriod[3]?.rows[0].values[0],
          ],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[1]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[1]?.rows[1].values[0] -
              dataIntegralIndicatorsForPeriod[3]?.rows[0].values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[1]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[1]?.rows[1].values[0] -
              dataIntegralIndicatorsForPeriod[3]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[3]?.rows[1].values[0],
          ],
        ]);
      } else {
        setDataFloatingChart([
          [0, dataIntegralIndicatorsForPeriod[0]?.rows[0]?.values[0]],
          [
            dataIntegralIndicatorsForPeriod[0]?.rows[0]?.values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value),
          ],
          [0, Number(dataIntegralIndicatorsForPeriod[0]?.value)],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value),
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0],
          ],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0],
          ],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0] -
              dataIntegralIndicatorsForPeriod[4]?.rows[0].values[0],
          ],
          [
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0] -
              dataIntegralIndicatorsForPeriod[4]?.rows[0].values[0],
            Number(dataIntegralIndicatorsForPeriod[0]?.value) -
              dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0] -
              dataIntegralIndicatorsForPeriod[4]?.rows[0].values[0] -
              dataIntegralIndicatorsForPeriod[4]?.rows[1].values[0],
          ],
        ]);
      }
    }
  }, [dataIntegralIndicatorsForPeriod, reportType]);

  return (
    <div className={styles.wrapper}>
      <Spin spinning={loading}>
        <div className={'js-parentNode'}>
          <Title title={t('reports')} />
          <div className={stylesButtons.container}>
            {(process.env.REACT_APP_TYPE === 'irk' || reportType === 'requestsForThePeriod') && (
              <div className={stylesButtons.button} onClick={getReportByPeriod}>
                {t('generate')} {process.env.REACT_APP_TYPE === 'csdp' && t('report')}
              </div>
            )}
            {process.env.REACT_APP_TYPE === 'irk' && (
              <div className={stylesButtons.button} onClick={resetForm}>
                {t('clear')}
              </div>
            )}
            {(process.env.REACT_APP_TYPE === 'irk' ||
              (process.env.REACT_APP_TYPE === 'csdp' &&
                (reportType === 'openRequests' ||
                  (reportType === 'requestsForThePeriod' && dataSource.length > 0)))) && (
              <Button
                className={stylesButtons.button}
                htmlType='button'
                type={'text'}
                disabled={exportEnabled}
                onClick={handleExport}
              >
                {t('exportingSummaryData')}{' '}
              </Button>
            )}
            {reportType === 'requestsForThePeriod' && process.env.REACT_APP_TYPE === 'irk' && (
              <Button
                className={stylesButtons.button}
                htmlType='button'
                type={'text'}
                disabled={exportEnabled}
                onClick={handleNioKseReport}
              >
                {t('generateAnUpload')}
              </Button>
            )}
            {reportType === 'requestsForThePeriod' &&
              dataSource.length > 0 &&
              process.env.REACT_APP_TYPE === 'irk' && (
                <Button
                  htmlType='button'
                  type={'text'}
                  disabled={exportPdfEnabled}
                  className={stylesButtons.button}
                  onClick={getPdf}
                >
                  {t('chartsExport')}
                </Button>
              )}
            {((reportType === 'requestsForThePeriod' && dataSource.length > 0) ||
              reportType === 'openRequests') &&
              process.env.REACT_APP_TYPE === 'csdp' && (
                <Button
                  htmlType='button'
                  type={'text'}
                  disabled={exportPdfEnabled}
                  className={stylesButtons.button}
                  onClick={getPdf}
                >
                  {t('chartExport')}
                </Button>
              )}
          </div>
          <Collapse
            className={entityEditorDetalisStyle.collapse}
            ghost={true}
            expandIcon={({ isActive }) => <AccordionIcon active={Boolean(isActive)} />}
            defaultActiveKey={[1, 2, 3, 4]}
          >
            <Panel key={'1'} header={props.isRA ? t('reportParameters') : t('basicInformation')}>
              <Form layout={'horizontal'} className={styles.form}>
                <Form.Item className={styles.inputWrap}>
                  <span className={styles.labelInput}>{t('reportType')}</span>
                  <Select
                    ref={ref}
                    suffixIcon={dropdownIcon}
                    getPopupContainer={getPopupContainer}
                    dropdownClassName={stylesSelect.dropdown}
                    defaultValue={'openRequests'}
                    className={stylesSelect.input}
                    onChange={onChangeReportType}
                    open={isReportTypeOpen}
                    onClick={() => onSelectClick(0)}
                    onBlur={() => onSelectClick(0, false)}
                  >
                    {(props.isRA ? valuesForTypeRA : valuesForTypeReport(t)).map(
                      (value: any, i: number) => {
                        return (
                          <Option key={`value_${i}`} value={value.value}>
                            {value.label}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                {process.env.REACT_APP_TYPE === 'irk' && (
                  <Form.Item className={styles.inputWrap}>
                    <span className={styles.labelInput}>{t('requestType')}</span>
                    <Select
                      ref={ref}
                      suffixIcon={dropdownIcon}
                      getPopupContainer={getPopupContainer}
                      onChange={onChangeRequestType}
                      value={requestType}
                      dropdownClassName={stylesSelect.dropdown}
                      className={stylesSelect.input}
                      open={isRequestTypeOpen}
                      onClick={() => onSelectClick(1)}
                      onBlur={() => onSelectClick(1, false)}
                    >
                      {valuesForTypeRequests.map((value: any, i: number) => {
                        return (
                          <Option key={`value_${i}`} value={value.value}>
                            {value.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {reportType === 'requestsForThePeriod' && (
                  <>
                    {' '}
                    {process.env.REACT_APP_TYPE === 'csdp' && (
                      <Form.Item className={styles.inputWrap}>
                        <span className={styles.labelInput}>{t('organization')}</span>
                        <Select
                          ref={ref}
                          suffixIcon={dropdownIcon}
                          defaultValue={''}
                          getPopupContainer={getPopupContainer}
                          dropdownClassName={stylesSelect.dropdown}
                          className={stylesSelect.input}
                          onChange={onChangeOrganizationFilter}
                          value={organizationFilter}
                          open={isOrganizationOpen}
                          onClick={() => onSelectClick(3)}
                          onBlur={() => onSelectClick(3, false)}
                          showSearch
                          optionFilterProp='label'
                          options={organizationOptions}
                        />
                      </Form.Item>
                    )}
                    <Form.Item className={styles.inputWrap}>
                      <span className={styles.labelInput}>{t('period')}</span>
                      <Select
                        ref={ref}
                        suffixIcon={dropdownIcon}
                        defaultValue={''}
                        getPopupContainer={getPopupContainer}
                        dropdownClassName={stylesSelect.dropdown}
                        className={stylesSelect.input}
                        onChange={onChangePeriod}
                        value={period}
                        open={isPeriodOpen}
                        onClick={() => onSelectClick(2)}
                        onBlur={() => onSelectClick(2, false)}
                      >
                        {valueForPeriod(t).map((value: any, i: number) => {
                          return (
                            <Option key={`value_${i}`} value={value.value}>
                              {value.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item className={styles.inputWrap}>
                      <RangePicker
                        className={stylesDatePicker.input}
                        disabled={period !== 'arbitraryPeriod'}
                        placeholder={['', '']}
                        onChange={onChangeDate}
                        disabledDate={disabledDate}
                        value={date}
                        format={FormatUtils.dateFormat}
                      />
                    </Form.Item>
                  </>
                )}
              </Form>
            </Panel>
            <div className={`${styles.wrapDate} wrapDate`} />
            <Panel key={'2'} header={t('chart')}>
              {reportType === 'requestsForThePeriod' ? (
                <div className={styles.wrapperChart}>
                  {dataSource.length > 0 ? (
                    <>
                      <div className='chartRequest'>
                        <RequestsChart dataSource={dataSource} width={width} isRA={props.isRA} />
                      </div>
                      {process.env.REACT_APP_TYPE === 'irk' && (
                        <div className='chartFloating'>
                          {' '}
                          <FloatingChart
                            period={period}
                            width={width}
                            dataFloatingChart={dataFloatingChart}
                            dataTooltip={dataIntegralIndicatorsForPeriod}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className={i18n.language === 'en' ? styles.emptyEN : styles.empty}>
                      {t('toBuildTheChartConfigureTheMainInformationBlockAndClickGenerate')}
                      {process.env.REACT_APP_TYPE === 'csdp' && ` ${t('report')}`}"
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.wrapperPie} id='pie'>
                  {dataIntegralIndicatorsForMultiPie.map(
                    (item: ItemDataTooltipForPie, i: number) => {
                      let array: any = [];
                      if (item.rows.length >= 2) {
                        if (props.isRA) {
                          for (let i = 0; i < item.columns.length; i++) {
                            array.push({
                              columns: item.columns[i],
                              data: item.rows.map((row) => `${row.values[i]}`),
                              backgroundColor: colorPie.datasets[i] ?? [colorBright, colorBright10],
                              title: item.rows.map((row) => row.label),
                            });
                          }
                        } else {
                          for (let i = 0; i < item.columns.length - 1; i++) {
                            array.push({
                              columns: item.columns[i + 1],
                              data: item.rows.map((row) => `${row.values[i + 1]}`),
                              backgroundColor: colorPie.datasets[i] ?? [colorBright, colorBright10],
                              title: item.rows.map((row) => row.label),
                            });
                          }
                        }
                      } else {
                        let colors =
                          process.env.REACT_APP_TYPE === 'irk'
                            ? [colorBright, colorSecondary, colorBright5]
                            : [colorBright, colorSecondary, colorBright2, colorBright5];
                        if (props.isRA) {
                          array = [
                            {
                              title: item.rows[0].label,
                              columns: item.columns,
                              data: item.rows[0].values.map((val) => `${val}`),
                              backgroundColor: item.columns.map(
                                (col, i) => colors[i] ?? colorBright10
                              ),
                            },
                          ];
                        } else {
                          array = [
                            {
                              title: item.rows[0].label,
                              columns: item.columns.slice(1),
                              data: item.rows[0].values.slice(1).map((val) => `${val}`),
                              backgroundColor: item.columns.map(
                                (col, i) => colors[i] ?? colorBright10
                              ),
                            },
                          ];
                        }
                      }
                      return (
                        <MultiPieChart
                          key={`multiPieChart_${i}`}
                          data={array}
                          title={item.headerTitle}
                          dataTooltip={item.rows}
                          isRA={props.isRA}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </Panel>
            <Panel key={'3'} header={t('integralIndicators')} className='integralIndicators'>
              <div className={styles.wrapperIntegral}>
                {(reportType === 'requestsForThePeriod'
                  ? dataIntegralIndicatorsForPeriod
                  : dataIntegralIndicatorsForMultiPie
                ).map((item: any, i: number) => {
                  return (
                    <IntegralIndicators
                      key={`integralIndicators_${i}`}
                      title={item.headerTitle}
                      data={item.rows}
                      columns={item.columns}
                      sum={
                        item.headerValue
                          ? item.headerValue
                          : `${item.value} ${t('piece')} / ${item.valuePercent} %`
                      }
                    />
                  );
                })}
              </div>
            </Panel>
            <Panel key={'4'} header={t('summaryData')} className={styles.panelTable}>
              {reportType === 'requestsForThePeriod' ? (
                <TableRequestsForThePeriod dataSource={dataSource} isRA={props.isRA} />
              ) : (
                <TableGrid
                  onChange={handleTableChange}
                  columns={columns}
                  dataSource={items}
                  isVisible={true}
                  loading={isTableLoading}
                  screenResolution={handleWidthTableForShortView(width)}
                />
              )}
            </Panel>
          </Collapse>
        </div>
      </Spin>
    </div>
  );
};

export default Reports;
