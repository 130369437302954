import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Collapse, Row } from 'antd';
import EntityList from 'pages/entityList/EntityList';
import styles from './InboxGroupList.module.css';
import entityEditorDetalisStyle from 'pages/entityEditor/Tabs/EntityEditorDetails.module.css';
import stylesEntityList from 'pages/entityList/EntityList.module.css';
import AccordionIcon from 'components/icons/AccordionIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import FilterSearchIcon from 'components/icons/FilterSearchIcon';
import Title from 'components/layout/content/title/Title';
import { useTranslation } from 'react-i18next';
import composeLink from 'utils/composeLink';
import { getInboxGroupToolIcon } from './utils';

export interface InboxGroupListProps {
  inboxGroup?: any;
  groupID?: string;
  entityListProps: any;
  menu: any;
}

const { Panel } = Collapse;

const InboxGroupList: React.FC<InboxGroupListProps> = ({ inboxGroup, groupID, entityListProps, menu }) => {
  const history = useHistory();
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState(false);
  const {t} = useTranslation();

  const keys = inboxGroup.inboxGroup?.inboxes.map((key: any, i: number) => `panel_${i}`);

  const resetFilters = () => {
    setIsReset(true);
    setIsSearch(false);
  };

  const allSearch = () => {
    setIsSearch(true);
  };

  return (
    <>
      <div className={styles.wrapperTitle}>
        <Title title={inboxGroup.inboxGroup.label}/>
        <div className={styles.wrapper}>
          {inboxGroup?.tools?.map((tool: any, i: number) => {
            return (
              <Button
              key={i}
              htmlType='button'
              className={stylesEntityList.button}
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  composeLink({
                    inbox: tool.inboxName,
                    className: menu.inboxes[tool.inboxName].caseName,
                    id: 'new',
                  })
                );
              }}
              >
              {getInboxGroupToolIcon(tool.icon)}
              {tool.label}
              </Button>
            );
          })}
          <Button
            style={{ marginBottom: 0 }}
            onClick={resetFilters}
            className={stylesEntityList.button}
            icon={
              <DeleteIcon
                className={stylesEntityList.iconButton}
              />
            }
          >
            {t("reset")}
          </Button>
          <Button
            style={{ marginBottom: 0 }}
            onClick={allSearch}
            className={stylesEntityList.button}
            icon={
              <FilterSearchIcon
                className={stylesEntityList.iconButton}
              />
            }
          >
            {t("apply")}
          </Button>
        </div>
      </div>

      <Row className={[styles.list, 'main-content'].join(' ')}>
        <Collapse
          className={entityEditorDetalisStyle.collapse}
          ghost={true}
          defaultActiveKey={keys}
          expandIcon={({ isActive }) => <AccordionIcon active={Boolean(isActive)} />}
        >
          {inboxGroup?.mainPageInboxes?.map((inbox: string, i: number) => {
            if (menu.inboxes[inbox]) {
              const actualInboxName = Object.keys(menu.inboxes).find(key => key !== inbox && menu.inboxes[key].caseName === menu.inboxes[inbox].caseName) || inbox;
              return (
                <Panel key={`panel_${i}`} header={menu.inboxes[actualInboxName].label}>
                  <EntityList
                    {...entityListProps}
                    setIsSearch={setIsSearch}
                    setIsReset={setIsReset}
                    isSearch={isSearch}
                    isReset={isReset}
                    isShortView={true}
                    inboxName={menu.inboxes[actualInboxName].name}
                    className={menu.inboxes[actualInboxName].caseName}
                  />
                </Panel>
              );
            }

            return <React.Fragment key={`fragment_${i}`} />;
          })}
        </Collapse>
      </Row>
    </>
  );
};

export default InboxGroupList;
