import axios from 'axios';
import { getSelectParams } from 'components/controls/SelectControl/SelectControl';
import { FormComponentData } from 'interfaces/BaseComponentProps';

export const isHiddenPanel = (panel: any): boolean => Boolean(panel?.hidden);

export const isHiddenComponent = (component?: FormComponentData): boolean => {
  if (!component) return false;

  return (
    component.templateName === 'hidden' || component.visible === false || component.hidden === true
  );
};

export const isActiveComponent = (component?: FormComponentData): boolean => {
  if (!component) return false;

  return Boolean(
    !component.readonly ||
    component.params?.active ||
    component.templateName === 'attachmentList' ||
    component.templateName === 'cardTable'
  );
};
export const isTransitionComponent = (component?: FormComponentData): boolean => {
  if (!component) return false;

  return Boolean(
    getSelectParams(component)?.transition)

};

export const getLegendName = (legend: string) => {
  let name = legend
  if (legend && legend.includes("name")) {
    name = JSON.parse(legend).name
  }
  return name
}

export const getLegendHint = async (legend: string) => {
  let hint = ''
  if (legend && legend.includes("hint")) {
    hint = JSON.parse(legend).hint
  }
  if (legend && legend.includes("hintController")) {
    await axios.get(JSON.parse(legend).hintController).then((res) => hint = res.data.message as string);
  }
  return hint
}

export const getLegendHintSync = (legend: string) => {
    let hint = ''
    if (legend && legend.includes("hint")) {
      hint = JSON.parse(legend).hint
    }
    return hint
  }

