import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size';
import { Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import loadable from '@loadable/component';
import { uniqueId } from 'lodash';

import TwoColumnGrid from 'components/layout/content/TwoColumnGrid';
import SiderWithTabs, { DEFAULT_TAB, SiderTab } from 'components/navigation/Sider/SiderWithTabs';
import EntityEditorDetails from './Tabs/EntityEditorDetails';
import EntityListFilters from 'pages/entityList/entityListFilters/EntityListFilters';
import notification from 'components/messages/notification';

import styles from './EntityEditor.module.css';

import { EntityEditorRouteParams } from 'router/Routes';
import MiscUtils from 'utils/MiscUtils';
import composeLink from 'utils/composeLink';
import { AppState } from 'store/reducers';
import { ActionType } from 'store/actionTypes';
import { getEditorRefreshTime } from 'store/selectors/editor';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { processExportResponse } from 'utils/ProcessExportResponse';
import { useTranslation } from 'react-i18next';

const EntityEditorResponse = loadable(() => import('./Tabs/EntityEditorResponse'));
const EntityEditorHistory = loadable(() => import('./Tabs/EntityEditorHistory'));
const EntityEditorLinkedNew = loadable(() => import('./Tabs/EntityEditorLinkedNew'));
const EntityEditorRoutingHistory = loadable(() => import('./Tabs/EntityEditorRoutingHistory'));

interface EntityEditorProps extends RouteComponentProps<EntityEditorRouteParams> {
  menu: any;
  formConfig: any;
  editorButtons: any;
  currentTab: string;
  editorRefreshTime?: any;
}

const EntityEditor: React.FC<EntityEditorProps> = (props) => {
  const params: EntityEditorRouteParams = props.match.params;
  const {
    inboxName,
    className,
    objectID,
    parentId,
    useKB,
    preFillResponse,
    backUrl,
    parentClassName,
  } = params;
  const { editorButtons, menu } = props;
  const { currentTab } = editorButtons;
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonsLoader, setbuttonLoader] = useState<boolean>(false);
  const [tabsData, setTabsData] = useState<any>({});
  const [buttonsData, setButtonsData] = useState<any>({});
  const [tabs, setTabs] = useState<SiderTab[]>([]);
  const [refresh, setRefresh] = useState<any>();
  const [buttonState, setButtonState] = useState<boolean>(false);
  const [formFilter] = useForm();
  const [width] = useWindowSize();
  const [form] = useForm();
  const [entityEditorResponseForm] = useForm();

  const {t} = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE_EDITOR_FORM,
      payload: form,
    });
  }, [form]);

  useEffect(() => {
    // reload entity editor from outside component (TableControl and etc)
    if (props.editorRefreshTime !== null && props.editorRefreshTime !== refresh) {
      setRefresh(props.editorRefreshTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editorRefreshTime]);

  useEffect(() => {
    if (tabs.length > 0) {
      const tab = tabs.find((tab: any) => {
        if (props.location.hash.includes(tab.id)) {
          return tab.id;
        }
      });
      dispatch({
        type: ActionType.UPDATE_EDITOR_TAB,
        payload: tab === undefined ? DEFAULT_TAB : tab.id,
      });
    }
  }, [props.location.hash, tabs]);

  useEffect(() => {
    if (buttonsData != null) {
      const newButtons: any = {};
      Object.keys(buttonsData).forEach((buttonTabKey: string) => {
        if (!newButtons.hasOwnProperty(buttonTabKey)) {
          newButtons[buttonTabKey] = [];
        }
        const currentButtons: any = buttonsData[buttonTabKey];
        if (currentButtons != null) {
          Object.keys(currentButtons).forEach((buttonKey: string) => {
            const currentButton: any = currentButtons[buttonKey];
            const isBPM: boolean = currentButton.controller.indexOf('BPM') === 0;
            newButtons[buttonTabKey].push({
              ...{
                loader: (isLoading: boolean) => {
                  if (isBPM) {
                    setbuttonLoader(isLoading);
                  }
                },
              },
              ...currentButton,
              ...{
                action: (objectData?: any) => {
                  if (currentButton.controller != null) {
                    if (isBPM && currentButton.params) {
                      // update bpm launch ID param if document changed
                      if (objectData != null && objectData !== false) {
                        if (objectData.params && objectData.params.ID != null) {
                          if (currentButton.params.id === null) {
                            currentButton.params.id = objectData.params.ID;
                          }
                        }
                      }
                      const bodyFormData = new FormData();
                      Object.keys(currentButton.params).forEach((param: string) => {
                        bodyFormData.append(param, currentButton.params[param]);
                      });
                      return axios
                        .post(`/${currentButton.controller}`, bodyFormData)
                        .then((response: AxiosResponseExt) => {
                          if (!response.error) {
                            const answer: string = MiscUtils.notNullString(
                              MiscUtils.cleanUpHtml(response.data.answer)
                            );
                            if (answer !== '') {
                              if (response.data.success === true) {
                                notification.success({
                                  text: answer,
                                  width,
                                });
                              } else {
                                if (answer.includes('сообщение')) {
                                  entityEditorResponseForm.validateFields().catch((err: any) => {
                                    entityEditorResponseForm.scrollToField('ResponceType', {
                                      behavior: 'smooth',
                                      block: 'start',
                                      inline: 'end',
                                    });
                                  });
                                }
                                notification.error({
                                  text: answer,
                                  width,
                                });
                              }
                            }
                          }
                          setRefresh(new Date().getTime());
                        });
                    } else if (currentButton.controller === 'createLinkedObjectCaseTool') {
                      let formValues: any = form.getFieldsValue(true);
                      // update form values from new object
                      if (objectData != null && objectData !== false) {
                        formValues = objectData.params;
                      }
                      const { params } = currentButton;
                      const buttonClassName = params.className || className;
                      let buttonInboxView = inboxName;
                      if (params.className) {
                        const { inboxes } = menu;
                        if (inboxes && inboxes[params.className]) {
                          buttonInboxView = inboxes[params.className].inboxView || inboxName;
                        }
                      }
                      history.push(
                        composeLink({
                          inbox: buttonInboxView,
                          className: buttonClassName,
                          id: 'new',
                          parentClassName: className,
                          parentId: formValues.VersionSeriesId,
                        })
                      );
                      return Promise.resolve();
                    } else if (currentButton.controller === 'deleteCaseTool') {
                      notification.error({
                        text: t('executorSkipped'),
                        width,
                      });
                      return Promise.resolve();
                    } else if (currentButton.controller === 'parameterReportTool') {
                      setLoading(true);
                      let currentObjectId: string | undefined = objectID;
                      if (objectData && objectData.id && objectID !== objectData.id) {
                        currentObjectId = objectData.id;
                      }
                      let bodyFormData = new FormData();
                      if (currentObjectId) {
                        bodyFormData.append('conversationId', currentObjectId);
                        bodyFormData.append('caseId', currentObjectId);
                      }
                      if (className) {
                        bodyFormData.append('caseType', className);
                      }
                      bodyFormData.append('toolName', currentButton.params.toolName);

                      // Object.keys(currentButton.params).forEach((param: string) => {
                      //   bodyFormData.append(param, currentButton.params[param]);
                      // });
                      const controller = `/${currentButton.controller}JsonIrk`;
                      const toolId = `${currentButton.params.toolName}/${new Date().getTime()}`;
                      return axios
                        .post(`${controller}/index`, bodyFormData)
                        .then((response: AxiosResponseExt) => {
                          if (response.data.show !== `casePreloader`) {
                            throw new Error(`no casePreloader`);
                          }
                          bodyFormData = new FormData();
                          if (currentObjectId) {
                            bodyFormData.append('conversationId', currentObjectId);
                          }
                          bodyFormData.append('toolName', currentButton.params.toolName);
                          bodyFormData.append('toolRequestId', toolId);
                          bodyFormData.append('inboxName', '');
                          return axios.post(`${controller}/confirmReportParams`, bodyFormData);
                        })
                        .then((response: AxiosResponseExt) => {
                          if (response.data.confirmAction !== 'searchParams') {
                            throw new Error(`no searchParams`);
                          }
                          return axios.post(`${controller}/searchParams`, bodyFormData);
                        })
                        .then((response: AxiosResponseExt) => {
                          if (!response.data.requestId) {
                            throw new Error(`no requestId`);
                          }
                          bodyFormData.append('requestId', response.data.requestId);
                          let hasResult = false;
                          const askResult = () => {
                          bodyFormData.append('className', className);
                            return axios
                              .post(`${controller}/askResult`, bodyFormData)
                              .then((response: AxiosResponseExt) => {
                                if (!!response.data.file) {
                                  processExportResponse(response);
                                  hasResult = true;
                                } else {
                                  if (!response.data.status) {
                                    throw new Error('no status');
                                  }
                                  switch (response.data.status) {
                                    case 'IN_PROGRESS':
                                      hasResult = false;
                                      break;
                                    case 'COMPLETED':
                                      hasResult = true;
                                      const link = document.createElement('a');
                                      link.target = '_blank';
                                      link.href = response.data.result;
                                      link.download = `${response.data.id}.${response.data.format}`;
                                      link.click();
                                      break;
                                    default:
                                      hasResult = true;
                                      throw new Error(`wrong status ${response.data.status}`);
                                  }
                                }
                              })
                              .catch((reason) => {
                                hasResult = true;
                                throw new Error(reason);
                              });
                          };
                          return (async () => {
                            while (!hasResult) {
                              await MiscUtils.delay(1000);
                              await askResult();
                            }
                          })();
                        })
                        .catch((reason) => {
                          notification.error({
                            text: t('reportGenerationError'),
                            width,
                          });
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    } else {
                      if (
                        currentButton.params != null &&
                        currentButton.params.preFillObject != null
                      ) {
                        form.setFieldsValue(currentButton.params.preFillObject);
                      }
                      form.submit();
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            });
          });
        }
      });
      dispatch({
        type: ActionType.UPDATE_EDITOR_ALL_BUTTONS,
        payload: newButtons,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonsData]);

  useEffect(() => {
    if (tabs != null) {
      const buildTabs = (tabs: any): SiderTab[] => {
        const result: SiderTab[] = [];
        for (const key in tabs) {
          if (tabs.hasOwnProperty(key)) {
            const _tab = tabs[key];
            if (_tab != null) {
              let hideTab: boolean = false;
              if (_tab && _tab.params && _tab.params.dataTableConfig) {
                _tab.params.dataTableConfig.forEach((config: any) => {
                  if (config.showInSidebar === false) {
                    hideTab = true;
                  }
                });
              }
              const tab: SiderTab = {
                id: key,
                icon: key,
                controller: _tab.controller,
                caption: _tab.label,
                params: _tab.params,
                active: key === currentTab || (!currentTab && key === DEFAULT_TAB),
                clickHandler: {
                  link: `#${key}`,
                },
              };
              if (!hideTab) {
                result.push(tab);
              }
            }
          }
        }
        return result;
      };
      setTabs(buildTabs(tabsData || {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsData, className, objectID, currentTab]);

  const isVisible = (_tabId: string) => {
    return currentTab === _tabId ? `main-content__wrap ${styles.tabContainer}` : styles.hidden;
  };

  useEffect(() => {
    if (objectID === 'new' || params.message || tabs.length <= 1) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [objectID, params.message, tabs, className]);

  const baseProps = {
    inboxName,
    className,
    objectID,
    parentId,
    refresh,
    backUrl,
    buttonsData,
    setRefresh,
  };

  return (
    <TwoColumnGrid
      buttonState={buttonState}
      name={'entityeditor'}
      sider={
        <SiderWithTabs
          tabs={tabs}
          buttons={<></>}
          filters={tabs.map((tab: any) => {
            if (tab.controller === 'GetLinkedTableTabController') {
              return (
                <div className={isVisible(tab.id)} key={`tab_${tab.id}`}>
                  <EntityListFilters
                    form={formFilter}
                    populateFactoryAndConfigFromStorage={tab.id}
                    config={null}
                    action={(config: any) => {
                      dispatch({
                        type: ActionType.UPDATE_SEARCH_CONFIGURATIONS,
                        payload: {
                          config,
                          key: tab.id,
                        },
                      });
                    }}
                    showFilters={tab.id === currentTab}
                    className={className}
                    inboxName={inboxName}
                  />
                </div>
              );
            } else if (tab.controller === 'GetRoutingHistoryInformation') {
              return (
                <div className={isVisible(tab.id)} key={`tab_${tab.id}`}>
                  <EntityListFilters
                    form={formFilter}
                    populateFactoryAndConfigFromStorage={tab.id}
                    config={null}
                    action={(config: any) => {
                      dispatch({
                        type: ActionType.UPDATE_SEARCH_CONFIGURATIONS,
                        payload: {
                          config,
                          key: tab.id,
                        },
                      });
                    }}
                    showFilters={tab.id === currentTab}
                    className={className}
                    inboxName={inboxName}
                  />
                </div>
              );
            } else {
              return <React.Fragment key={uniqueId()} />;
            }
          })}
        />
      }
    >
      <Spin spinning={loading}>
        <div className={isVisible(DEFAULT_TAB)}>
          {/* <EntityEditorButtons /> */}
          <EntityEditorDetails
            setTabsData={setTabsData}
            setButtonsData={setButtonsData}
            parentClassName={parentClassName}
            form={form}
            useKB={useKB}
            {...baseProps}
            currentTabId={DEFAULT_TAB}
            innerViews={
              <>
                {Object.keys(tabsData)
                  .reduce((result: any[], current: string) => {
                    if (tabsData[current].controller === 'GetResponses') {
                      result.push({
                        ...tabsData[current],
                        ...{ tabName: current },
                      });
                    }
                    return result;
                  }, [])
                  .map((respTab: any, idx: number) => {
                    return (
                      <EntityEditorResponse
                        formFromEditor={entityEditorResponseForm}
                        key={`tab_editor__${respTab.tabName}_${idx}`}
                        {...baseProps}
                        tabInboxName={respTab.params.dataTableConfig[0].inboxName}
                        currentTabId={respTab.tabName}
                        useKB={useKB}
                        preFillResponse={preFillResponse}
                      />
                    );
                  })}
              </>
            }
          />
        </div>
        {Object.keys(tabsData)
          .reduce((result: any[], current: string) => {
            if (tabsData[current].controller === 'GetLinkedTableTabController') {
              result.push({
                ...tabsData[current],
                ...{ tabName: current },
              });
            }
            return result;
          }, [])
          .map((linkedTab: any) => {
            return (
              <div key={`linkedTab_${linkedTab.tabName}`} className={isVisible(linkedTab.tabName)}>
                <EntityEditorLinkedNew
                  formFilter={formFilter}
                  isVisible={currentTab === linkedTab.tabName}
                  {...baseProps}
                  tabInboxName={linkedTab.params.dataTableConfig[0].inboxName}
                  currentTabId={linkedTab.tabName}
                  linkedTab={linkedTab}
                />
              </div>
            );
          })}
        {Object.keys(tabsData)
          .reduce((result: any[], current: string) => {
            if (tabsData[current].controller === 'GetHistoryTabController') {
              result.push({
                ...tabsData[current],
                ...{ tabName: current },
              });
            }
            return result;
          }, [])
          .map((historyTab: any) => {
            return (
              <div
                key={`historyTab_${historyTab.tabName}`}
                className={isVisible(historyTab.tabName)}
              >
                <EntityEditorHistory
                  historyTab={historyTab}
                  {...baseProps}
                  currentTabId={historyTab.tabName}
                  currentTab={currentTab}
                />
              </div>
            );
          })}
        {Object.keys(tabsData)
          .reduce((result: any[], current: string) => {
            if (tabsData[current].controller === 'GetRoutingHistoryInformation') {
              result.push({
                ...tabsData[current],
                ...{ tabName: current },
              });
            }
            return result;
          }, [])
          .map((routingHistory: any) => {
            return (
              <div
                key={`routingHistory_${routingHistory.tabName}`}
                className={isVisible(routingHistory.tabName)}
              >
                <EntityEditorRoutingHistory
                  tabInboxName={routingHistory.params.dataTableConfig[0].inboxName}
                  isVisible={currentTab === routingHistory.tabName}
                  {...baseProps}
                  routingHistory={routingHistory}
                  currentTabId={routingHistory.tabName}
                  formFilter={formFilter}
                />
              </div>
            );
          })}
      </Spin>
    </TwoColumnGrid>
  );
};

export default connect((state: AppState, props: RouteComponentProps) => {
  return {
    menu: state.menu,
    editorButtons: state.editorButtons,
    editorRefreshTime: getEditorRefreshTime(state),
  };
})(EntityEditor);
