import React, { useEffect, useRef, useState } from 'react';
import { Form, Spin } from 'antd';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { FormInstance } from 'antd/lib/form/Form';
import axios from 'axios';
import cn from 'classnames';
import { UnregisterCallback } from 'history';
import { IS_CHANGED_KEY } from 'interfaces/Form';
import CardLayout from 'components/CardLayout';
import ModalForm from 'components/modals/ModalForm';
import modal from 'components/messages/modal';
import ControlFactory from 'utils/ControlFactory';
import EntityEditorButtons, { ControllerList, EditorButton } from '../Buttons/EntityEditorButtons';
import EntityEditorContext from '../EntityEditorContext/EntityEditorContext';
import { useBeforeunload } from 'react-beforeunload';
import styles from './EntityEditorDetails.module.css';

import {
  useEditorChanged,
  useLayoutFactory,
  useObjectCrud,
  useRefStore,
  useRefreshSubscribe,
  useTabsCrud,
  useToolsCrud,
  useUpdateEditor,
  useFormHandler,
  useCrumbs,
} from 'hooks';

import composeLink from 'utils/composeLink';
import { getFilteredHash, getFilteredLocationPath } from 'utils/NavUtils';
import { getChildrenParentID, getDocumentId, isNewID } from 'utils/ObjectUtils';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';
import { RefreshID } from 'enums/refresh';
import { ConfirmObjectSave, ObjectData, SaveObject } from 'interfaces/Object';
import { FormObjectData } from 'interfaces/Form';
import ServerError from 'integration/ServerError';
import { useTranslation } from 'react-i18next';
import notification from 'components/messages/notification';
import MiscUtils from 'utils/MiscUtils';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import ServerAPI from 'integration/ServerAPI';


// import Title from "components/layout/content/title/Title";
const Title = loadable(() => import('components/layout/content/title/Title'));

interface EntityEditorDetailsProps {
  inboxName?: string;
  className: string;
  objectID: string;
  parentId?: string;
  parentClassName?: any;
  form: FormInstance;
  currentTabId: string;
  innerViews?: JSX.Element;
  refresh?: any;
  setRefresh?: (data: any) => void;
  backUrl?: string;
  setTabsData: (data: any) => void;
  setButtonsData: (data: any) => void;
  useKB?: string;
}

interface AsyncListItem {
  pathname: string;
  objectID: string;
  done: boolean;
  factory?: ControlFactory;
  objectData?: ObjectData;
}

export const attachContentToDocument = (filesToAttache: string[], saveParams: any) => {
  const filesToAttacheRequests: Promise<any>[] = [];
  if (filesToAttache.length > 0) {
    const body: FormData = new FormData();
    body.append('contentIds', JSON.stringify({
        Files: filesToAttache
    }));
    body.append('property', JSON.stringify(saveParams));
    filesToAttacheRequests.push(axios.post('/ContentCrud/attachContentToDocument', body));
  }
  return filesToAttacheRequests;
};

const EntityEditorDetails: React.FC<EntityEditorDetailsProps & RouteComponentProps> = (props) => {
  const {
    inboxName,
    className,
    parentId,
    parentClassName,
    refresh: refreshEditor,
    setTabsData,
    setButtonsData,
    objectID,
    form,
    useKB,
  } = props;
  const editorDetails = useSelector((state: AppState) => state.editorDetails);
  const crumbs = useSelector((state: AppState) => state.crumbs);
  const username = useSelector((state: AppState) => state.typeUser.userData?.user?.params?.Name);
  const userId = useSelector((state: AppState) => state.typeUser.userData?.user?.id);
  const editorResponsesForm: any = useSelector((state: AppState) => state.editorResponses);
  const buttons: EditorButton[] =
    useSelector((state: AppState) => {
        const currTab = state.editorButtons.currentTab;
        return currTab && state.editorButtons.buttons[currTab];
    }) || [];

  const { t } = useTranslation();
  const params: any = props.match.params;

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, hash } = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const leavePage = useRef<boolean>(false);
  const asyncList = useRef<AsyncListItem[]>([]);

  // чтобы карточка и шапка не обрезались если шапка толстая
  // без этого шапка обрезается при разломе валидации и автоматическом скролле к полю
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const headerRefChange = (node: HTMLDivElement) => {
    const headerOffsetHeight = node?.offsetHeight;
    if (!headerOffsetHeight || loading) return;
    setHeaderHeight(headerOffsetHeight);
  };
  const { refStore, toggleProcessKeyWrapper } = useRefStore();
  const { addCrumb } = useCrumbs();
  const { updateEditorErrors, updateEditorDetails } = useUpdateEditor();
  const { updateEditorChanged, checkEditorChanged, checkEditorGeneralChanged, updateEditorGeneralChanged} = useEditorChanged({ form });

  // подписка на перезагрузку лэйаута и объекта
  const { refreshCount: reload, refreshSubscriber: reloadSelf } = useRefreshSubscribe(
    RefreshID.EDITOR_DETAILS_DATA
  );

  // подписка на ререндер карточки
  useRefreshSubscribe(RefreshID.EDITOR_DETAILS);

  const {
    layoutFactory: factory,
    setLayoutFactory: setFactory,
    createLayoutFactory,
  } = useLayoutFactory({ className, objectID, parentId, parentClassName, inboxName });

  const { setFormData, validateFields } = useFormHandler(form);

  const { preFillNewObject, getObjectInfo, createObject, updateObject, handleValuesChange, updatePassword } =
    useObjectCrud({
      form,
      objectID,
      className,
      parentId,
      parentClassName,
      setLoading,
      setFactory,
    });

  const { getTabsData } = useTabsCrud({ className, objectID, useKB: !!useKB });
  const { getToolsData } = useToolsCrud({ className, objectID });

  const loadObjectData = async (factory?: ControlFactory) => {
    if (!factory) return;

    setLoading(true);

    form.resetFields();

    updateEditorErrors();

    let objectData: ObjectData | undefined;
    let objectIdChanged: boolean | undefined;

    if (isNewID(objectID)) {
      const result = await preFillNewObject({...editorDetails, inboxName});
      objectData = result.objectData;
      if (objectData?.id) objectIdChanged = true;
    } else {
      const result = await getObjectInfo();
      objectData = result.objectData;
      objectIdChanged = result.newID;
      updateEditorChanged(false);
    }

    if (!objectData) return;

    await factory._fetchChoiceListDataWithParams(form, objectData.params);

    if (objectIdChanged) {
      history.replace(
        composeLink({
          inbox: inboxName,
          className: objectData.classType,
          id: objectData.id,
          anchor: getFilteredHash(hash),
        })
      );
      return;
    }

    setTabsData((await getTabsData()) || {});
    setButtonsData((await getToolsData()) || {});

    dispatch({
      type: ActionType.UPDATE_FILTERS,
      payload: {
        filtersId: className,
        filters: {},
      },
    });

    return objectData;
  };

  const processAsyncList = () => {
    if (!asyncList.current.length) return;

    let lastObjectData: ObjectData | undefined;
    let lastFactory: ControlFactory | undefined;

    for (let i = 0; i < asyncList.current.length; i++) {
      const asyncListItem: AsyncListItem = asyncList.current[i];
      const { pathname, objectID, done, factory, objectData } = asyncListItem;

      if (done) continue;
      if (!objectData || !factory) return;

      const title: string = factory.getConfig().params.Title;

      if (isNewID(objectID) || objectID === objectData.id) {
        addCrumb({ versionSeriesId: objectData.params?.VersionSeriesId, title, path: pathname });
      }

      lastObjectData = objectData;
      lastFactory = factory;
      asyncListItem.done = true;
    }

    if (lastObjectData) updateEditorDetails(lastObjectData);
    if (lastFactory) setFactory(lastFactory);

    asyncList.current = asyncList.current.filter((item: AsyncListItem) => !item.done);

    if (!asyncList.current.length) setLoading(false);
  };


  const loadLayoutAndObjectData = async () => {
    if (leavePage.current) return;

    const asyncListItem: AsyncListItem = { pathname, objectID, done: false };
    asyncList.current.push(asyncListItem);

    setLoading(true);

    const newFactory: ControlFactory | undefined = await createLayoutFactory();
    asyncListItem.factory = newFactory;

    const objectData: ObjectData | undefined = await loadObjectData(newFactory);
    asyncListItem.objectData = objectData;

    if (!newFactory || !objectData) asyncListItem.done = true;

    processAsyncList();
  };

  const onValuesChange = (changedValues: FormObjectData) =>
    handleValuesChange(changedValues, editorDetails, factory);
  const _saveObject: SaveObject = async (toolName) => {
    if (!(await validateFields())) return;

    setLoading(true);
    updateEditorErrors();

    let objectData: ObjectData | undefined;

    if (isNewID(objectID)) {
      const result = await createObject(editorDetails, toolName);
      objectData = result.objectData;
    } else {
      if (className === 'Users' && !!editorDetails.changedNotifications && editorDetails.changedNotifications.length > 0) {
        axios.post('/UserEmailSubscriptions/update', {
          updatedEventCheckboxes: editorDetails.changedNotifications
        }).catch((err: ServerError) => err.notify());
      }

      if (!!form.getFieldValue('Password')) {
        form?.setFields([
            {
              name: 'Password',
              errors: [],
            },
            {
              name: 'PasswordRepeat',
              errors: [],
            },
        ]);
        const newPassword = form.getFieldValue('Password');
        const repeatedPassword = userId === editorDetails.id ? form.getFieldValue('PasswordRepeat') : newPassword;
        const res = await updatePassword(newPassword, repeatedPassword, editorDetails.id);
        if (!res?.error) {
            form.setFieldValue('Password', '');
            form.setFieldValue('PasswordRepeat', '');
        } else {
            setLoading(false);
            return;
        }
      }

      const result = await updateObject(editorDetails, toolName);
      const answer: string = MiscUtils.notNullString(result.answer);
      if (answer !== '') {
        if (result.success === true) {
          notification.success({
            text: answer,
          });
        } else {
          notification.error({
            text: answer,
          });
        }
      }
      objectData = result.objectData;
      if (result.reload) reloadSelf();
    }

    if (objectData) {
      updateEditorChanged(false);
      const newLink: string = composeLink({
        inbox: inboxName,
        className: objectData.classType,
        id: objectData.id,
        parentClassName,
        parentId,
      });

      if (newLink === pathname) {
        updateEditorDetails(objectData);
      } else {
        history.replace(newLink);
      }
    }

    setLoading(false);

    return objectData;
  };

  const saveObject: SaveObject = toggleProcessKeyWrapper('blockActions', _saveObject, {
    rollbackDelay: 1000,
  });

  const sendEmailNotif = async (event: string) => {
    const response: AxiosResponseNull = await ServerAPI.axiosFormDataPost(ControllerList.EMAIL_NOTIF, {
      id: editorDetails.id,
      className: editorDetails.classType,
      event
    }).catch((serverError: ServerError) => serverError.notify());

    if (response?.status === 200) {
      notification.success(t('notificationSent'));
    }
  };

  const blockInProcess = useRef<boolean>(false);

  // TODO: отрефакторить реализацию кастомных модалок вместе с бэком
  const confirmObjectSave: ConfirmObjectSave = (allow, { toTab } = {}) => {
    modal.confirmSave({
      onOk: async () => {
        if (!toTab) leavePage.current = true;
        const saveRes = await saveObject();
        if (editorDetails?.classType.includes('Meeting') && checkEditorGeneralChanged()) {
          await sendEmailNotif(buttons.find(button => button.controller === ControllerList.EMAIL_NOTIF)?.params.event);
        }
        if (saveRes) allow?.();
        blockInProcess.current = false;
      },
      onCancel: () => {
        if (!toTab && allow) {
          updateEditorDetails();
          updateEditorChanged(false);
          updateEditorGeneralChanged(false);
          allow();
        }

        blockInProcess.current = false;
      },
      cancelText: toTab || (editorDetails?.classType.includes('Meeting') && checkEditorGeneralChanged()) ? t('cancel') : t('leaveWithoutSaving'),
      contentText: editorDetails?.classType.includes('Meeting') && checkEditorGeneralChanged() ? '' : undefined,
      title: editorDetails?.classType.includes('Meeting') && checkEditorGeneralChanged() && t('sendNotification'),
      okText: editorDetails?.classType.includes('Meeting') && checkEditorGeneralChanged() && t('yes')
    });
  };

  const unblockRef = useRef<UnregisterCallback>();

  if (!blockInProcess.current) {
    unblockRef.current?.();

    const unblock = history.block(({ pathname: p, hash: h }) => {
      blockInProcess.current = true;
      const toTab = !!h && !h.includes('state=');
      const targetPath = getFilteredLocationPath({ pathname: p, hash: h });
      const currentPath = getFilteredLocationPath({ pathname, hash });
      const activeCrumbsChain = crumbs.activeChainId ? crumbs.chains[crumbs.activeChainId] : [];
      const searchedTargetCrumbIdx = activeCrumbsChain.findIndex((i: any) => i.path === targetPath);
      const searchedCurrentCrumbIdx = activeCrumbsChain.findIndex(
        (i: any) => i.path === currentPath
      );

      // добавляем крамб в случае перехода из таба в EntityEditorDetails
      if (p === pathname && !h) {
        addCrumb({ path: p });
      }

      const allow = () => {
        unblock();

        // пушим в историю только hash, когда переходим на tab
        if (toTab) {
          history.push(h);
          return;
        }

        if (searchedTargetCrumbIdx > -1 && searchedCurrentCrumbIdx > -1) {
          history.go(searchedTargetCrumbIdx - searchedCurrentCrumbIdx);
          return;
        }

        history.push(targetPath);
      };

      if (checkEditorChanged()) {
        confirmObjectSave(allow, { toTab });
        return false;
      }

      blockInProcess.current = false;
    });

    unblockRef.current = unblock;
  }

  // для анмаунта
  // unblockRef не изменяется - можно не указывать в зависимостях
  useEffect(
    () => () => {
      unblockRef.current?.();
      updateEditorDetails();
    },
    [updateEditorDetails]
  );

  // загрузка лэйаута и данных объекта
  useEffect(() => {
    if (!username) return;
    loadLayoutAndObjectData();/*  */
  }, [pathname, refreshEditor, reload, username]);

  // проставление в форму полученных данных объекта
  // resetFields ремаунтит форм айтемы - от него хорошо бы уйти
  useEffect(() => {
    form.resetFields();
    setFormData(editorDetails);
  }, [editorDetails, form]);
  // Переводит скролл в исходное положение при смене объекта
  useEffect(() => {
    const mainContent = document.querySelector('.main-content');
    mainContent?.scroll(0, 0);
  }, [getChildrenParentID(editorDetails)]);

  const onBeforeunload = (evt: any) => {
    if (checkEditorChanged() || editorResponsesForm.getFieldValue(IS_CHANGED_KEY)) {
      evt.preventDefault()
    }
  }

  useBeforeunload(onBeforeunload);
  return (
    <Spin spinning={loading}>
      <div className={styles.header} ref={headerRefChange}>
        <Title
          form={form}
          goBack={factory?.getConfig().params.goBack !== undefined ? factory?.getConfig().params.goBack : true}
          confirmObjectSave={confirmObjectSave}
          inbox={inboxName}
        />
        <EntityEditorButtons form={form} factory={factory} saveObject={saveObject} inbox={inboxName} />
      </div>
      <div
        className={cn('main-content', 'js-parentNode', styles.mainContent)}
        style={{ height: `calc(100% - ${headerHeight}px)` }}
      >
        <ModalForm />
        <Form
          layout='vertical'
          form={form}
          onValuesChange={onValuesChange}
          onFinishFailed={(errors: any) => updateEditorErrors(errors.errorFields)}
          onFinish={() => saveObject()}
          scrollToFirstError={true}
        >
          <EntityEditorContext.Provider
            value={{
              className: editorDetails.classType,
              form,
              factory,
              documentId: getDocumentId(editorDetails),
              refStore,
              onFormFinish: saveObject,
              onValuesChange,
              toggleProcessKeyWrapper,
            }}
          >
            <CardLayout
              factory={factory}
              noCollapse={!!params.message}
              useKB={useKB}
              isNew={isNewID(objectID)}
            />
          </EntityEditorContext.Provider>
        </Form>
        {props.innerViews || <></>}
      </div>
    </Spin>
  );
};

export default withRouter(EntityEditorDetails);
